import {Breadcrumb, Col, Row} from "antd";
import React from "react";
import {useRecoilValue} from "recoil";
import {globalBreadCrumbState,} from "../../../_common/global_state";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {RightOutlined} from "@ant-design/icons";

export const Breadcrumb_5 = () => {
    const navigate = useNavigate();
    const hash = window.location.hash;
    const globalBreadCrumb = useRecoilValue(globalBreadCrumbState);
    const {t} = useTranslation();

    const breadcrumbItems =
        Array.isArray(globalBreadCrumb) &&
        globalBreadCrumb?.length > 0 &&
        globalBreadCrumb?.map((item, index) => {
            return {
                title: (
                    <div
                        className={`clickable ${index === globalBreadCrumb.length - 1 ? "" : "secondary-text"}`}
                        onClick={() => navigate(item?.path)}
                    >
                        {t(
                            `module.${item?.name
                                ?.replace(".", "")
                                .replace(/[-\s+]/g, "_")
                                .toLowerCase()}`
                        )}
                    </div>
                ),
            };
        });

    return (
        breadcrumbItems &&
        <Row>
            <Col span={24} className={"mb-2 mt-3"}>
                <Breadcrumb
                    style={{width: "fit-content"}}
                    items={breadcrumbItems}
                    separator={<RightOutlined size={"small"}/>}
                />
            </Col>
        </Row>
    );
};
