// import {Empty} from "antd";
// import IconCommon from "../../../../../shared/_common/_iconcommon";
import useShared from "../../../../../shared/_common/_shared";


export default function ExcotradesEmptyData() {
    const {IconCommon} = useShared()
    return(
        <div className={"text-center"}>
            <IconCommon iconName={"empty"} className={"empty-data"} title={"noData"} isAlwaysInActive={true}/>
        </div>
    )
}