import React, {useEffect, useState} from "react";
import {Button, Col, Layout, Menu, Popover, Row, Space, Tooltip,} from "antd";
import {useRecoilState, useRecoilValue} from "recoil";
import {globalBrandSettingState, globalColorThemeState, globalLanguageListState, globalUserDetailState,} from "../../../_common/global_state";
import QrModal from "./qr/qrModal";
import {useIsMobile} from "../../../_common/function";
import PlatformDownloadModal from "./platformDownload/platformDownloadModal";
import {getPlatform} from "../../../api/graphql/platformDownload";
import {useNavigate} from "react-router-dom";
import useShared from "../../../_common/_shared";
import {useTranslation} from "react-i18next";
import GoLiveModal from "./goLive";
import {useVerification} from "../../../_common/_authorize";
import useLayoutFunction from "../../function";

export const Header_3 = (props) => {
    const {
        registerField,
        headerRoutes,
        collapsed,
        setCollapsed,
        children,
        openPlatformModal,
        setOpenPlatformModal,
        openQrModal,
        setOpenQrModal,
        openGoLiveModal,
        setOpenGoLiveModal
    } = props;
    const isMobile = useIsMobile(768)
    const {t} = useTranslation();
    const {handleTokenExpiration} = useVerification();
    const {Header} = Layout;
    const [platformData, setPlatformData] = useState([]);
    const userDetails = useRecoilValue(globalUserDetailState);
    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const languageListState = useRecoilValue(globalLanguageListState);
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const navigate = useNavigate();
    const {IconCommon, IconFlag} = useShared();
    const {changeLanguageHandler} = useLayoutFunction();

    function filterAndGroupServers(arr) {
        const result = {};
        let appObject = null;

        for (const obj of arr) {
            const serverName = obj?.server?.name;
            const isEnabled =
                obj?.windowLinkObject.isEnabled ||
                obj?.macOSXLinkObject.isEnabled ||
                obj?.androidAPKLinkObject.isEnabled ||
                obj?.iOSDownloadLinkObject.isEnabled ||
                obj?.androidDownloadLinkObject.isEnabled ||
                obj?.huaweiDownloadLinkObject.isEnabled;

            if (obj.type === "app" && isEnabled) {
                appObject = obj;
            }

            if (serverName && isEnabled) {
                result[serverName] = obj;
            }
        }

        if (appObject) {
            result["App"] = appObject;
        }

        return result;
    }

    const preload = async () => {
        const response = await getPlatform();
        const filtered = await filterAndGroupServers(response?.getPlatform?.data);
        setPlatformData(filtered);
    };

    useEffect(() => {
        preload();
    }, []);

    const languageDropdown = (
        <Menu
            className={"headerLanguage"}
            selectedKeys={[localStorage.getItem("language")]}
        >
            {(languageListState ?? [])?.map((lang) => (
                <Menu.Item
                    key={lang?.code}
                    value={lang?.code}
                    onClick={() => changeLanguageHandler(lang?.code)}
                >
                    <Space><IconFlag countryCode={lang?.countryCode} style={{width: "24px"}}/> {lang?.nativeName}</Space>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Header id={"layout-header"}>
            <Row justify={"space-between"} wrap={false}>
                <Col className="header-icon-section">
                    <img
                        className={"clickable"}
                        onClick={() => navigate("/dashboard")}
                        src={
                            colorState === "dark"
                                ? brandSettingState?.companyLogoInverse
                                : brandSettingState?.companyLogo
                        }
                    />
                    <div className="header-breadcrumb d-none d-lg-block">{children}</div>
                </Col>
                <div className="header-menu-section">
                    {userDetails?.clientAccountType === "demo" && (
                        <Button
                            type="primary"
                            className="goLiveButton hide-sm me-3"
                            onClick={() => {
                                setOpenGoLiveModal(true);
                            }}
                        >
                            {t("common.goLive")}
                        </Button>
                    )}

                    <div className="header-profile-section me-2">
                        {userDetails.username}
                    </div>
                    <Tooltip title={t("referral.modal.title")} getPopupContainer={() => document.querySelector("#layout-3")}>
                        <Button
                            type="default"
                            className="square icon hide-sm"
                            onClick={() => {
                                setOpenQrModal(true);
                            }}
                        >
                            <IconCommon iconName={"cm-qr-regular"}/>
                        </Button>
                    </Tooltip>
                    <Popover
                        className="hide-sm"
                        trigger={"click"}
                        getPopupContainer={() => document.querySelector("#layout-3")}
                        content={languageDropdown}
                    >
                        <Tooltip title={t("common.language")} getPopupContainer={() => document.querySelector("#layout-3")}>
                            <Button type="default" className="square icon">
                                <IconCommon iconName={"cm-language-regular"}/>
                            </Button>
                        </Tooltip>
                    </Popover>
                    <Tooltip title={t("common.theme")} getPopupContainer={() => document.querySelector("#layout-3")}>
                        <Button type="default" className="square icon hide-sm" onClick={() => {
                            if (colorState === "light") {
                                setColorState("dark");
                                localStorage.setItem("color", "dark");
                            } else {
                                setColorState("light");
                                localStorage.setItem("color", "light");
                            }
                        }}>
                            <IconCommon iconName={`cm-${colorState}theme-regular`}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title={t("module.platformDownload")} getPopupContainer={() => document.querySelector("#layout-3")}>
                        <Button
                            type="default"
                            className="square icon hide-sm"
                            onClick={() => {
                                setOpenPlatformModal(true);
                            }}
                        >
                            <IconCommon iconName={"cm-download-regular"}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title={t("common.logout")} getPopupContainer={() => document.querySelector("#layout-3")}>
                    <Button
                        type="default"
                        className="square icon hide-sm"
                        onClick={() => handleTokenExpiration()}
                    >
                        <IconCommon iconName={"cm-logout-regular"}/>
                    </Button>
                    </Tooltip>

                    <Button
                        type="default"
                        className="square icon show-sm"
                        onClick={() => {
                            setCollapsed((c) => !c);
                        }}
                    >
                        <IconCommon iconName={"cm-hamburger-regular"}/>
                    </Button>
                </div>
            </Row>

            <QrModal
                isModalOpen={openQrModal}
                handleCancel={() => setOpenQrModal(false)}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
            <PlatformDownloadModal
                isModalOpen={openPlatformModal}
                handleCancel={() => setOpenPlatformModal(false)}
                platformData={platformData}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
            <GoLiveModal
                isModalOpen={openGoLiveModal}
                handleCancel={() => setOpenGoLiveModal(false)}
                registerField={registerField}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
        </Header>
    );
};
