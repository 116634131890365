import React, {useEffect, useState} from "react";
import {Col, Divider, Layout} from "antd";
import {useRecoilValue} from "recoil";
import {globalBrandModuleSettingState} from "../../../_common/global_state";
import {useNavigate} from "react-router-dom";
import useShared from "../../../_common/_shared";
import FooterTime from '../component/latestTime/footerTime'
import {useTranslation} from "react-i18next";
import {moduleTranslation} from "../../../_common/function";

export const Footer_2 = () => {
    const {Footer} = Layout;
    const brandModuleSetting = useRecoilValue(globalBrandModuleSettingState)
    const [isMobile, setIsMobile] = useState(false);
    const {IconCommon} = useShared()
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isMobileFunction = () => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        setIsMobile(mediaQuery.matches);

        const handleResize = (e) => setIsMobile(e?.matches);
        mediaQuery.addEventListener('change', handleResize);
        return () => {
            mediaQuery.removeEventListener('change', handleResize);
        };
    }

    useEffect(() => {
        isMobileFunction()
    }, [])

    return (
        <Footer id={"layout-footer"}>
            <div className={isMobile ? "row justify-content-center justify-content-sm-end p-2" : 'row p-2'}>
                <Col
                    className={"text-center d-flex align-items-center col-12 justify-content-center justify-content-sm-end"}>
                    {brandModuleSetting?.find((item) => item?.isEnabled === true && item?.name === 'Deposit') &&
                    <>
                        <div className={"d-flex align-items-center justify-content-center clickable"}
                             onClick={() => {
                                 navigate('/wallet-deposit')
                             }}
                        >
                            <IconCommon className={"footerIcon"} iconName={"nav-deposit"}/>
                            {!isMobile && <span className={"footerText"}>{t('module.deposit')}</span>}
                        </div>
                        <Divider type="vertical" className={"footerDivider"}/>
                    </>
                    }
                    {brandModuleSetting?.find((item) => item?.isEnabled === true && item?.name === 'Withdrawal') &&
                    <>
                        <div className={"d-flex align-items-center justify-content-center clickable"}
                             onClick={() => {
                                 navigate('/wallet-withdrawal')
                             }}
                        >
                            <IconCommon className={"footerIcon"} iconName={"nav-withdrawal"}/>
                            {!isMobile && <span className={"footerText"}>{t('module.withdrawal')}</span>}
                        </div>
                        <Divider type="vertical" className={"footerDivider"}/>
                    </>
                    }
                    {brandModuleSetting?.find((item) => item?.isEnabled === true && item?.name === 'Support') &&
                    <>
                        <div className={"d-flex align-items-center justify-content-center clickable"}>
                            <IconCommon className={"footerIcon"} iconName={"helpcenter"}/>
                            {!isMobile && <span className={"footerText"}>{t('module.support')}</span>}
                        </div>
                        <Divider type="vertical" className={"footerDivider"}/>
                    </>
                    }
                    <div className={"d-flex align-items-center justify-content-center"}>
                        <IconCommon className={"footerIcon"} iconName={"time"}/>
                        <FooterTime/>
                    </div>
                </Col>
            </div>
        </Footer>
    )
}