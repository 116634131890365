import {Card, Col, Image, Modal, Row, Tabs} from "antd";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import dummy from "../../../../assets/image/dummy_obj.png";

const PlatformDownloadModal = (props) => {
    const {isModalOpen, handleCancel, platformData} = props;
    const language = localStorage.getItem("language");

    const {t} = useTranslation();

    const items = useMemo(() => {
        return Object.keys(platformData)?.map((key, index) => {
            return {
                key: index + 1,
                label: t(key),
                children: (
                    <Row gutter={[16, 16]}>
                        {Object.keys(platformData[key])?.map((item, i) => {
                            if (
                                item !== "server" &&
                                item !== "__typename" &&
                                key &&
                                platformData[key] &&
                                item &&
                                platformData[key].hasOwnProperty(item) &&
                                platformData[key][item]?.isEnabled === true
                            ) {
                                const data = platformData[key][item];
                                const platformDisplayName =
                                    data?.displayNameLanguage?.find((v) => v?.language === language)?.label ??
                                    data?.displayNameLanguage?.find((v) => v?.language === "en")?.label ??
                                    data?.displayName;

                                return (
                                    <Col xs={24} md={12} lg={8} className={"mb-2"} key={i}>
                                        <a className={"text-decoration-none h-100"} href={data?.link} target="_blank">
                                            <Card className={"platformDownloadCard h-100"}>
                                                <Col span={24} className={"text-center"}>
                                                    <Image
                                                        width={115}
                                                        height={115}
                                                        src={data?.icon ? data?.icon : data?.defaultIcon ? data?.defaultIcon : dummy}
                                                        style={{objectFit: "cover"}}
                                                        preview={false}
                                                    />
                                                    <p className={"mt-4"}>{platformDisplayName}</p>
                                                </Col>
                                            </Card>
                                        </a>
                                    </Col>
                                );
                            }
                        })}
                    </Row>
                ),
            };
        });
    }, [platformData]);

    return (
        <Modal
            title={t("module.platformDownload")}
            className={"platformDownloadModal"}
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
            width={"60%"}
            getContainer={() => document.getElementById("layout-5")}
        >
            <Tabs items={items} />
        </Modal>
    );
};

export default PlatformDownloadModal;
