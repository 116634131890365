import { Layout } from "antd";
import React, {useCallback, useMemo} from "react";
import { Outlet, useLocation } from "react-router-dom";
import "./css/style.css";
import { Header_1 } from "./component/header";
import { Sider_1 } from "./component/sider";
import { Breadcrumb_1 } from "./component/breadcrumb";
import { Footer_1 } from "./component/footer";
const Layout_1 = ({
  kybList,
  poiList,
  porList,
  routes,
  registerField,
  navMap,
}) => {
  const location = useLocation()
  const getHeaderRoutes = useCallback((isHeader) => {
    const more = routes.find((d) => d.key === "more");
    const home = routes.find((d) => d.key === "home");
    const pinned = routes.filter(
        (d) => (d.key !== "more" || d.key !== "home") && d.key in navMap
    );
    const pinnedParents = routes.filter(
        (d) => !(d.key === "more" || d.key === "home") && !(d.key in navMap)
    )
    const parents = (isHeader ? (more?.sub ?? []).filter((d) => !(d.key in navMap)) : more?.sub.concat(pinned)) || [];
    const headerRoutes = [home, ...([...parents, ...pinnedParents]?.sort((a, b) => a?.key?.localeCompare?.(b?.key)))];
    pinned.forEach((d) => {
      const p = headerRoutes.find((e) => e.key === navMap[d.key]);
      if (p?.sub && !p.sub.some((f) => f.key === d.key)) {
        p.sub.push(d);
      }
    });
    return headerRoutes;
  },[routes, navMap])
  const headerRoutes = getHeaderRoutes(true)
  const mobileHeaderRoutes = getHeaderRoutes(false)
  return (
    <Layout id={"layout-1"}>
      <Header_1 registerField={registerField} headerRoutes={headerRoutes} mobileRoutes={mobileHeaderRoutes}/>
      <Layout>
        <Sider_1 routes={routes} />
        <div className="sider-overlay"></div>
        <Layout id={"layout-body-1"} className={"layout_padding container-xxl"}>
          {!(location.pathname === "/dashboard") && (
            <Breadcrumb_1
              kybList={kybList}
              poiList={poiList}
              porList={porList}
            />
          )}
          <Outlet />
        </Layout>
      </Layout>
      {/* <Footer_1 /> */}
    </Layout>
  );
};
export default Layout_1;