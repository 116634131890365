import React from "react";
import { withLazyComponent } from "./lazyComponent";

export const protectedRoute = [
  {
    path: "/dashboard",
    element: withLazyComponent(
      React.lazy(() => import("../pages/dashboard/view"))
    ),
  },
  {
    path: "/profile",
    name: "Profile",
    element: withLazyComponent(
      React.lazy(() => import("../pages/profile/view"))
    ),
  },
  {
    path: "/referral",
    name: "Referral",
    element: withLazyComponent(
      React.lazy(() => import("../pages/referral/view"))
    ),
  },
  {
    path: "/self-trade",
    name: "Self-Trade",
    element: withLazyComponent(
      React.lazy(() => import("../pages/selfTrade/view"))
    ),
  },
  {
    path: "/wallet-operations",
    name: "Wallet Operations",
    element: withLazyComponent(
      React.lazy(() => import("../pages/walletOperations/view"))
    ),
  },
  {
    path: "/wallet-deposit",
    name: "Deposit",
    element: withLazyComponent(
      React.lazy(() => import("../pages/walletDeposit/view"))
    ),
  },
  {
    path: "/wallet-withdrawal",
    name: "Withdrawal",
    element: withLazyComponent(
      React.lazy(() => import("../pages/walletWithdrawal/view"))
    ),
  },
  {
    path: "/account-verification",
    name: "Verification",
    element: withLazyComponent(
      React.lazy(() => import("../pages/accountVerification/view"))
    ),
  },
  {
    path: "/market-news",
    moduleName: "Market News",
    element: withLazyComponent(
      React.lazy(() => import("../pages/marketNews/view"))
    ),
  },
  {
    path: "/trade-ideas",
    moduleName: "Trade Ideas",
    element: withLazyComponent(
      React.lazy(() => import("../pages/tradeIdeas/view"))
    ),
  },
  {
    path: "/economic-calendar",
    moduleName: "Economic Calendar",
    element: withLazyComponent(
      React.lazy(() => import("../pages/economicCalendar/view"))
    ),
  },
  {
    path: "/ai-technical-screener",
    moduleName: "A.I Technical Screener",
    element: withLazyComponent(
      React.lazy(() => import("../pages/aiTechnicalScreener/view"))
    ),
  },
  {
    path: "/platform-download",
    element: withLazyComponent(
      React.lazy(() => import("../pages/platformDownload/view"))
    ),
  },
  {
    path: "/smart-score",
    moduleName: "Smart Score",
    element: withLazyComponent(
      React.lazy(() => import("../pages/smartScore/view"))
    ),
  },
  {
    path: "/live-broadcast",
    moduleName: "LIVE Broadcast",
    element: withLazyComponent(
      React.lazy(() => import("../pages/liveBroadcast/view"))
    ),
  },
  {
    path: "/market-buzz",
    moduleName: "Market Buzz",
    element: withLazyComponent(
        React.lazy(() => import("../pages/marketBuzz/view"))
    ),
  },
  {
    path: "/delete-account",
    moduleName: "Smart Score",
    element: withLazyComponent(
      React.lazy(() => import("../pages/deleteAccount/view"))
    ),
  },
  {
    path: "/coming-soon",
    element: withLazyComponent(
      React.lazy(() => import("../pages/_error/pageComingSoon"))
    ),
  },
  {
    path: "/mission",
    moduleName: "Mission",
    element: withLazyComponent(
        React.lazy(() => import("../pages/mission/view"))
    ),
  },
  {
    path: "/reward",
    moduleName: "Reward",
    element: withLazyComponent(
        React.lazy(() => import("../pages/reward/view"))
    ),
  },
  {
    path: "/mam-pamm",
    moduleName: "MAM/PAMM",
    element: withLazyComponent(
        React.lazy(() => import("../pages/mamPamm/_list/view"))
    ),
  },
  {
    path: "/mam-pamm/money-manager-acc",
    moduleName: "MAM/PAMM",
    element: withLazyComponent(
        React.lazy(() => import("../pages/mamPamm/moneyManagerAccount/view"))
    ),
  },
  {
    path: "/promo-materials",
    moduleName: "Promo Materials",
    element: withLazyComponent(
        React.lazy(() => import("../pages/promoMaterials/view"))
    ),
  },
  {
    path: "/client-trades",
    moduleName: "Client Trades",
    element: withLazyComponent(
        React.lazy(() => import("../pages/reports/clientTrades/view"))
    ),
  },
  {
    path: "/client-listing",
    moduleName: "Client Listing",
    element: withLazyComponent(
        React.lazy(() => import("../pages/reports/clientListing/view"))
    ),
  },
  {
    path: "/client-deposits-withdrawals",
    moduleName: "Client Deposits/Withdrawals",
    element: withLazyComponent(
        React.lazy(() => import("../pages/reports/clientDepositWithdrawal/view"))
    ),
  },
  {
    path: "/client-trading-accounts",
    moduleName: "Client Trading Accounts",
    element: withLazyComponent(
        React.lazy(() => import("../pages/reports/clientTradingAccounts/view"))
    ),
  },
  {
    path: "/volume-commission",
    moduleName: "Volume Commission",
    element: withLazyComponent(
        React.lazy(() => import("../pages/reports/volumeCommission/view"))
    ),
  },
  {
    path: "/spread-earnings-commission",
    moduleName: "Spread Earnings Commission",
    element: withLazyComponent(
        React.lazy(() => import("../pages/reports/spreadEarningsCommission/view"))
    ),
  },
  {
    path: "/profit-sharing-commission",
    moduleName: "Profit Sharing Commission",
    element: withLazyComponent(
        React.lazy(() => import("../pages/reports/profitSharingCommission/view"))
    ),
  },
];
