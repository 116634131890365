const bmMY = {
    testing: "testing bm",
    testing2: {
        test: "testing nested bm",
    },
    errors: {
        message:{
            400: "Bad Request",
            401: "Unauthorized",
            403: "Forbidden",
            404: "Not Found"
        }
    }
}

export default bmMY