import {BRAND_NAME} from "./_variable";

const configMap = {
    staging: {
        brandName: "excotrades",
        // backendURL: "https://adminapi.antospnc.app/",
        // graphqlURL: "https://adminapi.antospnc.app/graphql",
        backendURL: "https://admin.excotrades.app/",
        graphqlURL: "https://admin-web.excotrades.app/graphql",
        // backendURL: "http://localhost:41960/",
        // graphqlURL: "http://localhost:41960/graphql",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        defaultLayout: 2,
        mode: "development",
        VapidKey:
            "BFlai1FP_MVQ-ippIlqPABjByg40BzNVluNC2hAeN4dQgcjcK2l2p0a1u5Rjm6SNHqNU-MC6-KRv2iW1jXib29c",
        fireBaseConfig: {
            apiKey: "AIzaSyDeGnehW7xoP6DuF0Ike5Jat_XKVQjH3ms",
            authDomain: "excotrades.firebaseapp.com",
            projectId: "excotrades",
            storageBucket: "excotrades.appspot.com",
            messagingSenderId: "668887087728",
            appId: "1:668887087728:web:5b230426f9263cfbbed2b5",
            measurementId: "G-0PHQ303T53",
        },
    },
    fundamarkets: {
        brandName: "fundamarkets",
        backendURL: "https://admin.fundamarkets.app/",
        graphqlURL: "https://admin-web.fundamarkets.app/graphql",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        defaultLayout: 6,
        mode: "development",
        VapidKey:
            "BFlai1FP_MVQ-ippIlqPABjByg40BzNVluNC2hAeN4dQgcjcK2l2p0a1u5Rjm6SNHqNU-MC6-KRv2iW1jXib29c",
        fireBaseConfig: {
            apiKey: "AIzaSyDeGnehW7xoP6DuF0Ike5Jat_XKVQjH3ms",
            authDomain: "excotrades.firebaseapp.com",
            projectId: "excotrades",
            storageBucket: "excotrades.appspot.com",
            messagingSenderId: "668887087728",
            appId: "1:668887087728:web:5b230426f9263cfbbed2b5",
            measurementId: "G-0PHQ303T53",
        },
    },
    excotrades: {
        brandName: "excotrades",
        backendURL: "https://admin.excotrades.app/",
        graphqlURL: "https://admin-web.excotrades.app/graphql",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        defaultLayout: 2,
        mode: "development",
        VapidKey:
            "BFlai1FP_MVQ-ippIlqPABjByg40BzNVluNC2hAeN4dQgcjcK2l2p0a1u5Rjm6SNHqNU-MC6-KRv2iW1jXib29c",
        fireBaseConfig: {
            apiKey: "AIzaSyDeGnehW7xoP6DuF0Ike5Jat_XKVQjH3ms",
            authDomain: "excotrades.firebaseapp.com",
            projectId: "excotrades",
            storageBucket: "excotrades.appspot.com",
            messagingSenderId: "668887087728",
            appId: "1:668887087728:web:5b230426f9263cfbbed2b5",
            measurementId: "G-0PHQ303T53",
        },
    },
    metapromarkets: {
        brandName: "metapromarkets",
        backendURL: "https://admin.metapromarkets.app/",
        graphqlURL: "https://admin-web.metapromarkets.app/graphql",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        defaultLayout: 3,
        mode: "development",
        VapidKey:
            "BFlai1FP_MVQ-ippIlqPABjByg40BzNVluNC2hAeN4dQgcjcK2l2p0a1u5Rjm6SNHqNU-MC6-KRv2iW1jXib29c",
        fireBaseConfig: {
            apiKey: "AIzaSyDeGnehW7xoP6DuF0Ike5Jat_XKVQjH3ms",
            authDomain: "excotrades.firebaseapp.com",
            projectId: "excotrades",
            storageBucket: "excotrades.appspot.com",
            messagingSenderId: "668887087728",
            appId: "1:668887087728:web:5b230426f9263cfbbed2b5",
            measurementId: "G-0PHQ303T53",
        },
    },
    ridgewaymarkets: {
        brandName: "ridgewaymarkets",
        backendURL: "https://admin.ridgewaymarkets.app/",
        graphqlURL: "https://admin-web.ridgewaymarkets.app/graphql",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        defaultLayout: 4,
        mode: "development",
        VapidKey:
            "BFlai1FP_MVQ-ippIlqPABjByg40BzNVluNC2hAeN4dQgcjcK2l2p0a1u5Rjm6SNHqNU-MC6-KRv2iW1jXib29c",
        fireBaseConfig: {
            apiKey: "AIzaSyDeGnehW7xoP6DuF0Ike5Jat_XKVQjH3ms",
            authDomain: "ridgewaymarkets.firebaseapp.com",
            projectId: "ridgewaymarkets",
            storageBucket: "ridgewaymarkets.appspot.com",
            messagingSenderId: "668887087728",
            appId: "1:668887087728:web:5b230426f9263cfbbed2b5",
            measurementId: "G-0PHQ303T53",
        },
    },
    alliumtrades: {
        brandName: "alliumtrades",
        backendURL: "https://admin.alliumtrades.app/",
        graphqlURL: "https://admin-web.alliumtrades.app/graphql",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        defaultLayout: 6,
        mode: "development",
        VapidKey:
            "BFlai1FP_MVQ-ippIlqPABjByg40BzNVluNC2hAeN4dQgcjcK2l2p0a1u5Rjm6SNHqNU-MC6-KRv2iW1jXib29c",
        fireBaseConfig: {
            apiKey: "AIzaSyDeGnehW7xoP6DuF0Ike5Jat_XKVQjH3ms",
            authDomain: "ridgewaymarkets.firebaseapp.com",
            projectId: "ridgewaymarkets",
            storageBucket: "ridgewaymarkets.appspot.com",
            messagingSenderId: "668887087728",
            appId: "1:668887087728:web:5b230426f9263cfbbed2b5",
            measurementId: "G-0PHQ303T53",
        },
    },
    minosmarkets: {
        brandName: "minosmarkets",
        backendURL: "https://admin.minosmarkets.app/",
        graphqlURL: "https://admin-web.minosmarkets.app/graphql",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        cmsURL: "minosbrokers.com",
        defaultLayout: 5,
        mode: "development",
        VapidKey:
            "BFlai1FP_MVQ-ippIlqPABjByg40BzNVluNC2hAeN4dQgcjcK2l2p0a1u5Rjm6SNHqNU-MC6-KRv2iW1jXib29c",
        fireBaseConfig: {
            apiKey: "AIzaSyDeGnehW7xoP6DuF0Ike5Jat_XKVQjH3ms",
            authDomain: "ridgewaymarkets.firebaseapp.com",
            projectId: "ridgewaymarkets",
            storageBucket: "ridgewaymarkets.appspot.com",
            messagingSenderId: "668887087728",
            appId: "1:668887087728:web:5b230426f9263cfbbed2b5",
            measurementId: "G-0PHQ303T53",
        },
    },
    cronoscap: {
        brandName: "cronoscap",
        backendURL: "https://admin.cronoscap.app/",
        graphqlURL: "https://admin-web.cronoscap.app/graphql",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        cmsURL: "cronoscap.com",
        defaultLayout: 5,
        mode: "development",
        VapidKey:
            "BFlai1FP_MVQ-ippIlqPABjByg40BzNVluNC2hAeN4dQgcjcK2l2p0a1u5Rjm6SNHqNU-MC6-KRv2iW1jXib29c",
        fireBaseConfig: {
            apiKey: "AIzaSyDeGnehW7xoP6DuF0Ike5Jat_XKVQjH3ms",
            authDomain: "ridgewaymarkets.firebaseapp.com",
            projectId: "ridgewaymarkets",
            storageBucket: "ridgewaymarkets.appspot.com",
            messagingSenderId: "668887087728",
            appId: "1:668887087728:web:5b230426f9263cfbbed2b5",
            measurementId: "G-0PHQ303T53",
        },
    },
    quorvex: {
        brandName: "quorvex",
        backendURL: "https://admin.qvmarkets.app/",
        graphqlURL: "https://admin-web.qvmarkets.app/graphql",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        defaultLayout: 3,
        mode: "development",
        VapidKey:
            "BFlai1FP_MVQ-ippIlqPABjByg40BzNVluNC2hAeN4dQgcjcK2l2p0a1u5Rjm6SNHqNU-MC6-KRv2iW1jXib29c",
        fireBaseConfig: {
            apiKey: "AIzaSyDeGnehW7xoP6DuF0Ike5Jat_XKVQjH3ms",
            authDomain: "excotrades.firebaseapp.com",
            projectId: "excotrades",
            storageBucket: "excotrades.appspot.com",
            messagingSenderId: "668887087728",
            appId: "1:668887087728:web:5b230426f9263cfbbed2b5",
            measurementId: "G-0PHQ303T53",
        },
    },
    blueocean: {
        brandName: "blueocean",
        backendURL: "https://admin.blueoceanfin.app/",
        graphqlURL: "https://admin-web.blueoceanfin.app/graphql",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tradeURL: "https://mt5.nextrades.ai/api",
        defaultLayout: 1,
        mode: "development",
        VapidKey:
            "BFlai1FP_MVQ-ippIlqPABjByg40BzNVluNC2hAeN4dQgcjcK2l2p0a1u5Rjm6SNHqNU-MC6-KRv2iW1jXib29c",
        fireBaseConfig: {
            apiKey: "AIzaSyDeGnehW7xoP6DuF0Ike5Jat_XKVQjH3ms",
            authDomain: "excotrades.firebaseapp.com",
            projectId: "excotrades",
            storageBucket: "excotrades.appspot.com",
            messagingSenderId: "668887087728",
            appId: "1:668887087728:web:5b230426f9263cfbbed2b5",
            measurementId: "G-0PHQ303T53",
        },
    },
    antos: {
        brandName: "antos",
        backendURL: "https://admin.antospnc.app/",
        graphqlURL: "https://admin-web.antospnc.app/graphql",
        // backendURL: "https://admin.worthycodes.com/",
        // graphqlURL: "https://admin-web.worthycodes.com/graphql",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tradeURL: "https://mt5.antospnc.app/api",
        mt5WebSocket: "wss://xsocket.antospnc.app",
        defaultLayout: 2,
        mode: "development",
        VapidKey:
            "BFlai1FP_MVQ-ippIlqPABjByg40BzNVluNC2hAeN4dQgcjcK2l2p0a1u5Rjm6SNHqNU-MC6-KRv2iW1jXib29c",
        fireBaseConfig: {
            apiKey: "AIzaSyDeGnehW7xoP6DuF0Ike5Jat_XKVQjH3ms",
            authDomain: "excotrades.firebaseapp.com",
            projectId: "excotrades",
            storageBucket: "excotrades.appspot.com",
            messagingSenderId: "668887087728",
            appId: "1:668887087728:web:5b230426f9263cfbbed2b5",
            measurementId: "G-0PHQ303T53",
        },
    },
    // Add more configuration mappings for different names
};

function getConfigByName() {
    const config = configMap[BRAND_NAME];
    if (config) {
        return config;
    } else {
        // Return a default configuration or handle the case when the name is not found
        return null;
    }
}

export default getConfigByName;
