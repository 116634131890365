import {Col, Modal, Row, Tabs} from "antd";
import React, {useMemo} from "react";
import PlatformDownloadCard from "./platformDownloadCard";
import {useTranslation} from "react-i18next";

const PlatformDownloadModal = (props) => {
    const {isModalOpen, handleCancel, platformData} = props;

    const {t} = useTranslation();

    const items = useMemo(() => {
        return Object.keys(platformData)?.map((key, index) => {
            return {
                key: index + 1,
                label: t(key),
                children: (
                    <Row key={key} gutter={[16, 16]}>
                        {Object.keys(platformData[key])?.map((item, index) => {
                            if (item !== "server" && item !== "__typename") {
                                if (
                                    key &&
                                    platformData[key] &&
                                    item &&
                                    platformData[key].hasOwnProperty(item) &&
                                    platformData[key][item]?.isEnabled === true
                                ) {
                                    return (
                                        <Col key={platformData[key][item]?.displayName + index} xs={24} md={12} lg={8} className={"mb-2"}>
                                            <PlatformDownloadCard data={platformData[key][item]} />
                                        </Col>
                                    );
                                }
                            }
                        })}
                    </Row>
                ),
            };
        });
    }, [platformData]);

    return (
        <Modal
            title={t("module.platformDownload")}
            className={"platformDownloadModal"}
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
            width={"60%"}
            getContainer={() => document.getElementById("layout-2")}
        >
            <Tabs defaultActiveKey="1" items={items} />
        </Modal>
    );
};

export default PlatformDownloadModal;
