import {Col, Modal, Row, Tabs} from "antd";
import React from "react";
import PlatformDownloadCard from "./platformDownloadCard";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";

const PlatformDownloadModal = (props) => {
    const {isModalOpen, handleCancel, platformData} = props;

    const {t} = useTranslation();

    const filteredServerKey = Object.keys(platformData)?.filter((key) =>
        Object.keys(platformData[key])?.filter(
            (item) => key && platformData[key] && item && platformData[key].hasOwnProperty(item) && platformData[key][item]?.isEnabled === true
        )
    );

    const items = useMemo(() => {
        return filteredServerKey?.map((key, index) => {
            const filteredPlatformDataKey = Object.keys(platformData[key])?.filter((item) => {
                if (item !== "server" && item !== "__typename") {
                    return key && platformData[key] && item && platformData[key].hasOwnProperty(item) && platformData[key][item]?.isEnabled === true;
                }
                return false;
            });
            return {
                key: index + 1,
                label: t(key),
                children: (
                    <Row gutter={[16, 16]} justify="center">
                        {filteredPlatformDataKey.map((platformKey) => {
                            const item = platformData[key][platformKey];
                            return (
                                <Col key={item?.displayName} xs={24} md={12} lg={8} className={"pt-0 mb-2"}>
                                    <PlatformDownloadCard data={item} />
                                </Col>
                            );
                        })}
                    </Row>
                ),
            };
        });
    }, [platformData]);

    return (
        <Modal
            title={t("module.platformDownload")}
            className={"platformDownloadModal"}
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
            width={"60%"}
            getContainer={() => document.getElementById("layout-4")}
            destroyOnClose
        >
            <Tabs defaultActiveKey="1" items={items} />
        </Modal>
    );
};

export default PlatformDownloadModal;
