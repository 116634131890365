import {Button, Col, DatePicker, Form, Modal, Row} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useRecoilValue} from "recoil";
import {globalUserDetailState} from "../../../../../_common/global_state";
import useAccountVerificationFunction from "../../../function";
import {KycField_2} from "./kycField";
import {useTranslation} from "react-i18next";

const KycModal_2 = (props) => {

    const {data, kycType, verificationType, isModalOpen, handleCancel, poiList, porList} = props
    const userDetails = useRecoilValue(globalUserDetailState)
    const {addKycFunction, reorderValue} = useAccountVerificationFunction()
    const { t } = useTranslation();
    const title = verificationType === "kyc" ? kycType === "poi" ? "Submit POI" : "Submit POR" : "Upgrade to Enterprise Account"

    const onSubmit = async (value) => {

        let memberArray = [];

        if (verificationType === "kyb") {
            await Promise.all(Object.keys(value).map((key) => {
                const detail = data?.find((d) => d.kycField.fieldType === "member" && d._id === key)
                const newData = [...poiList, ...porList]
                if (detail) {
                    value[detail?._id]?.map(async (v, index) => {
                        let memberResult = await reorderValue(newData, v, true, index + 1, detail)
                        await memberResult.map((member) => {
                            memberArray.push(member)
                        })
                    })
                }
            }))
        }

        let array = await reorderValue(data, value)

        let allArray = [...memberArray, ...array]

        const response = await addKycFunction(allArray, verificationType)

        if (response?.status === "success") {
            handleCancel()
        }

    }

    return (
        <Modal className={'kycModal'} title={title} open={isModalOpen} footer={null} onCancel={handleCancel}
               destroyOnClose={true}
               width={"70%"}>
            {/*<Modal className={'kycModal'} title={t('common.' + title)} open={isModalOpen} footer={null} onCancel={handleCancel}
            destroyOnClose={true} width={"70%"}>*/}
            <Form name="basic" id={"kycForm"} className={"margin-top-0-75"} onFinish={onSubmit} layout={"vertical"}>
                <Row gutter={[16, 16]}>
                    {
                        data?.length > 0 && data?.map((item) => {
                            if (item?.isEnabled === true) {
                                if (item?.kycField?.fieldType === "text" || item?.kycField?.fieldType === "file" || item?.kycField?.fieldType === "date") {
                                    return (
                                        <KycField_2 key={item?.kycField?._id} item={item}/>
                                    )
                                } else if (item?.kycField?.fieldType === "member") {
                                    return (
                                        <Col span={24}>
                                            <Row gutter={[16, 0]} key={item?.kycField?.label}>
                                                <Col span={24}>
                                                    <label>
                                                        {/*{t(item?.kycField?.label)} (Member)*/}
                                                        {t(item?.kycField?.label)} ({t('userVerificationSteps.member')})
                                                    </label>
                                                    <Form.List label={t(item?.kycField?.label)}
                                                               name={item?._id}
                                                               key={item?.kycField?.label}
                                                               rules={[{
                                                                   required: item?.isRequired,
                                                                   message: 'Please input ' + item?.kycField?.label
                                                                   // message: t('common.msgRules') + t(item?.kycField?.label),
                                                               }]}>
                                                        {(fields, {add, remove}) => (
                                                            <>
                                                                {fields.map(({key, name, ...restField}) => (
                                                                    <Row gutter={[16, 16]}>
                                                                        <Col span={23}>
                                                                            <Row gutter={[16, 16]}>
                                                                                {
                                                                                    poiList?.map((items) => {
                                                                                        if (items.isEnabled === true) {
                                                                                            return (
                                                                                                <KycField_2
                                                                                                    item={items}
                                                                                                    isMember={true}
                                                                                                    indexing={key.toString()}
                                                                                                    restField={restField}
                                                                                                    name={name}/>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                                {
                                                                                    porList?.map((items) => {
                                                                                        if (items.isEnabled === true) {
                                                                                            return (
                                                                                                <KycField_2
                                                                                                    item={items}
                                                                                                    isMember={true}
                                                                                                    indexing={key.toString()}
                                                                                                    restField={restField}
                                                                                                    name={name}/>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={1}>
                                                                            <MinusCircleOutlined
                                                                                onClick={() => remove(name)}/>
                                                                        </Col>
                                                                    </Row>
                                                                ))}
                                                                <Form.Item>
                                                                    <Button type="dashed" onClick={() => add()}
                                                                            block icon={<PlusOutlined/>}>
                                                                        {/*Add Member*/}
                                                                        {t('userVerificationSteps.addMember')}
                                                                    </Button>
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </Form.List>

                                                </Col>
                                                <Col span={24}>
                                                    {
                                                        item?.hasExpiredDate === true && (
                                                            <Form.Item
                                                                name={item?._id + "_expiredDate"}
                                                                label={t(item?.kycField?.label) + " Expired Date"}
                                                                // label={t(item?.kycField?.label) + " " +t(userVerificationSteps.expDate)}
                                                                rules={[{
                                                                    required: true,
                                                                    message: 'Please input ' + t(item?.kycField?.label) + " Expired Date"
                                                                    // message: t('common.msgRules') + t(item?.kycField?.label) + " " +t(userVerificationSteps.expDate),
                                                                }]}
                                                                key={`${item?.kycField?.label}`}
                                                            >
                                                                <DatePicker/>
                                                            </Form.Item>
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                }
                            }
                        })
                    }
                </Row>

                <Col span={24} className={"text-end"}>
                    <Button htmlType={"submit"}>
                        {t('common.submit')}
                    </Button>
                </Col>

            </Form>
        </Modal>
    )

}

export default KycModal_2