import {getMemberInitialData, getMemberInitialDataWithoutToken} from "../api/graphql/initialDataSetup";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import i18next from "i18next";
import {
    globalAvailableProductState,
    globalBrandConfigState,
    globalBrandModuleSettingLoadingState,
    globalBrandModuleSettingState,
    globalBrandSettingState,
    globalDashboardEducationState,
    globalDashboardMarketNewsState,
    globalDashboardNewsletterState,
    globalDashboardOpportunityState,
    globalDashboardPromotionState,
    globalDashboardSymbolPriceState,
    globalDefaultWebModuleSettingState,
    globalEducationPostingState,
    globalHasProductState,
    globalIconListState, globalIsLoginState,
    globalLanguageListState,
    globalNotificationState,
    globalPlatformState,
    globalProfileSetting,
    globalReferralSettingState,
    globalSymbolCategoryState,
    globalUserDefaultTradingAccount,
    globalUserSettingsState,
    globalUserTradingAccount,
    globalWebModuleSettingState,
} from "./global_state";
import {getUserFavouriteSymbol} from "../api/graphql/favouriteSymbol";
import {getTradingAccount} from "../api/graphql/tradingAccount";
// import {getProfileInformationSetting} from "../api/graphql/profileInformation";
import {getEducationCategory, getEducationPosting,} from "../api/restful/education";
import {getMarketNews} from "../api/restful/market-news";
import {getOpportunities} from "../api/restful/opportunities";
import {getNewsletter} from "../api/graphql/newsletter";
import {getPromotions} from "../api/restful/promotion";
import {getInstruments, getSymbolPrice, getSymbolPriceMt5} from "../api/restful/symbol-price";
import {getDefaultWebModuleSetting, getUserPreferenceNavigation,} from "../api/graphql/webModuleSetting";
import {getReferralSetting} from "../api/graphql/referralSetting";
import useShared from "./_shared";
import {getSymbolMappingCategory} from "../api/graphql/symbolCategory";
import {categoryFakeData} from "../../config/categoryFakeData";
import {getUserSettingByUserID} from "../api/graphql/user";

function usePreload() {
    const [brandSettingState, setBrandSettingState] = useRecoilState(globalBrandSettingState);
    const setLanguageListState = useSetRecoilState(globalLanguageListState);
    const setUserSettings = useSetRecoilState(globalUserSettingsState);
    const setUserTradingAccountState = useSetRecoilState(globalUserTradingAccount);
    const setUserDefaultTradingAccountState = useSetRecoilState(globalUserDefaultTradingAccount);
    const setProfileSetting = useSetRecoilState(globalProfileSetting);
    const setNotificationMessage = useSetRecoilState(globalNotificationState);
    const setDashboardEducation = useSetRecoilState(globalDashboardEducationState);
    const setDashboardMarketNews = useSetRecoilState(globalDashboardMarketNewsState);
    const setDashboardOpportunity = useSetRecoilState(globalDashboardOpportunityState);
    const setDashboardNewsletter = useSetRecoilState(globalDashboardNewsletterState);
    const setDashboardPromotion = useSetRecoilState(globalDashboardPromotionState);
    const setDashboardSymbolPrice = useSetRecoilState(globalDashboardSymbolPriceState);
    const brandConfigState = useRecoilValue(globalBrandConfigState);
    const setIconListState = useSetRecoilState(globalIconListState);
    const setWebModuleSetting = useSetRecoilState(globalWebModuleSettingState);
    const setBrandModuleSetting = useSetRecoilState(globalBrandModuleSettingState);
    const setBrandModuleSettingLoading = useSetRecoilState(globalBrandModuleSettingLoadingState);
    const setReferralSetting = useSetRecoilState(globalReferralSettingState);
    const setDefaultWebModuleSetting = useSetRecoilState(globalDefaultWebModuleSettingState);
    const setEducationPosting = useSetRecoilState(globalEducationPostingState);
    const setSymbolCategoryState = useSetRecoilState(globalSymbolCategoryState);
    const setHasProduct = useSetRecoilState(globalHasProductState);
    const setPlatform = useSetRecoilState(globalPlatformState)
    const [availableProductState, setAvailableProductState] = useRecoilState(globalAvailableProductState);

    const {convertLanguageFile} = useShared();

    const cmsUrl = localStorage?.getItem("cmsUrl");

    const getInitialDataWithoutToken = async () => {
        try {
            if (brandSettingState?.defaultDomain) return

            const initialDataWithoutToken = await getMemberInitialDataWithoutToken();

            const getLanguage = initialDataWithoutToken?.getLanguage?.data
            const getLanguageDropdown = initialDataWithoutToken?.getLanguageDropdown?.data
            const getUserBrandSetting = initialDataWithoutToken?.getUserBrandSetting
            const getIconList = initialDataWithoutToken?.getIconList?.data
            const getReferralSetting = initialDataWithoutToken?.getReferralSetting

            if (getLanguage) {
                let languageData = getLanguage;
                let languageFile = convertLanguageFile(languageData);
                window.lang = languageData;
                languageData?.forEach((item) => {
                    i18next.addResourceBundle(
                        item?.code,
                        "translation",
                        languageFile?.[item?.code]?.["translation"]
                    );
                });
            }

            if (getReferralSetting) setReferralSetting(getReferralSetting)

            if (getLanguageDropdown) {
                let languageData = getLanguageDropdown;
                let languageList = [];
                window.lang = languageData;
                languageData?.forEach((item) => {
                    languageList.push({
                        name: item?.name,
                        code: item?.code,
                        countryCode: item?.countryCode,
                        nativeName: item?.nativeName
                    });
                });
                setLanguageListState(languageList);
            }

            if (getUserBrandSetting) {
                localStorage.setItem("timezone", getUserBrandSetting.timezone)
                setBrandSettingState(getUserBrandSetting);
            }

            if (getIconList) setIconListState(getIconList);

        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the initial data. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getInitialData = async () => {
        try {
            const initialData = await getMemberInitialData();
            const getAllAvailableProductByUserId = initialData?.getAllAvailableProductByUserId?.data
            // const getUserSettingByUserID = initialData?.getUserSettingByUserID?.data
            const getProfileInformationSetting = initialData?.getProfileInformationSetting?.data
            const getModuleSetting = initialData?.getModuleSetting?.data

            if (getAllAvailableProductByUserId) {
                const productOptions =
                    getAllAvailableProductByUserId.map((p) => {
                        return {
                            label: p.name,
                            value: p._id,
                            productType: p?.productType,
                            minDeposit: p?.minDeposit,
                            leverage: p?.leverage,
                            server: p?.server,
                            spreadGroup: p?.spreadGroup,
                        };
                    });
                setAvailableProductState(productOptions);
            }

            if (getProfileInformationSetting) setProfileSetting(getProfileInformationSetting)

            if (getModuleSetting) {
                setBrandModuleSettingLoading(true);
                await setBrandModuleSetting(getModuleSetting);
            }

            setBrandModuleSettingLoading(false);
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the initial data. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDashboardEducationPosting = async () => {
        try {
            const response = await getEducationPosting();

            setEducationPosting(response.data);
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the education posting details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const defaultWebModuleSetting = async () => {
        try {
            const getDefaultSetting = await getDefaultWebModuleSetting();
            setDefaultWebModuleSetting(getDefaultSetting?.getWebModuleSetting?.data);
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the default module. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getReferralDataSetting = async () => {
        try {
            const referral = await getReferralSetting();
            setReferralSetting(referral?.getReferralSetting);
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the referral module. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    // const getAvailableProduct = async () => {
    //     try {
    //         const userId = localStorage.getItem("userid") || null;
    //
    //         if (!userId) return null
    //
    //         const availableProduct = await getAllAvailableProductByUserId(userId, [
    //             "selfTradeLive",
    //             "selfTradeDemo",
    //         ]);
    //         const productOptions =
    //             availableProduct?.getAllAvailableProductByUserId?.data?.map((p) => {
    //                 return {
    //                     label: p.name,
    //                     value: p._id,
    //                     productType: p?.productType,
    //                     minDeposit: p?.minDeposit,
    //                     leverage: p?.leverage,
    //                     server: p?.server,
    //                     spreadGroup: p?.spreadGroup,
    //                 };
    //             });
    //         setAvailableProductState(productOptions);
    //         return productOptions;
    //     } catch (error) {
    //     }
    // };

    const getDefaultTradingAccount = async () => {
        try {
            const userId = localStorage.getItem("userid") || null;
            const tradingAccounts = await getTradingAccount(userId);
            const accounts = tradingAccounts?.getTradingAccount?.data;
            const defaultAccount =
                accounts.find(
                    (item) => item?.mode === "live" && item?.isDefault === true
                ) || accounts.find((item) => item?.mode === "live");

            setUserTradingAccountState(accounts);
            setUserDefaultTradingAccountState(defaultAccount);

            return defaultAccount;
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the default trading account. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    // const getBrandSetting = async () => {
    //     try {
    //         const userId = localStorage.getItem("userid") || null;
    //         const language = localStorage.getItem("language") || "en";
    //         const brandSetting = await getUserBrandSetting({
    //             userid: userId,
    //             language: language,
    //         });
    //         setBrandSettingState(brandSetting.getUserBrandSetting);
    //         localStorage.setItem("timezone", brandSetting?.getUserBrandSetting?.timezone)
    //     } catch (e) {
    //         setNotificationMessage({
    //             status: "error",
    //             title: "",
    //             msg: "Apologies, we are currently undergoing maintenance for the brand setting details. We're actively working to resolve the issue.",
    //             key: "systemNotification",
    //         });
    //     }
    // };

    // const getProfileSetting = async () => {
    //     try {
    //         const profile = await getProfileInformationSetting();
    //         setProfileSetting(profile.getProfileInformationSetting.data);
    //     } catch (e) {
    //         setNotificationMessage({
    //             status: "error",
    //             title: "",
    //             msg: "Apologies, we are currently undergoing maintenance for the profile setting. We're actively working to resolve the issue.",
    //             key: "systemNotification",
    //         });
    //     }
    // };

    const getDashboardEducation = async () => {
        try {
            const response = await getEducationCategory();
            setDashboardEducation(response.data);
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the education details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDashboardMarketNews = async () => {
        try {
            const response = await getMarketNews();
            setDashboardMarketNews(response.data);
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the market news details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDashboardOpportunity = async (baseSymbolId) => {
        try {
            const response = await getOpportunities(
                localStorage.getItem("userid"),
                null,
                baseSymbolId || null,
                6
            );
            setDashboardOpportunity(response?.article);
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the opportunities details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDashboardNewsletter = async () => {
        try {
            const response = await getNewsletter();
            setDashboardNewsletter(response?.getNewsletter?.data);
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the newsletter details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDashboardPromotion = async () => {
        try {
            if (cmsUrl) {
                const response = await getPromotions(cmsUrl);
                const filtered = response?.data.filter((item) => item?.active === true);
                // if (filtered.length < 4) {
                //     setDashboardPromotion([...filtered, ...filtered]);
                // } else {
                setDashboardPromotion(filtered);
                // }
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the promotion details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDashboardSymbolPrice = async (serverData, spreadGroup) => {
        try {
            const userId = localStorage.getItem("userid") || null;
            if (userId && serverData) {
                const userFavouriteSymbol = await getUserFavouriteSymbol(
                    userId,
                    serverData?._id
                );

                let symbol = [];

                if (serverData?.platform === "cTrader") {
                    const baseSymbolId = userFavouriteSymbol?.getUserFavouriteSymbol?.favouriteSymbol.map(d => d?.baseSymbolId) || []
                    let serverName = serverData?.plantId === "blueocean" ? "server2" : "server1"

                    const symbolPrice = await getSymbolPrice(
                        null,
                        spreadGroup,
                        serverName
                    );

                    const baseInstrumentData = await getInstruments(baseSymbolId.join(","));

                    await Promise.all(
                        [...userFavouriteSymbol?.getUserFavouriteSymbol?.favouriteSymbol]?.map(async (item) => {
                            const detail = await baseInstrumentData?.find((symbol) => symbol?.base_symbol_id === Number(item?.baseSymbolId));
                            const price = await symbolPrice?.payload?.find((v) => v?.symbolId === Number(item?.symbolId));

                            symbol.push({
                                symbolName: item?.name,
                                ...price,
                                baseSymbolId: item?.baseSymbolId,
                                icon: detail?.icon_link || detail?.[0]?.icon_link || null,
                                instrumentName: detail?.instrument_name,
                            });
                        })
                    );

                } else if (brandConfigState?.tradeURL) {
                    const baseSymbolId = userFavouriteSymbol?.getUserFavouriteSymbol?.favouriteSymbolMt5.map(d => d?.baseSymbolId) || []
                    const baseInstrumentData = await getInstruments(baseSymbolId.join(","));
                    const symbolPrice = await getSymbolPriceMt5(brandConfigState?.tradeURL, null, spreadGroup)

                    // const decryptedSymbolPrice = decryptMT5Message(symbolPrice)

                    await Promise.all(
                        [...userFavouriteSymbol?.getUserFavouriteSymbol?.favouriteSymbolMt5]?.map(async (item) => {
                            const detail = await baseInstrumentData?.find((symbol) => symbol?.base_symbol_id === Number(item?.baseSymbolId));
                            const price = await symbolPrice?.payload?.data?.find((v) => v?.symbol === item?.symbol);

                            symbol.push({
                                symbolName: price?.symbol,
                                ...price,
                                baseSymbolId: item?.baseSymbolId,
                                icon: detail?.icon_link || detail?.[0]?.icon_link || null,
                                instrumentName: detail?.instrument_name,
                            });
                        })
                    );

                }

                setDashboardSymbolPrice(symbol);
            }
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the symbol price details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getSymbolMappingCategories = async (serverData) => {
        try {
            const userId = localStorage.getItem("userid") || null;
            if (userId && serverData) {
                const symbolCategory = await getSymbolMappingCategory(serverData?.name);
                const symbolCategoryData = structuredClone(
                    symbolCategory?.getSymbolMappingCategory?.data ?? []
                );
                const baseSymbolIds = symbolCategoryData?.map((c) =>
                    c?.symbol?.map((s) => s?.baseSymbolId)
                );

                // Fetch instrument for icons
                const instruments = await getInstruments(baseSymbolIds.join(","));
                const instrumentMap =
                    instruments?.reduce(
                        (a, b) => ({...a, [b.base_symbol_id]: b}),
                        {}
                    ) || {};

                symbolCategoryData?.forEach((c) => {
                    (c.symbol || [])?.forEach((s) => {
                        const bsi = s.baseSymbolId;
                        s.icon = instrumentMap[bsi]?.icon_link ?? undefined;
                        // c.symbol = { ...c.symbol, icon: instrumentMap[bsi] ?? undefined };
                    });
                });

                setSymbolCategoryState(symbolCategoryData);
            }
        } catch (e) {
            console.error(e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the symbol price details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    // const getIconLists = async () => {
    //     try {
    //         const iconList = await getIconList();
    //         setIconListState(iconList?.getIconList?.data);
    //     } catch (e) {
    //         setNotificationMessage({
    //             status: "error",
    //             title: "",
    //             msg: "Apologies, we are currently undergoing maintenance for the icon listing details. We're actively working to resolve the issue.",
    //             key: "systemNotification",
    //         });
    //     }
    // };

    // const getBrandModuleSetting = async () => {
    //     try {
    //         setBrandModuleSettingLoading(true);
    //         const brandModule = await getModuleSetting();
    //         setBrandModuleSetting(brandModule.getModuleSetting.data);
    //     } catch (e) {
    //         setNotificationMessage({
    //             status: "error",
    //             title: "",
    //             msg: "Apologies, we are currently undergoing maintenance for the web module setting. We're actively working to resolve the issue.",
    //             key: "systemNotification",
    //         });
    //     } finally {
    //         setBrandModuleSettingLoading(false);
    //     }
    // };

    const getUserPreferenceNavigations = async (userId) => {
        try {
            const module = await getUserPreferenceNavigation(userId);
            setWebModuleSetting(module?.getUserPreferenceNavigation?.webNavigation);
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the web module setting. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        } finally {
        }
    };

    const getUserSettings = async (userId) => {
        try {
            const response = await getUserSettingByUserID(userId);
            setUserSettings(response?.getUserSettingByUserID);
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the web module setting. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        } finally {
        }
    };

    // const getLanguages = async () => {
    //     try {
    //         const response = await getLanguage();
    //         if (response?.getLanguage?.data) {
    //             let languageData = response?.getLanguage?.data;
    //             let languageFile = convertLanguageFile(languageData);
    //             let languageList = [];
    //             window.lang = languageData;
    //             languageData?.forEach((item) => {
    //                 languageList.push({
    //                     name: item?.name,
    //                     code: item?.code,
    //                     countryCode: item?.countryCode,
    //                     nativeName: item?.nativeName
    //                 });
    //                 i18next.addResourceBundle(
    //                     item?.code,
    //                     "translation",
    //                     languageFile?.[item?.code]?.["translation"]
    //                 );
    //             });
    //             setLanguageListState(languageList);
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const preload = async () => {
        const userId = localStorage.getItem("userid") || null;
        const language = localStorage.getItem("language") || "en";
        if (!language) {
            localStorage.setItem("language", "en");
        } else {
            i18next.changeLanguage(language);
        }

        if (brandConfigState?.backendURL) {
            if (userId && userId !== null) {
                const tradingAcc = await getDefaultTradingAccount();
                const products = availableProductState;
                let serverData, spreadGroup
                if (tradingAcc) {
                    serverData = tradingAcc?.product?.server
                    if (serverData?.platform === "cTrader") {
                        spreadGroup = tradingAcc?.spreadGroup?.name
                    } else {
                        spreadGroup = tradingAcc?.mt5SpreadGroup?.group
                    }
                } else {
                    serverData = products?.find((p) => p?.productType === "selfTradeLive")?.server
                    if (serverData?.platform === "cTrader") {
                        spreadGroup = products?.find((p) => p?.productType === "selfTradeLive")?.spreadGroup?.[0]?.name
                    } else {
                        spreadGroup = products?.find((p) => p?.productType === "selfTradeLive")?.spreadGroupMt5?.[0]?.group
                    }
                }

                // setPlatform(serverData?.platform)

                if (serverData) {
                    localStorage.setItem("platform", serverData?.platform)
                    getDashboardSymbolPrice(serverData, spreadGroup);
                    await getSymbolMappingCategories(serverData);
                    setHasProduct(true);
                } else {
                    localStorage.setItem("platform", "cTrader")
                    setHasProduct(false);
                    setSymbolCategoryState(categoryFakeData);
                }
                await getUserSettings(userId);
                // await getInitialData();
                // await getProfileSetting();
                await getUserPreferenceNavigations(userId);
            }
            // await getInitialDataWithoutToken();
            // await getBrandSetting();
            await defaultWebModuleSetting();
        }
    };

    return {
        preload,
        getInitialDataWithoutToken,
        getInitialData,
        // getBrandSetting,
        // getBrandModuleSetting,
        getUserPreferenceNavigations,
        // getLanguages,
        defaultWebModuleSetting,
        getReferralDataSetting,
        getDashboardMarketNews,
        getDashboardEducation,
        getDashboardEducationPosting,
        getDashboardOpportunity,
        getDashboardNewsletter,
        getDashboardPromotion,
        // getIconLists,
        getDashboardSymbolPrice,
        // getAvailableProduct,
    };
}

export default usePreload;
