import React from "react";
import { BRAND_NAME } from "../../../config/_variable";
import MinosEWalletCard from "./minosmarkets";

export default function EWalletCard() {
    let component;
    switch (BRAND_NAME) {
        case "minosmarkets":
            component = <MinosEWalletCard />;
            break;

        default:
            component = <MinosEWalletCard />;
            break;
    }

    return component;
}
