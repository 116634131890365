import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_USER_FAVOURITE_SYMBOL = gql`
    query getUserFavouriteSymbol($userid: String,$server:String) {
        getUserFavouriteSymbol(userid: $userid,server:$server) {
            ... on UserFavouriteSymbol {
                favouriteSymbol {
                    name
                    symbolId
                    baseSymbolId
                }
                favouriteSymbolMt5 {
                    symbol
                    baseSymbolId
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getUserFavouriteSymbol(userid, server) {

    const variables = {
        userid: userid,
        server: server,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };

    const execute = await ApolloClientQuery(GET_USER_FAVOURITE_SYMBOL, variables, false);

    return execute;
}

const UPDATE_USER_FAVOURITE_SYMBOL = gql`
    mutation updateUserFavouriteSymbol($input: UserFavouriteSymbolInput) {
        updateUserFavouriteSymbol(input: $input) {
            ... on UserFavouriteSymbol {
                favouriteSymbol {
                    symbolId
                    name
                }

            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function updateUserFavouriteSymbol(userid, favouriteSymbol, server) {

    const variables = {
        input: {
            user: userid,
            favouriteSymbol: favouriteSymbol,
            server: server
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_FAVOURITE_SYMBOL, variables, true);

    return execute;
}