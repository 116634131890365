import {Col, DatePicker, Form, Input, Row, Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import {useRecoilState} from "recoil";
import {globalBrandConfigState} from "../../../../../_common/global_state";
import {useTranslation} from "react-i18next";


export const KycField_2 = ({item, isMember, indexing, name}) => {
    const [brandConfigState, setBrandConfigState] = useRecoilState(globalBrandConfigState)
    const { t } = useTranslation();
    let component
    switch (item?.kycField?.fieldType) {
        case "text":
            component = <Col span={12}>
                <Row gutter={[16, 0]} key={item?.kycField?.label}>
                    <Col span={24}>
                        <Form.Item label={t(item?.kycField?.label)}
                                   name={isMember ? [name, item?._id] : item?._id}
                                   rules={[{
                                       required: item?.isRequired,
                                       message: 'Please input your ' + t(item?.kycField?.label)
                                       // message: t('common.msgRules') + t(item?.kycField?.label),
                                   }]}
                                   key={item?.kycField?.label}
                        >
                            <Input/>
                        </Form.Item>

                    </Col>
                    <Col span={24}>
                        {
                            item?.hasExpiredDate === true && (
                                <Form.Item
                                    name={isMember ? [name, item?._id + "_expiredDate"] : item?._id + "_expiredDate"}
                                    label={t(item?.kycField?.label) + " Expired Date"}
                                    // label={t(item?.kycField?.label) + " " +t(userVerificationSteps.expDate)}
                                    rules={[{
                                        required: true,
                                        message: 'Please input your ' + t(item?.kycField?.label) + " Expired Date"
                                        // message: t('common.msgRules') + t(item?.kycField?.label) + " " +t(userVerificationSteps.expDate),

                                    }]}
                                    key={item?.kycField?.label}
                                >
                                    <DatePicker/>
                                </Form.Item>
                            )
                        }
                    </Col>
                </Row>
            </Col>
            break;
        case "file":
            component = <Col span={12}>
                <Row gutter={[16, 0]} key={item?.kycField?.label}>
                    <Col span={24}>
                        <Form.Item label={t(item?.kycField?.label)}
                                   name={isMember ? [name, item?._id] : item?._id}
                                   rules={[{
                                       required: item?.isRequired,
                                       message: 'Please input ' + t(item?.kycField?.label)
                                       // message: t('common.msgRules') + t(item?.kycField?.label),

                                   }]}
                                   key={item?.kycField?.label}
                        >
                            <Upload.Dragger name={item?.kycField?.label.toString()}
                                            accept={"image/*"} maxCount={1}
                                            action={`${brandConfigState?.backendURL}api/upload/kyc/image`}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined/>
                                </p>
                                <p className="ant-upload-text">Click or drag file to this
                                    area to upload</p>
                                {/*<p className="ant-upload-text">{t(userVerificationSteps.uploadDrag)}</p>*/}
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        {
                            item?.hasExpiredDate === true && (
                                <Form.Item
                                    name={isMember ? [name, item?._id + "_expiredDate"] : item?._id + "_expiredDate"}
                                    label={t(item?.kycField?.label) + " Expired Date"}
                                    // label={t(item?.kycField?.label) + " " +t(userVerificationSteps.expDate)}
                                    rules={[{
                                        required: true,
                                        message: 'Please input ' + t(item?.kycField?.label) + " Expired Date"
                                        // message: t('common.msgRules') + t(item?.kycField?.label) + " " +t(userVerificationSteps.expDate),

                                    }]}
                                    key={`${item?.kycField?.label}`}
                                >
                                    <DatePicker/>
                                </Form.Item>
                            )
                        }
                    </Col>
                </Row>
            </Col>
            break;
        case "date":
            component = <Col span={12}>
                <Row gutter={[16, 0]} key={item?.kycField?.label}>
                    <Col span={24}>
                        <Form.Item label={t(item?.kycField?.label)}
                                   name={isMember ? [name, item?._id] : item?._id}
                                   rules={[{
                                       required: item?.isRequired,
                                       message: 'Please input ' + t(item?.kycField?.label)
                                       // message: t('common.msgRules') + t(item?.kycField?.label),
                                   }]}
                                   key={item?.kycField?.label}
                        >
                            <DatePicker style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        {
                            item?.hasExpiredDate === true && (
                                <Form.Item
                                    name={isMember ? [name, item?._id + "_expiredDate"] : item?._id + "_expiredDate"}
                                    label={t(item?.kycField?.label) + " Expired Date"}
                                    // label={t(item?.kycField?.label) + " " +t(userVerificationSteps.expDate)}
                                    rules={[{
                                        required: true,
                                        message: 'Please input ' + t(item?.kycField?.label) + " Expired Date"
                                        // message: t('common.msgRules') + t(item?.kycField?.label) + " " +t(userVerificationSteps.expDate),
                                    }]}
                                    key={`${item?.kycField?.label}`}
                                >
                                    <DatePicker/>
                                </Form.Item>
                            )
                        }
                    </Col>
                </Row>
            </Col>
            break;
    }

    return component
}