import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_PROFILE_INFORMATION = gql`
    query getProfileInformationSetting($filter: ProfileInformationSettingSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getProfileInformationSetting(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on ProfileInformationSettingArray {
                data {
                    _id
                    fieldList {
                        _id
                        label
                        fieldName
                        fieldType
                        isSensitive
                        isRequired
                        isUnique
                        isUsedForLogin
                        optionValue {
                            label
                            value
                            key
                        }
                        category
                        isDisplayToClient
                        minCharacterLimit
                        maxCharacterLimit
                    }
                    type
                    position
                }
            }

            ...on BaseError {
                errKey
                errMsg
            }
        }
    }
`

export async function getProfileInformationSetting() {

    const variables = {
        filter: {
            type: "client"
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_PROFILE_INFORMATION, variables, false);

    return execute;
}

const GET_FIELD_LIST = gql`
query getFieldList($filter: FieldListSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
  getFieldList(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
    ...on FieldListArray {
      data {
        _id
        fieldName
        isSensitive
        isRequired
        isUnique
        isConfirmRequired
        isUsedForLogin
        minCharacterLimit
        maxCharacterLimit
      }
    }
    ...on BaseError {
      errKey
      errMsg
    }
  }
}
`

export async function getFieldList() {

    const variables = {
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_FIELD_LIST, variables, false);

    return execute;
}