import axios from "axios";

export async function getEducationCategory() {
    const cmsUrl = localStorage?.getItem("cmsUrl");
    const lang = localStorage.getItem("language");
    const headers = {
        Authorization: "e01a4b78-d5ca-404e-a63c-7e8c032eeb38",
    };
    return await axios({
        url: `https://cmsapi.` + cmsUrl + `/api/section/getSectionCategory?name=education&lang=${lang}`,
        method: "get",
        headers: headers,
    })
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
}

export async function getEducationPosting() {
    const cmsUrl = localStorage?.getItem("cmsUrl");
    const lang = localStorage.getItem("language");
    const headers = {
        Authorization: "e01a4b78-d5ca-404e-a63c-7e8c032eeb38",
    };
    return await axios({
        url: `https://cmsapi.` + cmsUrl + `/api/section/getSectionPosting?name=education&lang=${lang || "en"}`,
        method: "get",
        headers: headers,
    })
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
}
