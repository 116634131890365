import {Button, Card, Col, Image} from "antd";
import dummy from "../../../../assets/image/dummy_obj.png";
import {useTranslation} from "react-i18next";

const PlatformDownloadCard = (props) => {
    const {data} = props;
    const language = localStorage.getItem("language");

    const {t} = useTranslation();

    // const handleDownload = (link,fileName) => {
    //     // Create a temporary anchor element to trigger the download
    //     const linkElement = document.createElement('a');
    //     linkElement.href = link;
    //     linkElement.download = fileName;
    //     linkElement.click();
    // };

    const platformDisplayName =
        data?.displayNameLanguage?.find((v) => v?.language === language)?.label ??
        data?.displayNameLanguage?.find((v) => v?.language === "en")?.label ??
        data?.displayName;

    return (
        <a href={data?.link} target="_blank">
            <Card className={"platformDownloadCard"}>
                <Col span={24} className={"text-center"}>
                    <Image
                        width={115}
                        height={115}
                        src={data?.icon ? data?.icon : data?.defaultIcon ? data?.defaultIcon : dummy}
                        style={{objectFit: "cover"}}
                        preview={false}
                    />
                    <h6 className={"mt-4"}>{platformDisplayName}</h6>
                    {/*<h6 className={"mt-4"}>{t(data?.displayName)}</h6>*/}
                    {/* <Button className={"w-100 mt-3"} href={data?.link} target="_blank">
                    {t('common.download')}
                </Button> */}
                </Col>
            </Card>
        </a>
    );
};

export default PlatformDownloadCard;
