// import {Empty} from "antd";
// import IconCommon from "../../../../../shared/_common/_iconcommon";
import useShared from "../../../../../shared/_common/_shared";

export default function AlliumtradesEmptyData(props) {
    const {title} = props;
    const {IconCommon} = useShared();
    return (
        <>
            <IconCommon iconName={"xt-empty-box-regular"} className={"empty-data"} title={title || "noData"} />
        </>
    );
}
