import React, {useEffect, useState} from "react";
import {Avatar, Button, Col, Divider, Drawer, Dropdown, Layout, Menu, Row, Select, Switch,} from "antd";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    globalBrandConfigState,
    globalBrandSettingState,
    globalColorThemeState,
    globalIconListState,
    globalLanguageListState, globalOpenGoLiveModalState,
    globalUserDetailState,
    globalUserWalletState,
} from "../../../_common/global_state";
import {DownloadOutlined} from "@ant-design/icons";
import QrModal from "./qr/qrModal";
import {formatMoney, truncateString} from "../../../_common/function";
import PlatformDownloadModal from "./platformDownload/platformDownloadModal";
import {getPlatform} from "../../../api/graphql/platformDownload";
import dummyImg from "../../../assets/image/dummy.png";
import {useNavigate} from "react-router-dom";
import useShared from "../../../_common/_shared";
import {useTranslation} from "react-i18next";
import GoLiveModal from "./goLive";
import {useVerification} from "../../../_common/_authorize";
import {useProfileFunction} from "../../../pages/profile/function";
import useLayoutFunction from "../../function";

export const Header_2 = (props) => {
    const {registerField} = props;
    const {t, i18n} = useTranslation();
    const {handleTokenExpiration} = useVerification();
    const {Header} = Layout;
    const {Option} = Select;
    const [logo, setLogo] = useState();
    const [openQrModal, setOpenQrModal] = useState(false);
    const [openGoLiveModal, setOpenGoLiveModal] = useRecoilState(globalOpenGoLiveModalState);
    const [openPlatformModal, setOpenPlatformModal] = useState(false);
    const [platformData, setPlatformData] = useState([]);
    const userDetails = useRecoilValue(globalUserDetailState);
    const brandConfigState = useRecoilValue(globalBrandConfigState);
    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const languageListState = useRecoilValue(globalLanguageListState);
    const walletState = useRecoilValue(globalUserWalletState);
    const iconListing = useRecoilValue(globalIconListState);
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const {IconCommon} = useShared();
    const {changeLanguageHandler} = useLayoutFunction()

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    function filterAndGroupServers(arr) {
        const result = {};
        let appObject = null;

        for (const obj of arr) {
            const serverName = obj?.server?.name;
            const isEnabled =
                obj?.windowLinkObject.isEnabled ||
                obj?.macOSXLinkObject.isEnabled ||
                obj?.androidAPKLinkObject.isEnabled ||
                obj?.iOSDownloadLinkObject.isEnabled ||
                obj?.androidDownloadLinkObject.isEnabled ||
                obj?.huaweiDownloadLinkObject.isEnabled;

            if (obj.type === "app" && isEnabled) {
                appObject = obj;
            }

            if (serverName && isEnabled) {
                result[serverName] = obj;
            }
        }

        if (appObject) {
            result["App"] = appObject;
        }

        return result;
    }

    const preload = async () => {
        const response = await getPlatform();
        const filtered = await filterAndGroupServers(response?.getPlatform?.data);
        setPlatformData(filtered);
    };

    useEffect(() => {
        preload();

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 796);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const dropdownItems = (
        <Menu className={"headerDetails"}>
            <Menu.Item key="1">
                {t("common.hello")}, <strong>{userDetails?.username}</strong>
            </Menu.Item>
            <Menu.Item key="2">
                <strong>{t("user.rank")}:</strong> {userDetails?.rank?.name || "-"}
            </Menu.Item>
            {/*<Menu.Item key="3">*/}
            {/*    <strong>{t("user.role")}:</strong> {userDetails?.role?.username || "-"}*/}
            {/*</Menu.Item>*/}
            <Menu.Item key="4">
                <strong>{t("user.email")}:</strong>{" "}
                {truncateString(userDetails?.email || "", 30)}
            </Menu.Item>
            <Menu.Item key="5">
                <strong>{t("user.mobileNo")}:</strong>{" "}
                {truncateString(userDetails?.completedMobileNo || "", 30)}
            </Menu.Item>
            <Divider
                style={{margin: "0.3rem 0", background: "var(--divider-color)"}}
            />
            <Menu.Item key="6">
                <a
                    onClick={() => navigate("/profile")}
                    className={"d-flex align-items-center"}
                >
                    <IconCommon className={"headerIcon"} iconName={"edit"}/>
                    &emsp;{t("common.editProfile")}
                </a>
            </Menu.Item>
            <Divider
                style={{margin: "0.3rem 0", background: "var(--divider-color)"}}
            />
            <Menu.Item key="7">
                <a
                    onClick={() => handleTokenExpiration()}
                    className={"d-flex align-items-center"}
                >
                    <IconCommon className={"headerIcon"} iconName={"logout"}/>
                    &emsp;{t("common.logOut")}
                </a>
            </Menu.Item>
        </Menu>
    );

    const languageDropdown = (
        <Menu
            className={"headerLanguage"}
            selectedKeys={[localStorage.getItem("language")]}
        >
            {(languageListState ?? [])?.map((lang) => (
                <Menu.Item
                    key={lang?.code}
                    value={lang?.code}
                    onClick={() => changeLanguageHandler(lang?.code)}
                >
                    {lang?.nativeName}
                </Menu.Item>
            ))}
        </Menu>
    );

    const currentLanguageObj = languageListState?.find(
        (lang) => lang?.code === localStorage.getItem("language")
    );

    return (
        <Header id={"layout-header"}>
            {isMobile ? (
                <>
                    <div className={"row"} id={"header-row"}>
                        <Col className={"text-center col-3"}>
                            <img
                                className={"clickable"}
                                onClick={() => navigate("/dashboard")}
                                width={100}
                                src={
                                    colorState === "dark"
                                        ? brandSettingState?.companyLogoInverse
                                        : brandSettingState?.companyLogo
                                }
                            />
                        </Col>
                        <Col
                            className={
                                "text-center d-flex align-items-center col-9 justify-content-end"
                            }
                        >
                            <Button className={"navbar-toggler-layer"} onClick={showDrawer}>
                                <span className={"navbar-toggler-icon"}></span>
                            </Button>
                        </Col>
                    </div>
                    <Drawer
                        getContainer={() => document.getElementById("layout-2")}
                        title={
                            <>
                                <Dropdown
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                    overlay={languageDropdown}
                                    id={"language-select"}
                                >
                                    <a onClick={(e) => e?.preventDefault()}>
                                        {currentLanguageObj?.nativeName}
                                        {/* <Avatar size={30} src={"https://cmsapi.cronoscap.com/media/1671519233640-480-us.svg"} style={{margin: "0", border: "none"}} /> */}
                                    </a>
                                </Dropdown>
                                <Divider type="vertical" className={"headerDivider"}/>
                                <Switch
                                    checked={colorState === "light" && true}
                                    checkedChildren={
                                        <IconCommon
                                            isAlwaysActive={true}
                                            iconName={"sun"}
                                            className={"switchIcon"}
                                        />
                                    }
                                    unCheckedChildren={
                                        <IconCommon iconName={"moon"} className={"switchIcon"}/>
                                    }
                                    onChange={(e) => {
                                        if (e) {
                                            setColorState("light");
                                            localStorage.setItem("color", "light");
                                        } else {
                                            setColorState("dark");
                                            localStorage.setItem("color", "dark");
                                        }
                                    }}
                                />
                            </>
                        }
                        className={"headerDrawer"}
                        onClose={onClose}
                        open={open}
                    >
                        <Row>
                            <Col span={10} className={"text-center"}>
                                <Avatar
                                    size={100}
                                    src={userDetails?.profileImage || dummyImg}
                                />
                                <div
                                    className={"d-flex justify-content-center align-items-center"}
                                >
                                    <IconCommon
                                        iconName={"qr"}
                                        onClick={() => {
                                            setOpenQrModal(true);
                                        }}
                                        className={"mt-2 mx-2 qrIcon"}
                                    />
                                    <p className={"textColor mt-2 mb-0 mx-2"}>
                                        $ {formatMoney(walletState?.balance)}
                                    </p>
                                </div>
                            </Col>
                            <Col span={14}>
                                <p className={"textColor mb-2"}>
                                    {t("common.hello")}, <strong>{userDetails?.username}</strong>
                                </p>
                                <p className={"textColor mb-2"}>
                                    <strong>{t("user.rank")}:</strong> {userDetails?.rank?.name}
                                </p>
                                {/*<p className={"textColor mb-2"}>*/}
                                {/*    <strong>{t("user.role")}:</strong> {userDetails?.role}*/}
                                {/*</p>*/}
                                <p className={"textColor mb-2"}>
                                    <strong>{t("user.email")}:</strong> {userDetails?.email}
                                </p>
                                <p className={"textColor mb-2"}>
                                    <strong>{t("user.mobileNo")}:</strong>{" "}
                                    {userDetails?.completedMobileNo}
                                </p>
                            </Col>
                        </Row>
                        <Divider className={"dividerColor"}/>
                        <Row className={"buttons-row"}>
                            {userDetails?.clientAccountType === "demo" ? (
                                <>
                                    <Button
                                        className={"platform_download secondary_btn"}
                                        onClick={() => {
                                            setOpenGoLiveModal(true);
                                        }}
                                    >
                                        {t("common.goLive")}
                                    </Button>
                                </>
                            ) : null}

                            <Button
                                className={"platform_download secondary_btn mt-2"}
                                onClick={() => {
                                    setOpenPlatformModal(true);
                                }}
                                icon={
                                    <IconCommon className={"headerIcon"} iconName={"import"}/>
                                }
                            >
                                {t("module.platformDownload")}
                            </Button>

                            <Button
                                className={"w-100 mt-2 secondary_btn"}
                                onClick={() => {
                                    navigate("/wallet-withdrawal");
                                    onClose();
                                }}
                                icon={
                                    <IconCommon
                                        className={"headerIcon"}
                                        iconName={"withdrawal"}
                                    />
                                }
                            >
                                {t("module.withdrawal")}
                            </Button>

                            <Button
                                className={"w-100 primary_btn mt-2"}
                                onClick={() => {
                                    navigate("/wallet-deposit");
                                    onClose();
                                }}
                                icon={
                                    <IconCommon className={"headerIcon"} iconName={"deposit"}/>
                                }
                            >
                                {t("module.deposit")}
                            </Button>
                        </Row>
                        <Row className={"text-center toBottom"}>
                            <Col span={12}>
                                <Button
                                    type={"link"}
                                    onClick={() => {
                                        navigate("/profile");
                                        onClose();
                                    }}
                                    icon={
                                        <IconCommon className={"headerIcon"} iconName={"edit"}/>
                                    }
                                >
                                    {t("common.editProfile")}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    type={"link"}
                                    onClick={() => {
                                        onClose();
                                        handleTokenExpiration();
                                    }}
                                    icon={
                                        <IconCommon className={"headerIcon"} iconName={"logout"}/>
                                    }
                                >
                                    {t("common.logOut")}
                                </Button>
                            </Col>
                        </Row>
                    </Drawer>
                </>
            ) : (
                <Row className={"justify-content-between"}>
                    <Col style={{padding: "0"}}>
                        <img
                            className={"clickable"}
                            onClick={() => navigate("/dashboard")}
                            style={{marginLeft: "1.15rem"}}
                            width={100}
                            src={
                                colorState === "dark"
                                    ? brandSettingState?.companyLogoInverse
                                    : brandSettingState?.companyLogo
                            }
                        />
                    </Col>
                    <Col
                        className={
                            "text-center d-flex align-items-center justify-content-end px-2"
                        }
                        style={{padding: "0"}}
                    >
                        {userDetails?.clientAccountType === "demo" ? (
                            <>
                                <Button
                                    onClick={() => {
                                        setOpenGoLiveModal(true);
                                    }}
                                >
                                    {t("common.goLive")}
                                </Button>
                                <Divider type="vertical" className={"headerDivider"}/>
                            </>
                        ) : null}

                        <Button
                            className={"platform_download"}
                            onClick={() => {
                                setOpenPlatformModal(true);
                            }}
                        >
                            <DownloadOutlined
                                style={{margin: "0", verticalAlign: "2px", color: "#F7F7F7"}}
                            />
                            {t("module.platformDownload")}
                        </Button>
                        <Divider type="vertical" className={"headerDivider"}/>
                        <Dropdown
                            overlay={dropdownItems}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        >
                            <a onClick={(e) => e?.preventDefault()}>
                                <div
                                    className={"d-flex align-items-center justify-content-center"}
                                >
                                    <Avatar
                                        size={32}
                                        src={userDetails?.profileImage || dummyImg}
                                        style={{marginRight: "0.75rem"}}
                                    />
                                    <span className={"headerSpan"}>{userDetails?.username}</span>
                                </div>
                            </a>
                        </Dropdown>
                        <Divider type="vertical" className={"headerDivider"}/>
                        <span
                            className={"headerSpan clickable"}
                            onClick={() => {
                                navigate("/wallet-operations");
                            }}
                        >
              $ {formatMoney(walletState?.balance)}
            </span>
                        <Divider type="vertical" className={"headerDivider"}/>
                        <IconCommon
                            className={"sidebarIcon clickable"}
                            iconName={"qr"}
                            onClick={() => {
                                setOpenQrModal(true);
                            }}
                        />
                        <Divider type="vertical" className={"headerDivider"}/>
                        <Dropdown
                            overlay={languageDropdown}
                            id={"language-select"}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            arrow
                        >
                            <a className={"headerSpan"} onClick={(e) => e?.preventDefault()}>
                                {currentLanguageObj?.nativeName}
                                {/* <Avatar size={30} src={"https://cmsapi.cronoscap.com/media/1671519233640-480-us.svg"} style={{margin: "0", border: "none"}} /> */}
                            </a>
                        </Dropdown>
                        <Divider type="vertical" className={"headerDivider"}/>
                        <Switch
                            checked={colorState === "light" && true}
                            checkedChildren={
                                <IconCommon
                                    iconName={"sun"}
                                    className={"toggleIcon"}
                                    style={{filter: "brightness(.2) invert(1)"}}
                                />
                            }
                            unCheckedChildren={
                                <IconCommon iconName={"moon"} className={"toggleIcon"}/>
                            }
                            onChange={(e) => {
                                if (e) {
                                    setColorState("light");
                                    localStorage.setItem("color", "light");
                                } else {
                                    setColorState("dark");
                                    localStorage.setItem("color", "dark");
                                }
                            }}
                        />
                    </Col>
                </Row>
            )}
            <QrModal
                isModalOpen={openQrModal}
                handleCancel={() => setOpenQrModal(false)}
            />
            <PlatformDownloadModal
                isModalOpen={openPlatformModal}
                handleCancel={() => setOpenPlatformModal(false)}
                platformData={platformData}
            />
            <GoLiveModal
                isModalOpen={openGoLiveModal}
                handleCancel={() => setOpenGoLiveModal(false)}
                registerField={registerField}
            />
        </Header>
    );
};
