import {ApolloClient, ApolloLink, HttpLink, InMemoryCache, split,} from "@apollo/client";
import apolloLogger from "apollo-link-logger";
import getConfigByName from "../../../config";

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({headers = {}}) => ({
        headers: {
            ...headers,
            authorization: "Bearer " + localStorage.getItem("a_t") || "",
        },
    }));

    return forward(operation);
});

// const baseInstrumentLink = new HttpLink({ uri: getConfigByName().graphqlURL });
const marketBuzzLink = new HttpLink({uri: "https://marketbuzz.nextrades.ai/graphql"})
const baseLink = new HttpLink({uri: getConfigByName().graphqlURL});

const splitLink = split(
    (operation) => operation.variables.context?.endPoint === "marketBuzz",
    marketBuzzLink,
    baseLink
);

export const client = new ApolloClient({
    // uri: `${graphqlURL}`,
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    merge(existing = [], incoming) {
                        return [...existing, ...incoming];
                    },
                },
            },
        },
    }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "network-only",
        },
    },
    link: ApolloLink.from([authMiddleware, apolloLogger, splitLink]),
});

export async function ApolloClientQuery(query, variables, isMutation = false) {
    try {
        const response = await client[isMutation ? "mutate" : "query"]({
            [isMutation ? "mutation" : "query"]: query,
            variables,
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}
