import {Layout} from "antd";
import React from "react";
import {Outlet, useLocation} from "react-router-dom";
import {useRecoilState} from "recoil";
import "./css/style.css";
import {Header_4} from "./component/header";
import {Sider_4} from "./component/sider";
import {Breadcrumb_4} from "./component/breadcrumb";
import {Footer_4} from "./component/footer";
import {globalColorThemeState} from "../../_common/global_state";

const Layout_4 = ({kybList, poiList, porList, routes, registerField}) => {
    const location = useLocation();
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const themeClass = colorState === "dark" ? "dark-theme" : "light-theme";

    return (
        <Layout id={"layout-4"} className={themeClass}>
            {/* <Header_4 registerField={registerField}/> */}
            <Layout>
                <Sider_4 routes={routes} registerField={registerField} />
                <Layout id={"layout-body-4"} className={"layout_padding container-xxl"}>
                    {!(location.pathname === "/dashboard") && <Breadcrumb_4 kybList={kybList} poiList={poiList} porList={porList} />}
                    <Outlet />
                </Layout>
            </Layout>
            <Footer_4 />
        </Layout>
    );
};
export default Layout_4;
