import {Header} from "antd/es/layout/layout";
import {useIsMobile} from "../../../_common/function";
import {Button, Col, Dropdown, Image, Menu, Row, Space} from "antd";
import {MenuOutlined} from "@ant-design/icons";
import React from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {globalBrandSettingState, globalColorThemeState, globalLanguageListState, globalUserDetailState} from "../../../_common/global_state";
import useShared from "../../../_common/_shared";
import useLayoutFunction from "../../function";
import {useTranslation} from "react-i18next";
import {useVerification} from "../../../_common/_authorize";
import {useNavigate} from "react-router-dom";

const Header_5 = (props) => {
    const {
        registerField,
        onCollapsedChange,
        collapsed,
        setOpenQrModal,
    } = props;
    const {t} = useTranslation()
    const navigate = useNavigate();
    const isMobile = useIsMobile(768);

    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const userDetails = useRecoilValue(globalUserDetailState);
    const languageListState = useRecoilValue(globalLanguageListState);

    const {IconCommon, IconFlag} = useShared();
    const {changeLanguageHandler} = useLayoutFunction()
    const {handleTokenExpiration} = useVerification();

    const currentLanguageObj = languageListState?.find(
        (lang) => lang?.code === localStorage.getItem("language")
    );

    const languageDropdown = (
        <Menu
            className={"language-dropdown-menu"}
            selectedKeys={[localStorage.getItem("language")]}
        >
            {languageListState?.map((lang) => (
                <Menu.Item
                    key={lang?.code}
                    value={lang?.code}
                    onClick={() => changeLanguageHandler(lang?.code)}
                >
                    <Space><IconFlag countryCode={lang?.countryCode} style={{width: "24px"}}/> {lang?.nativeName}</Space>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Header id={"layout-header"}>
            <div className="layout-header">
                {isMobile ? (
                    <Row justify={"start"}>
                        <Col span={10}>
                            <Button
                                type="text"
                                className=""
                                icon={<MenuOutlined/>}
                                onClick={() => onCollapsedChange(!collapsed)}
                            />
                        </Col>
                        <Col span={12}>
                            <Image preview={false} className={"header-logo"}
                                   onClick={() => navigate("/dashboard")}
                                   src={colorState === "dark" ? brandSettingState?.companyLogoInverse : brandSettingState?.companyLogo}/>
                        </Col>
                    </Row>
                ) : (
                    <Row justify={"space-between"}>
                        <div>
                            <Image preview={false} className={"header-logo"}
                                   onClick={() => navigate("/dashboard")}
                                   src={colorState === "dark" ? brandSettingState?.companyLogoInverse : brandSettingState?.companyLogo}/>
                        </div>
                        <div className="layout-header-sub">
                            <Space>
                                <Dropdown
                                    overlay={languageDropdown}
                                    id={"language-select"}
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                    arrow
                                >
                                    <div
                                        className={"headerSpan"}
                                        onClick={(e) => e?.preventDefault()}
                                    >
                                        <IconFlag
                                            countryCode={currentLanguageObj?.countryCode}
                                            style={{width: "24px"}}
                                        />
                                    </div>
                                </Dropdown>
                                <div
                                    className={"clickable"}
                                    onClick={() => {
                                        if (colorState === "light") {
                                            setColorState("dark");
                                            localStorage.setItem("color", "dark");
                                        } else {
                                            setColorState("light");
                                            localStorage.setItem("color", "light");
                                        }
                                    }}
                                >
                                    {colorState !== "light" ? (
                                        <IconCommon
                                            iconName={"cm-lighttheme-regular"}
                                            className={"toggleIcon"}
                                            style={{width: "24px"}}
                                        />
                                    ) : (
                                        <IconCommon
                                            iconName={"cm-darktheme-regular"}
                                            className={"toggleIcon"}
                                            style={{
                                                width: "24px",
                                            }}
                                        />
                                    )}
                                </div>
                                <div>
                                    <IconCommon
                                        className={"clickable"}
                                        style={{width: "24px"}}
                                        iconName={"cm-qr-regular"}
                                        onClick={() => {
                                            setOpenQrModal(true);
                                        }}
                                    />
                                </div>
                                <div>
                                    <IconCommon
                                        className={"clickable"}
                                        style={{width: "24px"}}
                                        iconName={"cm-logout-regular"}
                                        onClick={() => handleTokenExpiration()}
                                    />
                                </div>
                            </Space>
                        </div>
                    </Row>
                )}
            < /div>
        </Header>
    )
}

export default Header_5