import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useRecoilValue, useSetRecoilState, useRecoilState} from "recoil";
import {Avatar, Button, Col, Divider, Drawer, Dropdown, Layout, Menu, Popover, Row, Tooltip, Upload} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {
    globalBrandConfigState,
    globalBrandModuleSettingState,
    globalBrandSettingState,
    globalColorThemeState,
    globalIconListState,
    globalLanguageListState,
    globalNotificationState, globalOpenGoLiveModalState,
    globalUserDetailState,
    globalWebModuleSettingState,
} from "../../../_common/global_state";
import useShared from "../../../_common/_shared";
import {useProfileFunction} from "../../../pages/profile/function";
import {useVerification} from "../../../_common/_authorize";
import {getPlatform} from "../../../api/graphql/platformDownload";
import QrModal from "./qr/qrModal";
import PlatformDownloadModal from "./platformDownload/platformDownloadModal";
import EditNavigationModal from "./editNavigationModal";
import dummyImg from "../../../assets/image/dummy.png";
import GoLiveModal from "./goLive";
import useLayoutFunction from "../../function";
import {useIsMobile} from "../../../_common/function";

export const Sider_4 = ({routes, registerField}) => {
    const {Sider} = Layout;
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const isMobile = useIsMobile(992)
    const {updateProfile} = useProfileFunction();
    const {IconModule, IconCommon, IconFlag} = useShared();
    const [openEditNavModal, setOpenEditNavModal] = useState(false);
    const [openGoLiveModal, setOpenGoLiveModal] = useRecoilState(globalOpenGoLiveModalState);
    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const brandConfigState = useRecoilValue(globalBrandConfigState);
    const brandModuleSetting = useRecoilValue(globalBrandModuleSettingState);
    const webModuleSetting = useRecoilValue(globalWebModuleSettingState);
    const userDetails = useRecoilValue(globalUserDetailState);
    const iconListState = useRecoilValue(globalIconListState);
    const languageListState = useRecoilValue(globalLanguageListState);

    const setNotificationMessage = useSetRecoilState(globalNotificationState);
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const themeClass = colorState === "dark" ? "dark-theme" : "light-theme";
    const [collapsed, setCollapsed] = useState(false);
    const {handleTokenExpiration} = useVerification();
    const [openQrModal, setOpenQrModal] = useState(false);
    const [openPlatformModal, setOpenPlatformModal] = useState(false);
    const [platformData, setPlatformData] = useState([]);
    const [openMorePopover, setIsOpenMorePopOver] = useState(false);
    const {changeLanguageHandler} = useLayoutFunction()

    const uploadImage = async (e) => {
        if (e && e?.file && e?.file.response && e?.file.response[0] && e?.file.response[0].link) {
            await updateProfile(userDetails?._id, {
                profileImage: e?.file.response[0].link.toString(),
            });
        }
    };

    const categories = [
        ...new Set(
            routes.flatMap((route) => {
                if (route?.sub && route?.sub.length > 0 && route?.key === "more") {
                    return route?.sub.map((subRoute) => subRoute?.categoryName);
                }
                return [];
            })
        ),
    ];

    const [openDrawers, setOpenDrawers] = useState([]);

    const openDrawer = (drawerKey) => {
        setOpenDrawers((prevOpenDrawers) => [...prevOpenDrawers, drawerKey]);
    };

    const closeDrawer = (drawerKey) => {
        setOpenDrawers((prevOpenDrawers) => prevOpenDrawers.filter((key) => key !== drawerKey));
    };

    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const toggleTheme = () => {
        let newColorState = colorState === "light" ? "dark" : "light";
        setColorState(newColorState);
        localStorage.setItem("color", newColorState);
    };

    const onClickMenu = (e) => {
        console.log("click ", e);
    };

    function filterAndGroupServers(arr) {
        const result = {};
        let appObject = null;

        for (const obj of arr) {
            const serverName = obj?.server?.name;
            const isEnabled =
                obj?.windowLinkObject.isEnabled ||
                obj?.macOSXLinkObject.isEnabled ||
                obj?.androidAPKLinkObject.isEnabled ||
                obj?.iOSDownloadLinkObject.isEnabled ||
                obj?.androidDownloadLinkObject.isEnabled ||
                obj?.huaweiDownloadLinkObject.isEnabled;

            if (obj.type === "app" && isEnabled) {
                appObject = obj;
            }

            if (serverName && isEnabled) {
                result[serverName] = obj;
            }
        }

        if (appObject) {
            result["App"] = appObject;
        }

        return result;
    }

    const preload = async () => {
        const response = await getPlatform();
        const filtered = await filterAndGroupServers(response?.getPlatform?.data);
        setPlatformData(filtered);
    };

    useEffect(() => {
        preload();
    }, []);

    // let amendedRoutes = routes?.map((route) => {
    //     let children;
    //     if (route?.sub && route?.sub?.length > 0) {
    //         children = route?.sub?.map((sub) => ({
    //             ...sub,
    //             label: <a href={`/#${sub?.path}`}>{sub?.label}</a>,
    //         }));
    //     }
    //     return {...route, label: children ? route?.label : <a href={`/#${route?.path}`}>{route?.label}</a>, children};
    // });

    const languageDropdown = (
        <Menu className={"language-dropdown-menu"} selectedKeys={[localStorage.getItem("language")]}>
            {languageListState?.map((lang) => (
                <Menu.Item key={lang?.code} value={lang?.code} onClick={() => changeLanguageHandler(lang?.code)}>
                    <IconFlag countryCode={lang?.countryCode} className="icon-button me-2" />
                    <small>{lang?.nativeName}</small>
                </Menu.Item>
            ))}
        </Menu>
    );

    const currentLanguageObj = languageListState?.find((lang) => lang?.code === localStorage.getItem("language"));

    return (
        <span id="sidebar" className={`${themeClass} ${collapsed ? "collapsed" : "expanded"}`}>
            <QrModal isModalOpen={openQrModal} handleCancel={() => setOpenQrModal(false)} />
            <PlatformDownloadModal isModalOpen={openPlatformModal} handleCancel={() => setOpenPlatformModal(false)} platformData={platformData} />
            <EditNavigationModal isModalOpen={openEditNavModal} handleCancel={() => setOpenEditNavModal(false)} />
            <GoLiveModal isModalOpen={openGoLiveModal} handleCancel={() => setOpenGoLiveModal(false)} registerField={registerField} />
            <Sider
                breakpoint="lg"
                width={220}
                collapsedWidth="80"
                className="vh-100"
                onCollapse={(value, collapsed, type) => {
                    setCollapsed(value);
                }}
                collapsible
                collapsed={collapsed}
            >
                <div className="sidebar-logo" style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px"}}>
                    {!collapsed && ( // Conditionally render the logo when not collapsed
                        <img
                            className={"w-100 h-100 clickable"}
                            onClick={() => navigate("/dashboard")}
                            src={colorState === "dark" ? brandSettingState?.companyLogoInverse : brandSettingState?.companyLogo}
                        />
                    )}
                </div>

                {collapsed ? (
                    <div style={{maxHeight: "40px", marginTop: "3rem"}}>
                        <Upload
                            id={"upload-layout"}
                            showUploadList={false}
                            beforeUpload={(file) => {
                                const isImage = file.type?.includes("image");
                                if (!isImage) {
                                    setNotificationMessage({
                                        status: "error",
                                        title: "",
                                        msg: t("errorMsg.type.invalidFileType"),
                                        key: "invalidFileType",
                                    });
                                }
                                return isImage || Upload.LIST_IGNORE;
                            }}
                            accept="image/*"
                            maxCount={1}
                            onChange={uploadImage}
                            action={`${brandConfigState?.backendURL}api/upload/profile/image`}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className={`upload-container ${hovered ? "hovered" : ""}`}
                            // disabled={userDetails?.profileImage}
                        >
                            <Avatar size={40} src={userDetails?.profileImage || dummyImg} />
                            <div className="plus-overlay-collapsed">
                                <PlusOutlined />
                            </div>
                        </Upload>
                    </div>
                ) : ((
                    // !loading && (
                    <div className="sidebar-profile-container container-fluid">
                        <Row align={"middle"} gutter={16} className="container-fluid mx-0">
                            <Col span={6}>
                                <Upload
                                    id={"upload-layout"}
                                    showUploadList={false}
                                    beforeUpload={(file) => {
                                        const isImage = file.type?.includes("image");
                                        if (!isImage) {
                                            setNotificationMessage({
                                                status: "error",
                                                title: "",
                                                msg: t("errorMsg.type.invalidFileType"),
                                                key: "invalidFileType",
                                            });
                                        }
                                        return isImage || Upload.LIST_IGNORE;
                                    }}
                                    accept="image/*"
                                    maxCount={1}
                                    onChange={uploadImage}
                                    action={`${brandConfigState?.backendURL}api/upload/profile/image`}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    className={`upload-container ${hovered ? "hovered" : ""}`}
                                >
                                    <Avatar src={userDetails?.profileImage || dummyImg} className="sidebar-profile-img"/>
                                    <div className="plus-overlay">
                                        <PlusOutlined/>
                                    </div>
                                </Upload>
                            </Col>
                            <Col span={18}>
                                <Row className="sidebar-profile-name">
                                    <p>{userDetails?.username}</p>
                                </Row>
                                <Row className="sidebar-profile-email">
                                    <Tooltip title={userDetails?.email}>
                                        <p>{userDetails?.email}</p>
                                    </Tooltip>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                ))}

                {userDetails?.clientAccountType === "demo" ? (
                    <div className="mx-3">
                        <Button
                            className={"platform_download primary-button"}
                            onClick={() => {
                                setOpenGoLiveModal(true);
                            }}
                        >
                            {t("common.goLive")}
                        </Button>
                    </div>
                ) : null}

                {/* <Menu className="sidebar-menu" theme={colorState} onClick={onClickMenu} mode="inline" items={amendedRoutes} /> */}

                <div className="sidebarMenu">
                    {routes.map((route, index) => {
                        if (route?.sub && route?.sub?.length > 0) {
                            const moreContent = (
                                <>
                                    {categories.map((category, index) => (
                                        <Row id={"layout-sider-popover"} key={category}>
                                            {/* {index === 0 ? (
                                                <Divider className="sidebarDivider" style={{display: "none"}} />
                                            ) : (
                                                <Divider className="sidebarDivider" />
                                            )} */}
                                            <Col span={24} className={"mt-2"}>
                                                <p className={"sub-title"}>
                                                    <strong>{t(`common.${category}`)}</strong>
                                                </p>
                                            </Col>
                                            {route?.sub
                                                .sort((a, b) => {
                                                    const categoryOrder = {
                                                        common: 1,
                                                        finance: 2,
                                                        others: 3,
                                                    };

                                                    const categoryComparison = categoryOrder[a.categoryName] - categoryOrder[b.categoryName];
                                                    if (categoryComparison !== 0) {
                                                        return categoryComparison;
                                                    }

                                                    return categoryOrder[a.key] - categoryOrder[b.key];
                                                })
                                                .filter((subRoute) => subRoute?.categoryName === category)
                                                .map((subRoute, subRouteIndex) => {
                                                    const filterModule = brandModuleSetting?.filter(
                                                        (item) => item?.isEnabled === true && subRoute?.label === item?.name
                                                    );
                                                    const drawerKey = `${subRoute?.label}`;
                                                    const isDrawerOpen = openDrawers.includes(drawerKey);
                                                    if (filterModule && subRoute?.sub) {
                                                        return (
                                                            <div key={subRouteIndex}>
                                                                <Tooltip
                                                                    title={subRoute?.label}
                                                                    placement="right"
                                                                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                                                >
                                                                    <Col
                                                                        xs={24}
                                                                        md={24}
                                                                        lg={24}
                                                                        className={"layout-sider-popover-btn text-center mb-2 clickable"}
                                                                        onClick={() => {
                                                                            openDrawer(drawerKey);
                                                                        }}
                                                                    >
                                                                        {subRoute?.icon}
                                                                    </Col>
                                                                </Tooltip>
                                                                <Drawer
                                                                    key={drawerKey}
                                                                    id="drawerContainer"
                                                                    onClose={() => closeDrawer(drawerKey)}
                                                                    open={isDrawerOpen}
                                                                    className={"popoverDrawer"}
                                                                    getContainer={false}
                                                                    title={
                                                                        <Row justify={"start"}>
                                                                            <Button type={"link"} onClick={() => closeDrawer(drawerKey)}>
                                                                                <p>X</p>
                                                                            </Button>
                                                                        </Row>
                                                                    }
                                                                    closeIcon={false}
                                                                >
                                                                    <Row className={"text-center"}>
                                                                        {subRoute?.sub?.map((subSubRoute) => {
                                                                            return (
                                                                                <Col
                                                                                    className={"mb-3 clickable"}
                                                                                    style={{cursor: "pointer"}}
                                                                                    key={subSubRoute?.label}
                                                                                    xs={24}
                                                                                    md={24}
                                                                                    lg={24}
                                                                                    onClick={() => {
                                                                                        closeDrawer(drawerKey)
                                                                                        setIsOpenMorePopOver(false)
                                                                                        isMobile && setCollapsed(true)

                                                                                        subSubRoute?.type === "_blank"
                                                                                            ? window.open(subSubRoute?.path, "_blank")
                                                                                            : navigate(subSubRoute?.path);
                                                                                    }}
                                                                                >
                                                                                    <Tooltip title={subSubRoute?.label}>
                                                                                        <IconModule
                                                                                            iconName={subSubRoute?.label}
                                                                                            className={"sidebarIcon"}
                                                                                            title={subSubRoute?.label
                                                                                                .replace(".", "")
                                                                                                .replace(/[-\s+]/g, "_")
                                                                                                .toLowerCase()}
                                                                                        />
                                                                                    </Tooltip>
                                                                                </Col>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                </Drawer>
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <Tooltip
                                                                key={subRouteIndex}
                                                                title={subRoute?.label}
                                                                placement="right"
                                                                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                                            >
                                                                <Col
                                                                    key={subRoute?.label}
                                                                    xs={24}
                                                                    md={24}
                                                                    lg={24}
                                                                    className={"layout-sider-popover-btn text-center mb-2 clickable"}
                                                                    onClick={() => {
                                                                        setIsOpenMorePopOver(false);

                                                                        isMobile && setCollapsed(true)

                                                                        subRoute?.type === "_blank"
                                                                            ? window.open(subRoute?.path, "_blank")
                                                                            : navigate(subRoute?.path);
                                                                    }}
                                                                >
                                                                    {subRoute?.icon}
                                                                </Col>
                                                            </Tooltip>
                                                        );
                                                    }
                                                })}
                                        </Row>
                                    ))}

                                    {route?.key === "more" && (
                                        <Col span={24} className={"text-center editNavigation"}>
                                            <Divider style={{margin: "0.2rem 0", background: "var(--divider-color)"}} />
                                            <Button
                                                type={"link"}
                                                className={""}
                                                onClick={() => {
                                                    setOpenEditNavModal(true);
                                                    setIsOpenMorePopOver(false);
                                                }}
                                            >
                                                {collapsed ? t("common.edit") : t("common.editNavigation")}
                                            </Button>
                                        </Col>
                                    )}
                                </>
                            );

                            const content = (
                                <Row id={"layout-sider-popover"}>
                                    {route?.sub?.map((subRoute, subRouteIndex) => {
                                        return (
                                            <Tooltip
                                                key={subRouteIndex}
                                                title={subRoute?.label}
                                                placement="right"
                                                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                            >
                                                <Col
                                                    key={subRoute?.label}
                                                    xs={12}
                                                    md={8}
                                                    lg={6}
                                                    className={`layout-sider-popover-btn text-center clickable ${
                                                        route?.sub?.length > 4 ? "mt-1 mb-2" : "mb-0"
                                                    }`}
                                                    onClick={() => {
                                                        isMobile && setCollapsed(true)

                                                        subRoute?.type === "_blank"
                                                            ? window.open(subRoute?.path, "_blank")
                                                            : navigate(subRoute?.path);
                                                    }}
                                                >
                                                    {subRoute?.icon}
                                                </Col>
                                            </Tooltip>
                                        );
                                    })}
                                </Row>
                            );

                            return (
                                <div key={index}>
                                    {route?.key === "more" ? (
                                        <Popover
                                            trigger={"click"}
                                            open={openMorePopover}
                                            destroyTooltipOnHide={true}
                                            className={"setPopoverCustom"}
                                            content={moreContent}
                                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                            placement={"right"}
                                            onOpenChange={(bool) => setIsOpenMorePopOver(bool)}
                                        >
                                            <Col onClick={() => setIsOpenMorePopOver(!openMorePopover)} span={24} className={"text-center mb-2"} style={{cursor: "pointer"}}>
                                                {route?.icon}
                                            </Col>
                                        </Popover>
                                    ) : (
                                        <Popover
                                            trigger={"click"}
                                            destroyTooltipOnHide={true}
                                            content={content}
                                            placement={"right"}
                                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                        >
                                            <Col span={24} className={"text-center mb-2"} style={{cursor: "pointer"}}>
                                                {route?.icon}
                                            </Col>
                                        </Popover>
                                    )}
                                </div>
                            );
                        } else {
                            return (
                                <Tooltip
                                    key={index}
                                    title={route?.label}
                                    placement="right"
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                >
                                    <Col
                                        span={24}
                                        className={"text-center"}
                                        key={route?.label}
                                        onClick={() => {
                                            isMobile && setCollapsed(true)

                                            route?.type === "_blank" ? window.open(route?.path, "_blank") : navigate(route?.path);
                                        }}
                                        style={{cursor: "pointer", flex: "none"}}
                                    >
                                        {route?.icon}
                                    </Col>
                                </Tooltip>
                            );
                        }
                    })}
                </div>

                <div className="function-bar">
                    <ul>
                        <li>
                            <Dropdown getPopupContainer={(triggerNode) => triggerNode.parentElement} overlay={languageDropdown}>
                                <a onClick={(e) => e?.preventDefault()}>
                                    {/* <IconCommon iconName={localStorage.getItem("language")} className="icon-button" /> */}
                                    <IconFlag countryCode={currentLanguageObj?.countryCode} className="icon-button" />
                                </a>
                            </Dropdown>
                        </li>
                        <li>
                            <Tooltip title={t("referral.modal.title")}>
                                <button
                                    className="icon-button"
                                    onClick={() => {
                                        setOpenQrModal(true);
                                    }}
                                >
                                    <IconCommon iconName={"qr"} className="icon-button" />
                                </button>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={t("brandSetting.theme")}>
                                <button onClick={toggleTheme} className="icon-button">
                                    <IconCommon isAlwaysActive={true} iconName={colorState === "light" ? "sun" : "moon"} className="h-100 w-100" />
                                </button>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={t("common.logOut")}>
                                <button
                                    className="icon-button"
                                    onClick={() => {
                                        handleTokenExpiration();
                                    }}
                                >
                                    <IconCommon iconName={"logout"} className="icon-button" />
                                </button>
                            </Tooltip>
                        </li>
                    </ul>
                    <button
                        className="primary-button"
                        onClick={() => {
                            setOpenPlatformModal(true);
                        }}
                    >
                        <IconCommon iconName={"import"} className="icon-button align-self-center" />
                        {!collapsed && <span>{t("common.download")}</span>}
                    </button>
                </div>
            </Sider>
        </span>
    );
};
