import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {globalBrandSettingState, globalUserWalletState} from "../../../../shared/_common/global_state";
import {Image, Row} from "antd";
import {formatDate, formatMoney} from "../../../../shared/_common/function";
// import moment from "moment";
import React from "react";
import "./css/style.css"

const MinosEWalletCard = () => {
    const {t} = useTranslation()
    const userWallet = useRecoilValue(globalUserWalletState)
    const brandSettingState = useRecoilValue(globalBrandSettingState);

    return (
        <div className={"ewallet-card"}>
            <Row justify={"space-between"}>
                <div>
                    <p><strong className={"secondary-text"}>{t("ewallet.balance")}</strong></p>
                    <p className={"number"}>$ {formatMoney(userWallet?.balance)}</p>
                </div>
                <Image preview={false} src={brandSettingState?.siteFavicon} width={32}/>
            </Row>
            <div className={"mt-4"}>
                <p><strong className={"secondary-text"}>{t("common.registrationDate")}</strong></p>
                {/*<h3><strong>{moment(userWallet?.createdAt).format("YYYY-MM-DD")}</strong></h3>*/}
                <h3><strong>{formatDate(userWallet?.createdAt, true, "YYYY-MM-DD")}</strong></h3>
            </div>
        </div>
    )
}

export default MinosEWalletCard