import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'reactflow/dist/style.css';
import App from "./App";
import { HashRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./shared/i18n/index";
import { ApolloProvider } from "@apollo/client";
import { client } from "./shared/api/graphql/_setup";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <RecoilRoot>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </RecoilRoot>
  </Router>
);
