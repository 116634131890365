import Sider from "antd/es/layout/Sider";
import {Button, Card, Col, Collapse, Divider, Dropdown, Menu, Row, Space, Upload} from "antd";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import React, {Fragment, useEffect, useState} from "react";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    globalBrandConfigState,
    globalBrandSettingState,
    globalColorThemeState,
    globalCurrentPathState,
    globalLanguageListState, globalNotificationState, globalOpenGoLiveModalState,
    globalUserDetailState
} from "../../../_common/global_state";
import {useNavigate} from "react-router-dom";
import useShared from "../../../_common/_shared";
import {useTranslation} from "react-i18next";
import useLayoutFunction from "../../function";
import {useVerification} from "../../../_common/_authorize";
import {getPlatform} from "../../../api/graphql/platformDownload";
import FooterTime from "./latestTime/footerTime";
import Meta from "antd/es/card/Meta";
import Avatar from "antd/es/avatar/avatar";
import dummyImg from "../../../assets/image/dummy.png";
import {useIsMobile} from "../../../_common/function";
import EWalletCard from "../../../../brand/component/eWalletCard";
import GoLiveModal from "./goLive";
import PlatformDownloadModal from "./platformDownload";
import {useProfileFunction} from "../../../pages/profile/function";

const Sider_5 = ({routes, collapsed, onCollapsedChange, setOpenQrModal, registerField, setCollapsed}) => {
    const isMobile = useIsMobile(768);
    const [nav, setNav] = useState([]);
    const setNotificationMessage = useSetRecoilState(globalNotificationState);
    const userDetails = useRecoilValue(globalUserDetailState);
    const languageListState = useRecoilValue(globalLanguageListState);
    const globalPathState = useRecoilValue(globalCurrentPathState);
    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const brandConfigState = useRecoilValue(globalBrandConfigState)
    const [openGoLiveModal, setOpenGoLiveModal] = useRecoilState(globalOpenGoLiveModalState);
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const [platformData, setPlatformData] = useState([]);
    const [openDownloadModal, setOpenDownloadModal] = useState(false);

    const {t} = useTranslation()
    const navigate = useNavigate();
    const {updateProfile} = useProfileFunction()
    const {IconCommon, IconFlag} = useShared();
    const {changeLanguageHandler} = useLayoutFunction()
    const {handleTokenExpiration} = useVerification();

    const currentLanguageObj = languageListState?.find(
        (lang) => lang?.code === localStorage.getItem("language")
    );

    const uploadImage = async (e) => {
        if (e && e?.file && e?.file.response && e?.file.response[0] && e?.file.response[0].link) {
            await updateProfile(userDetails?._id, {
                profileImage: e?.file.response[0].link.toString(),
            })
        }
    }

    function filterAndGroupServers(arr) {
        const result = {};
        let appObject = null;

        for (const obj of arr) {
            const serverName = obj?.server?.name;
            const isEnabled =
                obj?.windowLinkObject.isEnabled ||
                obj?.macOSXLinkObject.isEnabled ||
                obj?.androidAPKLinkObject.isEnabled ||
                obj?.iOSDownloadLinkObject.isEnabled ||
                obj?.androidDownloadLinkObject.isEnabled ||
                obj?.huaweiDownloadLinkObject.isEnabled;

            if (obj.type === "app" && isEnabled) {
                appObject = obj;
            }

            if (serverName && isEnabled) {
                result[serverName] = obj;
            }
        }

        if (appObject) {
            result["App"] = appObject;
        }

        return result;
    }

    const preload = async () => {
        const response = await getPlatform();
        const filtered = await filterAndGroupServers(response?.getPlatform?.data);
        setPlatformData(filtered);
    };

    const languageDropdown = (
        <Menu
            className={"language-dropdown-menu"}
            selectedKeys={[localStorage.getItem("language")]}
        >
            {languageListState?.map((lang) => (
                <Menu.Item
                    key={lang?.code}
                    value={lang?.code}
                    onClick={() => changeLanguageHandler(lang?.code)}
                >
                    <Space><IconFlag countryCode={lang?.countryCode} style={{width: "24px"}}/> {lang?.nativeName}</Space>
                </Menu.Item>
            ))}
        </Menu>
    );

    useEffect(() => {
        preload();
        processRoute();
    }, [routes]);

    const processRoute = async () => {
        let fav = [],
            other;

        await routes?.map((route) => {
            if (route.key !== "more") {
                fav.push(route);
            } else {
                other = route.sub;
            }
        });

        setNav([
            {
                groupName: "pinned",
                label: t("common.pinnedPages"),
                items: fav,
            },
            {
                groupName: "pages",
                label: t("common.pages"),
                items: other,
            },
        ]);
    };

    return (
        <Sider
            width={isMobile ? "100%" : 240}
            id={"layout-sider"}
            breakpoint={"lg"}
            collapsedWidth="0"
            collapsible
            collapsed={collapsed}
        >
            {
                isMobile &&
                <div className="nav-close-button text-end p-3 pb-0">
                    <Button
                        className=""
                        type="text"
                        icon={<CloseOutlined/>}
                        onClick={() => onCollapsedChange(!collapsed)}
                    />
                </div>
            }
            <div className={"user-detail-container mt-4"}>
                <Card>
                    <Meta
                        avatar={
                            <Upload
                                showUploadList={false}
                                beforeUpload={(file) => {
                                    const isImage = file.type?.includes('image')
                                    if (!isImage) {
                                        setNotificationMessage({
                                            status: "error",
                                            title: "",
                                            msg: t("errorMsg.type.invalidFileType"),
                                            key: "invalidFileType",
                                        });
                                    }
                                    return isImage || Upload.LIST_IGNORE;
                                }}
                                accept="image/*" maxCount={1}
                                onChange={uploadImage}
                                action={`${brandConfigState?.backendURL}api/upload/profile/image`}
                                className={"upload-profile-image"}
                            >
                                <Avatar className={"profile-image"} src={userDetails?.profileImage || dummyImg}/>
                                <div className="plus-overlay">
                                    <PlusOutlined/>
                                </div>
                            </Upload>
                        }
                        title={userDetails?.username}
                        description={userDetails?.email}
                    />
                </Card>
            </div>
            {
                isMobile &&
                <div className={"p-4 py-0 mt-3"}>
                    <EWalletCard/>
                </div>
            }
            <div className="nav-container py-3">
                {nav?.length > 0 &&
                nav?.map((d, i) => (
                    <Fragment key={d.groupName}>
                        <div className="nav-group-title d-flex my-2">
                            <p><small className={"secondary-text title"}>{d.label}</small></p>
                            {d?.groupName === "pinned" && (
                                <a
                                    className={"tertiary_btn ms-auto"}
                                    onClick={() => {
                                        navigate("/profile?edit=true");
                                        if (isMobile) setCollapsed(true)
                                    }}
                                >
                                    <IconCommon className={"small-icon"} iconName={"cm-edit-regular"}/>
                                    {/*<EditOutlined className={"secondary-text"}/>*/}
                                </a>
                            )}
                        </div>
                        <div
                            className={`nav-item-container ${i === 1 ? "scrollable" : ""}`}
                        >
                            {d?.items?.map((item, j) =>
                                item?.sub ? (
                                    <Collapse key={j} ghost expandIconPosition={"end"}>
                                        <CollapsePanel
                                            // showArrow={false}
                                            key={j}
                                            header={
                                                <Fragment key={j}>
                                                    <a
                                                        onClick={() => {
                                                            if (item?.path) {
                                                                item?.type === "_blank" ? window.open(item?.path, "_blank") : navigate(item?.path)
                                                                if (isMobile) setCollapsed(true)
                                                            }
                                                        }}
                                                        className={`${globalPathState === item?.path ? "active" : ""} nav-item d-flex`}
                                                    >
                                                        <div
                                                            className="nav-item-icon me-2"
                                                            style={{marginRight: "4px"}}
                                                        >
                                                            {item?.icon}
                                                        </div>
                                                        <div
                                                            className={"ms-2"}>{t(`module.${item.label.replace(".", "").replace(/[-\s+]/g, "_").toLowerCase()}`)}</div>
                                                    </a>
                                                    {item.children ? (
                                                        <div
                                                            className={`nested-nav-item-container ${
                                                                item.isExpanded ? "open" : ""
                                                            }`}
                                                        >
                                                            <div>
                                                                {item.children?.map((c) => (
                                                                    <a key={c.label} className="nav-item d-flex">
                                                                        <div className="nav-item-icon">
                                                                            {c.icon}
                                                                        </div>
                                                                        <div
                                                                            className={"ms-2"}>{t(`module.${c.label.replace(".", "").replace(/[-\s+]/g, "_").toLowerCase()}`)}</div>
                                                                    </a>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </Fragment>
                                            }
                                        >
                                            {item?.sub?.map((d, i) => (
                                                <Fragment key={i}>
                                                    <a
                                                        onClick={() => {
                                                            if (d?.path) {
                                                                d?.type === "_blank" ? window.open(d?.path, "_blank") : navigate(d?.path);
                                                                if (isMobile) setCollapsed(true)
                                                            }
                                                        }}
                                                        className={`${
                                                            globalPathState === d?.path ? "active" : ""
                                                        } nav-item d-flex `}
                                                    >
                                                        <div className="nav-item-icon me-2"/>
                                                        <div
                                                            className={"ms-2"}>{t(`module.${d.label.replace(".", "").replace(/[-\s+]/g, "_").toLowerCase()}`)}</div>
                                                    </a>
                                                </Fragment>
                                            ))}
                                        </CollapsePanel>
                                    </Collapse>
                                ) : (
                                    <Fragment key={j}>
                                        <a
                                            onClick={() => {
                                                if (item?.path) {
                                                    item?.type === "_blank" ? window.open(item?.path, "_blank") : navigate(item?.path)
                                                    if (isMobile) setCollapsed(true)
                                                }
                                            }}
                                            className={`${
                                                globalPathState === item?.path ? "active" : ""
                                            } nav-item d-flex`}
                                        >
                                            <div
                                                className="nav-item-icon me-2"
                                                style={{marginRight: "4px"}}
                                            >
                                                {item?.icon}
                                            </div>
                                            <div className={"ms-2"}>{t(`module.${item.label.replace(".", "").replace(/[-\s+]/g, "_").toLowerCase()}`)}</div>
                                        </a>
                                    </Fragment>
                                )
                            )}
                        </div>
                    </Fragment>
                ))}
                <div className={"d-block d-md-none"}>
                    <Divider/>
                    <Row>
                        <Dropdown
                            overlay={languageDropdown}
                            id={"language-select"}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            arrow
                            placement={"top"}
                        >
                            <Col
                                span={6}
                                className={"text-center"}
                                onClick={(e) => e?.preventDefault()}
                            >
                                <IconFlag
                                    countryCode={currentLanguageObj?.countryCode}
                                    style={{width: "24px"}}
                                />
                            </Col>
                        </Dropdown>
                        <Col
                            span={6}
                            className={"text-center"}
                            onClick={() => {
                                if (colorState === "light") {
                                    setColorState("dark");
                                    localStorage.setItem("color", "dark");
                                } else {
                                    setColorState("light");
                                    localStorage.setItem("color", "light");
                                }
                            }}
                        >
                            {colorState !== "light" ? (
                                <IconCommon
                                    iconName={"cm-darktheme-regular"}
                                    className={"toggleIcon"}
                                    style={{width: "24px"}}
                                />
                            ) : (
                                <IconCommon
                                    iconName={"cm-lighttheme-regular"}
                                    className={"toggleIcon"}
                                    style={{width: "24px"}}
                                />
                            )}
                        </Col>
                        <Col span={6} className={"text-center"}>
                            <IconCommon
                                style={{width: "24px"}}
                                iconName={"cm-qr-regular"}
                                onClick={() => {
                                    setOpenQrModal(true);
                                }}
                            />
                        </Col>
                        <Col span={6} className={"text-center"}>
                            <IconCommon
                                style={{width: "24px"}}
                                iconName={"cm-logout-regular"}
                                onClick={() => handleTokenExpiration()}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={"mt-3 bottom-content-container"}>
                {userDetails?.clientAccountType === "demo" &&
                <Button block type={"primary"}
                        className={"my-1"}
                        onClick={() => {
                            setOpenGoLiveModal(true)
                        }}
                >
                    {t("common.goLive")}
                </Button>
                }

                <Button
                    block
                    className={"my-1"}
                    onClick={() => setOpenDownloadModal(true)}
                >
                    {t("common.download")}
                </Button>
                <div className={"text-center my-3"}>
                    <FooterTime/>
                </div>
            </div>
            <GoLiveModal isModalOpen={openGoLiveModal} handleCancel={() => setOpenGoLiveModal(false)} registerField={registerField}/>
            <PlatformDownloadModal isModalOpen={openDownloadModal} handleCancel={() => setOpenDownloadModal(false)} platformData={platformData}/>
        </Sider>
    )
};

export default Sider_5