import {Layout} from 'antd';
import React from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import "./css/style.css"
import {Header_2} from "./component/header";
import {Sider_2} from "./component/sider";
import {Breadcrumb_2} from "./component/breadcrumb";
import {Footer_2} from "./component/footer";

const Layout_2 = ({kybList,poiList,porList,routes,registerField}) => {
    const location = useLocation();


    return (
        <Layout id={"layout-2"}>
            <Header_2 registerField={registerField}/>
            <Layout>
                <Sider_2 routes={routes}/>
                <Layout id={"layout-body-2"} className={"layout_padding container-xxl"}>
                    {!(location.pathname === '/dashboard') && (
                        <Breadcrumb_2 kybList={kybList} poiList={poiList} porList={porList} />
                    )}
                    <Outlet/>
                </Layout>
            </Layout>
            <Footer_2/>
        </Layout>
    );
};
export default Layout_2;