import axios from "axios";

export async function getMarketNews(page, row = 24) {
  const lang = localStorage.getItem("language");
  let newsLanguage;
  let timeLanguage;
  switch (lang) {
    case "cn":
    case "zh":
      newsLanguage = "zh";
      timeLanguage = "zh-cn";
      break;

    default:
      newsLanguage = "en";
      timeLanguage = "en";
      break;
  }

  return await axios({
    url: `https://market-news.nextrades.ai/api/v1/marketNews/listnews?language=${newsLanguage}&rows=${row}&page=${
      page || 1
    }`,
    method: "get",
  })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}
