import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { globalBrandSettingState } from "../../../../_common/global_state";
// import moment from "moment";
// import "moment-timezone";
import {formatDateTime} from "../../../../_common/function";

export const FooterTime = () => {
  const brandSettingState = useRecoilValue(globalBrandSettingState);
  const [adjustedTimes, setAdjustedTimes] = useState("");

  const getUtcTime = async () => {
    try {
      // const existingTime = await moment()?.utcOffset(-brandSettingState?.timezone);
      // const formattedTime = existingTime?.format("h:mm:ss a");
      // setAdjustedTimes(formattedTime);
      setAdjustedTimes(await formatDateTime(undefined, "h:mm:ss a"));
    } catch (error) {
      console.error("Error getting UTC time:", error);
    }
  };

  const timezoneFilter = () => {
    if (brandSettingState?.timezone === "0") {
      return "";
    } else if (brandSettingState?.timezone % 1) {
      const hours = Math.floor(brandSettingState?.timezone);

      const minutes = (brandSettingState?.timezone - hours) * 60;

      const formattedTimezone = `${
        +brandSettingState?.timezone > 0
          ? "-" + brandSettingState?.timezone?.replace(/[+-.]/g, "")
          : "+" + brandSettingState?.timezone?.replace(/[+-.]/g, "")
      }:${minutes?.toString()?.padStart(2, "0")}`;

      return formattedTimezone;
    } else {
      return +brandSettingState?.timezone > 0
        ? "-" + brandSettingState?.timezone?.replace(/[+-.]/g, "")
        : "+" + brandSettingState?.timezone?.replace(/[+-.]/g, "");
    }
  };

  useEffect(() => {
    setInterval(getUtcTime, 1000);
  }, [getUtcTime]);

  return (
    <span
      className={"footerText"}
    >{`GMT${timezoneFilter()} ${adjustedTimes}`}</span>
  );
};

export default FooterTime;
