import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Upload,
} from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { globalUserDetailState } from "../../../../_common/global_state";
import { PlusOutlined } from "@ant-design/icons";
// import moment from "moment";
import useLayoutFunction from "../../../function";
// import dayjs from "dayjs";
import {formatDate} from "../../../../_common/function";

const GoLiveModal = (props) => {
  const { isModalOpen, handleCancel, registerField } = props;
  const [form] = Form.useForm();

  const [btnLoading, setBtnLoading] = useState(false);
  const [countryCode, setCountryCode] = useState(null);

  const userDetails = useRecoilValue(globalUserDetailState);

  const { goLiveFunction } = useLayoutFunction();

  const sortedField = [...registerField]
    ?.filter(
      (v) =>
        v?.fieldList !== null &&
        v.fieldList.fieldName !== "checkTermAndCondition" &&
        v.fieldList.fieldName !== "affiliateCode" &&
        v.fieldList.category !== "loginInformation"
    )
    .sort((a, b) => a.position - b.position);

  const onFinish = async (values) => {
    await setBtnLoading(true);

    if (countryCode) {
      values.countryCode = countryCode;
    }

    Object.keys(values).map((value) => {
      const getField = sortedField.find(
        (item) => item?.fieldList?.fieldName === value
      );
      if (getField?.fieldList?.fieldType === "file") {
        values[value] = values[value].file.response[0].link.toString();
      } else if (getField?.fieldList?.fieldType === "date") {
        // values[value] = moment(values[value]).toISOString()
        values[value] = formatDate(values[value]).toISOString()
      }
    });

    values = {
      ...values,
      clientAccountType: "live",
    };
    const response = await goLiveFunction(values);
    setBtnLoading(false);
    handleCancel();
  };

  const { t } = useTranslation();

  return (
    <Modal
      title={"Go Live"}
      className={"goLiveModal"}
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
      getContainer={() => document.getElementById("layout-2")}
      destroyOnClose
    >
      <div>
        <Form
          className={"goLiveForm"}
          name="basic"
          form={form}
          layout={"vertical"}
          labelCol={{
            span: 24,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={[16, 16]}>
            {sortedField?.map((register) => {
              let component;
              let defaultValue

              if (register?.fieldList?.fieldType === "date") {
                // defaultValue = null
                defaultValue = !userDetails?.[register?.fieldList?.fieldName]
                    ? null
                    // : dayjs(dayjs(userDetails?.[register?.fieldList?.fieldName])?.utc()?.format("YYYY-MM-DD") + "T12:00:00+00:00");
                    : formatDate(userDetails?.[register?.fieldList?.fieldName])
              } else {
                defaultValue = register?.fieldList?.fieldName !== "affiliateCode" ? userDetails?.[register?.fieldList?.fieldName] : ""
              }

              switch (register?.fieldList?.fieldType) {
                case "mobile":
                  // const prefix = (
                  //     <Select className={'mobileSelector'}
                  //             style={{
                  //                 width: 90,
                  //             }}
                  //             showSearch
                  //             defaultValue={userDetails?.countryCode}
                  //             onChange={(e) => setCountryCode(e)}
                  //             popupMatchSelectWidth={false}
                  //             optionFilterProp={"label"}
                  //             optionLabelProp={"value"}
                  //     >
                  //         {register?.fieldList?.optionValue.map((value) => {
                  //
                  //             return (
                  //                 <Select.Option key={value?.label} value={value?.value}>
                  //                     {t(value?.label)}
                  //                 </Select.Option>
                  //             );
                  //         })}
                  //     </Select>
                  // )

                  component = (
                      <Form.Item
                          label={t(register?.fieldList?.label)}
                          className={"mobileInput"}
                          name="mobileNo"
                          style={{
                            width: "100%",
                            borderLeft: "1px solid var(--form-card)",

                          }}
                          initialValue={userDetails?.mobileNo}
                          rules={[
                            {
                              required: register?.fieldList?.isRequired,
                              message:
                                  "Please input your " +
                                  t(register?.fieldList?.label),
                              // message: t("common.msgRules") + t(register?.fieldList?.label),
                            },
                          ]}
                      >
                        <InputNumber
                            stringMode
                            controls={false}
                            addonBefore={
                              <Form.Item
                                  label=""
                                  name="countryCode"
                                  initialValue={userDetails?.countryCode}
                              >
                                <Select
                                    className={"mobileSelector"}
                                    style={{
                                      width: "auto",
                                    }}
                                    showSearch
                                    defaultValue={userDetails?.countryCode}
                                    onChange={(e) => setCountryCode(e)}
                                    popupMatchSelectWidth={false}
                                    optionFilterProp={"label"}
                                    optionLabelProp={"value"}
                                    options={register?.fieldList?.optionValue.map((d) => {
                                      return {
                                        label: t(d?.label),
                                        value: d?.value,
                                        key: d?.label,
                                      };
                                    })}
                                />
                              </Form.Item>
                            }
                        />
                      </Form.Item>
                  );

                  break;
                case "text":
                  component = (
                    <span className={"registerAccount"}>
                      <Input autoComplete={"off"} defaultValue={defaultValue} />
                    </span>
                  );
                  break;
                case "textarea":
                  component = <Input.TextArea />;
                  break;
                case "date":
                  component = <DatePicker />;
                  break;
                case "file":
                  component = (
                    <Upload action="/upload.do" listType="picture-card">
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    </Upload>
                  );
                  break;
                case "dropdown":
                  component = (
                    <Select showSearch>
                      {register?.fieldList?.optionValue.map((value) => {
                        return (
                          <Select.Option
                            key={value?.value}
                            value={value?.value}
                          >
                            {t(value?.label)}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  );
                  break;
                case "radioButton":
                  component = (
                    <Radio.Group>
                      {register?.fieldList?.optionValue.map((value) => {
                        return (
                          <Radio value={value?.value} key={value?.value}>
                            {t(value?.label)}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  );
                  break;
                case "checkbox":
                  component = <Checkbox />;
                  break;
                case "checkboxGroup":
                  component = (
                    <Checkbox.Group
                      options={register?.fieldList?.optionValue}
                    />
                  );
                  break;
                case "password":
                  component = <Input.Password autoComplete={"off"} />;
                  break;
              }

              // This is required to prevent nested input control bug
              if (register?.fieldList?.fieldType === "mobile") {
                return (
                  <Col md={12} xs={24} key={register?.fieldList?.fieldName}>
                    {component}
                  </Col>
                );
              }

              const rules = [
                {
                  required: register?.fieldList?.isRequired,
                  message: t("errorMsg.required.pleaseFillIn", {
                    field: t(register?.fieldList?.label),
                  }),
                  whitespace: true,
                }
              ]

              if (register?.fieldList?.fieldName?.includes("email")) {
                rules.push({
                  type: "email",
                  message: t("errorMsg.type.email"),
                })
              }

              return (
                <Col md={12} xs={24} key={register?.fieldList?.fieldName}>
                  <Form.Item
                    label={t(register?.fieldList?.label)}
                    name={register?.fieldList?.fieldName}
                    rules={rules}
                    initialValue={defaultValue}
                    // shouldUpdate={(prevValues, curValues) => {
                    //     console.log(prevValues, curValues);
                    //     return prevValues !== curValues;
                    // }}
                  >
                    {component}
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
          <Col md={24} xs={24} className={"registerBtn text-end mt-4"}>
            <Space>
              <Button
                className={"secondary_btn"}
                onClick={() => handleCancel()}
              >
                {t("common.cancel")}
              </Button>
              <Button
                htmlType={"submit"}
                loading={btnLoading}
                disabled={btnLoading}
              >
                {t("common.submit")}
              </Button>
            </Space>
          </Col>
        </Form>
      </div>
    </Modal>
  );
};

export default GoLiveModal;
