import {Col, message, Modal, QRCode, Row, Space, Tooltip} from "antd";
import {useRecoilValue} from "recoil";
import {globalReferralSettingState, globalUserDetailState} from "../../../../_common/global_state";
import {useTranslation} from "react-i18next";
import useShared from "../../../../_common/_shared";
import React from "react";
import copy from "copy-to-clipboard";

const QrModal = (props) => {
    const {isModalOpen, handleCancel} = props;
    const {IconCommon} = useShared();

    const userDetails = useRecoilValue(globalUserDetailState);
    const qrReferral = useRecoilValue(globalReferralSettingState);
    const mainDomain = window.location.hostname.split(".").slice(-2).join(".");
    const {t} = useTranslation();

    const homePageUrl = "https://" + mainDomain + "/?affiliate=" + userDetails?.affiliateCode;
    const liveUrl = "https://member." + mainDomain + "/#/register?type=individual&affiliate=" + userDetails?.affiliateCode;
    const demoUrl = "https://member." + mainDomain + "/#/register?type=demo&affiliate=" + userDetails?.affiliateCode;

    const copyLink = (link) => {
        copy(link);
        message.success(t("user.successMsg.affiliateLinkCopiedToClipboard"));
    };

    return (
        <Modal
            title={t("referral.modal.title")}
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
            className={"modalQr"}
            getContainer={() => document.getElementById("layout-6")}
        >
            <Row gutter={[64, 16]} className={"mt-5 text-center mb-5 justify-content-center"}>
                {qrReferral?.referralLink && (
                    <Col xs={24} md={8} className={"mb-3 mb-md-0"}>
                        <div className={"qr-wrap"}>
                            <h5 className={"qrTitle"}>{t("referral.homepage")}</h5>
                            <div className="header-qr-container">
                                <QRCode className={"border-0 mx-auto"} value={homePageUrl} />
                            </div>
                            <Row justify={"center"}>
                                <Space>
                                    <IconCommon
                                        iconName={"cm-link-regular"}
                                        style={{width: 20}}
                                        className={"clickable"}
                                        onClick={() => copyLink(homePageUrl)}
                                        tooltipText={t("common.copyToClipboard")}
                                    />
                                    <IconCommon
                                        iconName={"cm-share-regular"}
                                        style={{width: 20}}
                                        className={"clickable"}
                                        onClick={() => window.open(homePageUrl, "_blank")}
                                        tooltipText={t("common.openLinkInNewTab")}
                                    />
                                </Space>
                            </Row>
                        </div>
                    </Col>
                )}
                {qrReferral?.liveReferralLink && (
                    <Col xs={24} md={8} className={"mb-3 mb-md-0"}>
                        <div className={"qr-wrap"}>
                            <h5 className={"qrTitle"}>{t("referral.live")}</h5>
                            <div className="header-qr-container">
                                <QRCode className={"border-0 mx-auto"} value={liveUrl} />
                            </div>
                            <Row justify={"center"}>
                                <Space>
                                    <IconCommon
                                        iconName={"cm-link-regular"}
                                        style={{width: 20}}
                                        className={"clickable"}
                                        onClick={() => copyLink(liveUrl)}
                                        tooltipText={t("common.copyToClipboard")}
                                    />
                                    <IconCommon
                                        iconName={"cm-share-regular"}
                                        style={{width: 20}}
                                        className={"clickable"}
                                        onClick={() => window.open(liveUrl, "_blank")}
                                        tooltipText={t("common.openLinkInNewTab")}
                                    />
                                </Space>
                            </Row>
                        </div>
                    </Col>
                )}
                {qrReferral?.demoReferralLink && (
                    <Col xs={24} md={8} className={""}>
                        <div className={"qr-wrap"}>
                            <h5 className={"qrTitle"}>{t("referral.demo")}</h5>
                            <div className="header-qr-container">
                                <QRCode className={"border-0 mx-auto"} value={demoUrl} />
                            </div>
                            <Row justify={"center"}>
                                <Space>
                                    <IconCommon
                                        iconName={"cm-link-regular"}
                                        style={{width: 20}}
                                        className={"clickable"}
                                        onClick={() => copyLink(demoUrl)}
                                        tooltipText={t("common.copyToClipboard")}
                                    />
                                    <IconCommon
                                        iconName={"cm-share-regular"}
                                        style={{width: 20}}
                                        className={"clickable"}
                                        onClick={() => window.open(demoUrl, "_blank")}
                                        tooltipText={t("common.openLinkInNewTab")}
                                    />
                                </Space>
                            </Row>
                        </div>
                    </Col>
                )}
            </Row>
        </Modal>
    );
};

export default QrModal;
