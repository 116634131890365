import { Col, Modal, QRCode, Row, Space, Input, Button, message } from "antd";
import { useRecoilValue } from "recoil";
import {
  globalReferralSettingState,
  globalUserDetailState,
} from "../../../../_common/global_state";
import { useTranslation } from "react-i18next";
import useShared from "../../../../_common/_shared";
import copy from "copy-to-clipboard";

const QrModal = (props) => {
  const { isModalOpen, handleCancel } = props;

  const userDetails = useRecoilValue(globalUserDetailState);
  const qrReferral = useRecoilValue(globalReferralSettingState);
  const mainDomain = window.location.hostname.split(".").slice(-2).join(".");
  const { IconCommon } = useShared();
  const { t } = useTranslation();

  const homePageUrl =
    "https://" + mainDomain + "/?affiliate=" + userDetails?.affiliateCode;
  const liveUrl =
    "https://member." +
    mainDomain +
    "/#/register?type=individual&affiliate=" +
    userDetails?.affiliateCode;
  const demoUrl =
    "https://member." +
    mainDomain +
    "/#/register?type=demo&affiliate=" +
    userDetails?.affiliateCode;

  const copyLink = (link) => {
    copy(link);
    message.success(t("user.successMsg.affiliateLinkCopiedToClipboard"));
  };
  return (
    <Modal
      title={t("referral.modal.title")}
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
      className={"l-1 modalQr"}
      getContainer={() => document.getElementById("layout-3")}
    >
      <Row
        gutter={[16, 16]}
        className={"mt-5 text-center mb-5 justify-content-center"}
      >
        {qrReferral?.referralLink && (
          <Col xs={24} md={8} className={"mb-3 mb-md-0"}>
            <div className={"qr-wrap"}>
              <h5 className={"qrTitle"}>{t("referral.homepage")}</h5>
              <Space.Compact style={{ width: "100%" }} className="mb-3">
                <Input defaultValue={homePageUrl} readOnly={true} />
                <Button
                  type="primary"
                  onClick={() => {
                    copyLink(homePageUrl);
                  }}
                >
                  {t("common.copy")}
                </Button>
              </Space.Compact>
              {/* <a className={"qrLink"} href={homePageUrl} target="_blank">
                <p className="mb-2 referral-url">
                  <small>{homePageUrl}</small>
                </p>
              </a> */}
              <div className="header-qr-container">
                <QRCode value={homePageUrl} />
              </div>
            </div>
          </Col>
        )}
        {qrReferral?.liveReferralLink && (
          <Col xs={24} md={8} className={"mb-3 mb-md-0"}>
            <div className={"qr-wrap"}>
              <h5 className={"qrTitle"}>{t("referral.live")}</h5>
              <Space.Compact style={{ width: "100%" }} className="mb-3">
                <Input defaultValue={liveUrl} readOnly={true} />
                <Button
                  type="primary"
                  onClick={() => {
                    copyLink(liveUrl);
                  }}
                >
                  {t("common.copy")}
                </Button>
              </Space.Compact>
              {/* <a className={"qrLink mb-3"} href={liveUrl} target="_blank">
                <p className="mb-2 referral-url">
                  <small>{liveUrl}</small>
                </p>
              </a> */}
              <div className="header-qr-container">
                <QRCode value={liveUrl} />
              </div>
            </div>
          </Col>
        )}
        {qrReferral?.demoReferralLink && (
          <Col xs={24} md={8} className={""}>
            <div className={"qr-wrap"}>
              <h5 className={"qrTitle"}>{t("referral.demo")}</h5>
              <Space.Compact style={{ width: "100%" }} className="mb-3">
                <Input defaultValue={demoUrl} readOnly={true} />
                <Button
                  type="primary"
                  onClick={() => {
                    copyLink(demoUrl);
                  }}
                >
                  {t("common.copy")}
                </Button>
              </Space.Compact>
              {/* <a className={"qrLink"} href={demoUrl} target="_blank">
                <p className="mb-2 referral-url">
                  <small>{demoUrl}</small>
                </p>
              </a> */}
              <div className="header-qr-container">
                <QRCode value={demoUrl} />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default QrModal;
