import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_TRADING_ACCOUNT = gql`
    query getTradingAccount($filter: TradingAccountFilter,$filterType:String,$orderBy: String,$limit: Int,$offset:Int $startDate: String, $endDate: String, $dateKey: String) {
        getTradingAccount(filter: $filter,filterType:$filterType,orderBy: $orderBy,limit: $limit,offset: $offset, startDate: $startDate, endDate: $endDate, dateKey: $dateKey) {
            ... on TradingAccountAry {
                data {
                    _id
                    user {
                        _id
                        username
                        isLoginEnabled
                    }
                    accountId
                    traderId
                    brokerName
                    accessRights
                    depositAssetId
                    hasTradingPermission
                    spreadGroup {
                        _id
                        groupId
                        name
                    }
                    product {
                        _id
                        id
                        name
                        leverage
                        server{
                            _id
                            platform
                            mode
                            brokerName
                            name
                            plantId
                            resetPasswordLink
                        }
                        spreadGroup{
                            _id
                            name
                        }
                        mode
                        productType
                    }
                    platform
                    mode
                    leverage
                    leverageInCents
                    balance
                    totalBalance
                    lockBalance
                    margin
                    tradingVoucher
                    availableMargin
                    marginLevel
                    credit
                    equity
                    registrationDatetime
                    isSendReport
                    isDefault
                    depositAsset {
                        name
                        displayName
                    }
                    mt5SpreadGroup { group }
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getTradingAccount(userid) {

    const variables = {
        filter: {
            userid: userid
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_TRADING_ACCOUNT, variables, false);

    return execute;
}

const ADD_TRADING_ACCOUNT = gql`
    mutation addTradingAccount($input: TradingAccountInput) {
        addTradingAccount(input: $input) {
            ... on TradingAccount {
                _id
                user {
                    _id
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                        name
                    }
                    spreadGroup {
                        _id
                        name
                    }
                }
                platform
                mode
                leverage
                leverageInCents
                balance
                totalBalance
                margin
                tradingVoucher
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }

`;

export async function addTradingAccount(input) {

    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(ADD_TRADING_ACCOUNT, variables, true);

    return execute;
}

const CREATE_TRADING_ACCOUNT_MT5 = gql`
    mutation createTradingAccountMt5($input: TradingAccountMt5CreateInput) {
        createTradingAccountMt5(input: $input) {
            ... on TradingAccount {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

export async function addTradingAccountMt5(input) {

    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(CREATE_TRADING_ACCOUNT_MT5, variables, true);

    return execute;
}

const ADD_TRADING_ACCOUNT_TRANSACTION = gql`
    mutation addTradingAccountTransaction($input: tradingAccountTransactionInput) {
        addTradingAccountTransaction(input: $input) {
            ... on tradingAccountTransaction {
                _id
                type
                amount
                status
                remark
                depositType
                withdrawalType
                comment
                createdAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function addTradingAccountTransaction(input) {

    const variables = {
        input: input?.type === "deposit" ? {
            user: input?.user,
            account: input?.account,
            type: input?.type,
            amount: input?.amount.toString(),
            depositType: "ewallet",
            comment: null,
            sendEmailNotification: true,
        } : {
            user: input?.user,
            account: input?.account,
            type: input?.type,
            amount: input?.amount.toString(),
            withdrawalType: "ewallet",
            comment: null,
            sendEmailNotification: true,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(ADD_TRADING_ACCOUNT_TRANSACTION, variables, true);

    return execute;
}

const DEPOSIT_TRADING_ACCOUNT_MT5 = gql`
mutation depositTradingAccountMt5($input: mt5DepositInput) {
    depositTradingAccountMt5(input: $input) {
        ... on TradingAccountMt5Transaction {
            _id
        }
        ... on BaseError {
            errKey
            errMsg
            errObj
        }
    }
}
`

export async function depositTradingAccountMt5(input) {

    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DEPOSIT_TRADING_ACCOUNT_MT5, variables, true);

    return execute;
}

const WITHDRAWAL_TRADING_ACCOUNT_MT5 = gql`
mutation withdrawalTradingAccountMt5($input: mt5WithdrawalInput) {
    withdrawalTradingAccountMt5(input: $input) {
        ... on TradingAccountMt5Transaction {
            _id
            status
        }
        ... on BaseError {
            errKey
            errMsg
            errObj
        }
    }
}
`

export async function withdrawalTradingAccountMt5(input) {

    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(WITHDRAWAL_TRADING_ACCOUNT_MT5, variables, true);

    return execute;
}

const GET_TRADING_ACCOUNT_DETAIL = gql`
    query getTradingAccountDetail($id: String!) {
        getTradingAccountDetail(id: $id) {
            ... on TradingAccount {
                _id
                user {
                    _id
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server{
                        platform
                        mode
                        name
                    }
                    spreadGroup{
                        _id
                        name
                    }
                    mode
                }
                leverage
                leverageInCents
                balance
                asset
                totalBalance
                lockBalance
                margin
                tradingVoucher
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
                createdAt
                withdrawableAmount
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getTradingAccountDetail(id) {

    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_TRADING_ACCOUNT_DETAIL, variables, false);

    return execute;
}

const GET_TRADING_ACCOUNT_SETTING = gql`
    query getTradingAccountSetting {
        getTradingAccountSetting {
            ... on TradingAccountSetting {
                freeMarginPercentage
                tradingAccountLimitLive
                tradingAccountLimitDemo
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getTradingAccountSetting() {

    const variables = {
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_TRADING_ACCOUNT_SETTING, variables, false);

    return execute;
}

const GET_TRADING_ACCOUNT_TRANSACTION = gql`
    query getTradingAccountTransaction($filter: tradingAccountTransactionInput,$orderBy: String,$limit: Int,$offset:Int $startDate: String, $endDate: String, $dateKey: String) {
        getTradingAccountTransaction(filter: $filter,orderBy: $orderBy,limit: $limit,offset: $offset, startDate: $startDate, endDate: $endDate, dateKey: $dateKey) {
            ... on tradingAccountTransactionAry {
                data {
                    _id
                    type
                    amount
                    status
                    remark
                    depositType
                    withdrawalType
                    comment
                    createdAt
                    account{
                        accountId
                        brokerName
                        user{
                            _id
                            username
                        }
                    }
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getTradingAccountTransaction(id, type, status) {

    const variables = {
        filter: {
            account: id,
            type: type,
            status: status || null
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_TRADING_ACCOUNT_TRANSACTION, variables, false);

    return execute;
}

const CHANGE_PASSWORD_MT5 = gql`
mutation changePasswordMt5($input: TradingAccountMt5ChangePasswordInput) {
    changePasswordMt5(input: $input) {
        ... on TradingAccountChangePasswordMt5 {
            masterPassword {
                ... on TradingAccountChangePasswordMt5Status {
                    status
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
            investorPassword {
                ... on TradingAccountChangePasswordMt5Status {
                    status
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
        }
        ... on BaseError {
            errKey
            errMsg
            errObj
        }
        ... on CrmTaskApproval {
            msg
        }
    }
}
`

export async function changePasswordMt5(input) {

    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(CHANGE_PASSWORD_MT5, variables, true);

    return execute;
}