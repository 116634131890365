import React, {useState} from "react";
import {useRecoilValue} from "recoil";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Breadcrumb, Button, Col, Row} from "antd";
import {globalBreadCrumbState, globalUserDetailState} from "../../../_common/global_state";

export const Breadcrumb_4 = ({kybList, poiList, porList}) => {
    const navigate = useNavigate();
    const hash = window.location.hash;
    const currentPath = hash.substring(1);
    const splitPath = currentPath.split("?")[0];
    const globalBreadCrumb = useRecoilValue(globalBreadCrumbState);
    const {t} = useTranslation();

    function convertToTitleCase(str) {
        const withoutSlash = str.slice(1);
        const words = withoutSlash.split("-");
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalizedWords.join(" ");
    }

    const breadcrumbItems =
        Array.isArray(globalBreadCrumb) &&
        globalBreadCrumb?.length > 0 &&
        globalBreadCrumb?.map((item, index) => {
            return {
                title: (
                    <div
                        className={"clickable"}
                        style={index === globalBreadCrumb.length - 1 ? {color: "var(--primary-text-color)"} : {color: "var(--secondary-text-color)"}}
                        onClick={() => navigate(item?.path)}
                    >
                        {t(
                            `module.${item?.name
                                ?.replace(".", "")
                                .replace(/[-\s+]/g, "_")
                                .toLowerCase()}`
                        )}
                    </div>
                ),
            };
        });

    return (
        <Row>
            <Col span={24} className={"mb-2"}>
                <Breadcrumb className={"mx-auto mx-lg-0"} style={{width: "fit-content"}} separator=">" items={breadcrumbItems} />
            </Col>
        </Row>
    );
};
