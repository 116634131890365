import {Layout} from "antd";
import React, {useMemo, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import "./css/style.css";
import {Header_3} from "./component/header";
import {Sider_3} from "./component/sider";
import {Breadcrumb_3} from "./component/breadcrumb";
import {useRecoilState} from "recoil";
import {globalColorThemeState, globalOpenGoLiveModalState} from "../../_common/global_state";

const Layout_3 = ({
                      kybList,
                      poiList,
                      porList,
                      routes,
                      registerField,
                      navMap,
                  }) => {
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);

    const [openQrModal, setOpenQrModal] = useState(false);
    const [openGoLiveModal, setOpenGoLiveModal] = useRecoilState(globalOpenGoLiveModalState);
    const [openPlatformModal, setOpenPlatformModal] = useState(false);
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);

    const getHeaderRoutes = useMemo(() => {
        const more = routes.find((d) => d.key === "more");
        const home = routes.find((d) => d.key === "home");
        const pinned = routes.filter(
            (d) => (d.key !== "more" || d.key !== "home") && d.key in navMap
        );

        const parents = (more?.sub ?? []).filter((d) => !(d.key in navMap));

        const headerRoutes = [home, ...parents];
        pinned.forEach((d) => {
            const p = headerRoutes.find((e) => e.key === navMap[d.key]);
            if (p?.sub && !p.sub.some((f) => f.key === d.key)) {
                p.sub.push(d);
            }
        });
        return headerRoutes;
    }, [routes, navMap]);

    const themeClass = colorState === "dark" ? "dark-theme" : "light-theme";

    return (
        <Layout id={"layout-3"} className={themeClass}>
            <Header_3
                registerField={registerField}
                headerRoutes={getHeaderRoutes}
                collapsed={collapsed}
                setCollapsed={setCollapsed}

                openQrModal={openQrModal}
                setOpenQrModal={setOpenQrModal}
                openGoLiveModal={openGoLiveModal}
                setOpenGoLiveModal={setOpenGoLiveModal}
                openPlatformModal={openPlatformModal}
                setOpenPlatformModal={setOpenPlatformModal}
            >
                <Breadcrumb_3 kybList={kybList} poiList={poiList} porList={porList}/>
            </Header_3>
            <div className={`d-lg-none ${window.location.hash === "#/dashboard" ? "d-none" : ""}`}>
                <Breadcrumb_3 kybList={kybList} poiList={poiList} porList={porList}/>
            </div>
            <Layout>
                <Sider_3
                    routes={routes}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}

                    setOpenQrModal={setOpenQrModal}
                    setOpenGoLiveModal={setOpenGoLiveModal}
                    setOpenPlatformModal={setOpenPlatformModal}
                />
                <div
                    className="sider-overlay"
                    onClick={() => setCollapsed(false)}
                ></div>
                <Layout id={"layout-body-3"} className={"layout_padding container-xxl"}>
                    {/* {!(location.pathname === "/dashboard") && (
            <Breadcrumb_3
              kybList={kybList}
              poiList={poiList}
              porList={porList}
            />
          )} */}
                    <Outlet/>
                </Layout>
            </Layout>
            {/* <Footer_1 /> */}
        </Layout>
    );
};
export default Layout_3;
