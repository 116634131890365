import "./css/style.css"

export default function ExcotradesLoadingScreen() {

    return (
        <div id={"excotradesLoadingScreen"}>
            <div className={"loader"}>
                <div className={"temp_loader"}></div>
            </div>
        </div>
    );
}