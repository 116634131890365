import axios from "axios";

export async function getPromotions() {
    const cmsUrl = localStorage?.getItem("cmsUrl")
    const lang = localStorage?.getItem("language")
    const headers = {
        'Authorization': 'e01a4b78-d5ca-404e-a63c-7e8c032eeb38',
    };

    return await axios({
        url: "https://cmsapi." + cmsUrl + `/api/category/getCategoryPosting?name=web_tradersroom&section=promotion&lang=${lang}`,
        // url: "https://cmsapi." + cmsUrl + `/api/section/getSectionPosting?name=promotion&lang=${lang}`,
        method: 'get',
        headers: headers
    })
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            console.log(error)
            throw error;
        });
}