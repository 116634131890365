import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_REFERRAL_SETTING = gql`
    query getReferralSetting {
        getReferralSetting {
             ... on ReferralSetting {
                referralLink
                liveReferralLink
                demoReferralLink
             }
            ...on BaseError{
            errKey
            errMsg
        }
        }
    }
`;

export async function getReferralSetting() {

    const variables = {
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_REFERRAL_SETTING, variables, false);
    return execute;
}