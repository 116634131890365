import { Breadcrumb, Button, Col, Row } from "antd";
import React, { useState } from "react";
import KycModal_2 from "../../../pages/accountVerification/view/layout_2/component/kycModal";
import { useRecoilValue } from "recoil";
import {
  globalBreadCrumbState,
  globalUserDetailState,
} from "../../../_common/global_state";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Breadcrumb_2 = ({ kybList, poiList, porList }) => {
  const navigate = useNavigate();
  const hash = window.location.hash;
  const currentPath = hash.substring(1);
  const splitPath = currentPath.split("?")[0];
  const [openKycModal, setOpenKycModal] = useState(false);
  const globalBreadCrumb = useRecoilValue(globalBreadCrumbState);
  const { t } = useTranslation();

  function convertToTitleCase(str) {
    const withoutSlash = str.slice(1);
    const words = withoutSlash.split("-");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  const breadcrumbItems =
    Array.isArray(globalBreadCrumb) &&
    globalBreadCrumb?.length > 0 &&
    globalBreadCrumb?.map((item, index) => {
      return {
        title: (
          <div
            className={"clickable"}
            style={
              index === globalBreadCrumb.length - 1
                ? { color: localStorage.getItem("color") === "light" ? "var(--brand-300-color)" : "var(--brand-100-color)" }
                : { color: "var(--primary-text-color)" }
            }
            onClick={() => navigate(item?.path)}
          >
            {t(
              `module.${item?.name
                ?.replace(".", "")
                .replace(/[-\s+]/g, "_")
                .toLowerCase()}`
            )}
          </div>
        ),
      };
    });

  return (
    <Row>
      <Col span={24} className={"mb-2"}>
        <Breadcrumb
          className={"mx-auto mx-lg-0"}
          style={{ width: "fit-content" }}
          items={breadcrumbItems}
        />
      </Col>
      <KycModal_2
        isModalOpen={openKycModal}
        handleCancel={() => {
          setOpenKycModal(false);
        }}
        verificationType={"kyb"}
        data={kybList}
        poiList={poiList}
        porList={porList}
      />
    </Row>
  );
};
