import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import enUS from './en-US';
import bmMY from './ms-MY';

const resources = {
  "en": {
    translation: enUS,
  },
  "ms": {
    translation: bmMY,
  },
};

i18next
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources,
    interpolation: {
      escapeValue: false
    }
  })