import useShared from "../../../../../shared/_common/_shared";

export default function FundaEmptyData(props) {
    const {title} = props;
    const {IconCommon} = useShared();
    return (
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <IconCommon
                isAlwaysInActive={true}
                iconName={"cm-empty-regular"}
                className={"empty-data"}
                title={title || "noData"}
            />
        </div>
    );
}
