import {updateClientUser, updateEmailNewsletterSubscription, updateUser, updateUserPassword} from "../../../api/graphql/user";
import {getBankInfo, updateBankInfo} from "../../../api/graphql/bankInformation";
import {useRecoilState, useSetRecoilState} from "recoil";
import {globalNotificationState, globalUserDetailState} from "../../../_common/global_state";
import {selfBankInfoAccountState} from "../state";
import {useVerification} from "../../../_common/_authorize";
import {useTranslation} from "react-i18next";


const useProfileFunction = () => {
    const {t} = useTranslation()
    const setNotificationMessage = useSetRecoilState(globalNotificationState);
    const [userDetails, setUserDetailState] = useRecoilState(globalUserDetailState);
    const setBankInfo = useSetRecoilState(selfBankInfoAccountState)

    const {baseErrorChecking} = useVerification()

    const getBankDetails = async () => {
        try {
            const bankDetail = await getBankInfo(userDetails?._id)
            setBankInfo(bankDetail.getBankInformation.data)
        } catch (e) {
            console.log("Error:", e)
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }

    }

    const updatePassword = async (id, value) => {
        try {
            const response = await updateUserPassword(id, value)
            if (response?.updateUserPassword?.__typename === "BaseError") {

                return await baseErrorChecking(response?.updateUserPassword?.errKey)

            } else {
                setUserDetailState(response?.updateUserPassword)
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Updated Successfully",
                    key: "profileNotification",
                });
            }
        } catch (e) {
            console.log("Error:", e)
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }

    }

    const updateProfile = async (id, value, isUpdatingLanguage = false) => {
        try {
            const response = await updateClientUser(id, value)
            if (response?.updateClientUser?.__typename === "BaseError") {

                return await baseErrorChecking(response?.updateClientUser?.errKey)

            } else {
                setUserDetailState(response?.updateClientUser)
                if (isUpdatingLanguage) {
                    return response?.updateClientUser
                } else {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Updated Successfully",
                        key: "profileNotification",
                    });
                }
            }
        } catch (e) {
            console.log("Error:", e)
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }

    }

    const updateUserProfile = async (id, value) => {
        try {
            const response = await updateUser(id, value)
            if (response?.updateUser?.__typename === "BaseError") {
                return await baseErrorChecking(response?.updateUser?.errKey)
            } else {
                setUserDetailState(response?.updateUser)
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Updated Successfully",
                    key: "profileNotification",
                });
            }
        } catch (e) {
            console.log("Error:", e)
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }

    }

    const updateUserNewsletterSettings = async (input) => {
        try {
            const {mode} = input
            const response = await updateEmailNewsletterSubscription(input)
            if (response?.updateEmailNewsletterSubscription?.__typename === "BaseError") {
                return await baseErrorChecking(response?.updateEmailNewsletterSubscription?.errKey)
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t(`common.${mode}`) + " Successfully",
                    key: "profileNotification",
                });
            }
        } catch (e) {
            console.log("Error:", e)
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }

    }

    function validateInput(input) {
        // Regular expression to allow only alphanumeric characters
        const regex = /^[a-zA-Z0-9 ]+$/;

        return regex.test(input);
    }

    const validateKeys = ["account_holder", "account_no"]

    const updateBankDetail = async (userid, modifiedObject, id) => {
        try {
            const invalidKey = []
            Object.keys(modifiedObject)?.map(d => {
                if (validateKeys.includes(d)) {
                    if (!validateInput(modifiedObject[d])) {
                        invalidKey.push(t(`psp.${d}`))
                    }
                }
            })

            if (invalidKey?.length > 0) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t("errMsg.specialCharactersAreNotAllowed", {field: invalidKey.join(", ")}),
                    key: "profileNotification",
                });
            } else {

                const response = updateBankInfo({
                    id: id || null,
                    user: userid,
                    formData: modifiedObject
                })

                if (response?.updateBankInformation?.__typename === "BaseError") {

                    return await baseErrorChecking(response?.updateBankInformation?.errKey)

                } else {
                    const bankDetail = await getBankInfo(userid)
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Updated Successfully",
                        key: "profileNotification",
                    });
                    await getBankDetails()
                    setBankInfo(bankDetail?.getBankInformation?.data)
                    return {
                        status: "success",
                    }
                }
            }
        } catch (e) {
            console.log("Error:", e)
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }

    }

    const updatePspBankDetail = async (userid, modifiedObject, id) => {
        try {
            const invalidKey = []
            Object.keys(modifiedObject)?.map(d => {
                if (validateKeys.includes(d)) {
                    if (!validateInput(modifiedObject[d])) {
                        invalidKey.push(t(`psp.${d}`))
                    }
                }
            })

            if (invalidKey?.length > 0) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t("errMsg.specialCharactersAreNotAllowed", {field: invalidKey.join(", ")}),
                    key: "profileNotification",
                });
            } else {
                const response = updateBankInfo({
                    psp: id || null,
                    user: userid,
                    formData: modifiedObject
                })

                if (response?.updateBankInformation?.__typename === "BaseError") {

                    return await baseErrorChecking(response?.updateBankInformation?.errKey)

                } else {
                    const bankDetail = await getBankInfo(userid)
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Updated Successfully",
                        key: "profileNotification",
                    });
                    await getBankDetails()
                    setBankInfo(bankDetail?.getBankInformation?.data)
                    return {
                        status: "success",
                    }
                }
            }

        } catch (e) {
            console.log("Error:", e)
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }

    }

    return {
        updateProfile,
        updateUserProfile,
        updateBankDetail,
        updatePspBankDetail,
        updatePassword,
        updateUserNewsletterSettings
    }

}

export {
    useProfileFunction
}