import { Button, Col, Divider, List, Modal, Row, Switch } from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import {
  globalBrandModuleSettingState,
  globalWebModuleSettingState,
} from "../../../_common/global_state";
import React, { useEffect, useState } from "react";
import VirtualList from "rc-virtual-list";
import useLayoutFunction from "../../function";
import useShared from "../../../../shared/_common/_shared";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { moduleTranslation } from "../../../_common/function";

const EditNavigationModal_2 = (props) => {
  const { isModalOpen, handleCancel } = props;
  const [userPreference, setUserPreference] = useState([]);
  const brandModuleSetting = useRecoilValue(globalBrandModuleSettingState);
  const webModuleSetting = useRecoilValue(globalWebModuleSettingState);
  const { saveNavigationFunction, resetNavigationFunction } =
    useLayoutFunction();
  const { IconCommon, IconModule } = useShared();
  const [checkedSwitches, setCheckedSwitches] = useState(() => {
    return userPreference?.map((item) => item?.moduleId?._id);
  });
  const [remainAdding, setRemainAdding] = useState(0);
  const [initialUserPreference, setInitialUserPreference] = useState([]);
  const { t } = useTranslation();

  function reorderPositions(arr, itemId, newPosition) {
    const updatedArray = arr?.map((item) => ({ ...item }));

    const movingItem = updatedArray?.find(
      (item) => item?.moduleId?.id === itemId
    );
    if (!movingItem) {
      return updatedArray;
    }

    const lockedPositions = updatedArray
      ?.filter((item) => item?.isLocked)
      ?.map((item) => item?.position);

    let nextAvailablePosition = Math.max(...lockedPositions) + 1;
    while (lockedPositions?.includes(nextAvailablePosition)) {
      nextAvailablePosition++;
    }
    newPosition = Math.max(nextAvailablePosition, newPosition);

    const currentPos = movingItem?.position;

    if (currentPos < newPosition) {
      for (let i = currentPos + 1; i <= newPosition; i++) {
        const item = updatedArray?.find((item) => item?.position === i);
        if (item && !item?.isLocked) {
          item.position--;
        }
      }
    } else if (currentPos > newPosition) {
      for (let i = currentPos - 1; i >= newPosition; i--) {
        const item = updatedArray?.find((item) => item?.position === i);
        if (item && !item?.isLocked) {
          item.position++;
        }
      }
    }

    movingItem.position = newPosition;

    return updatedArray?.sort((a, b) => a.position - b.position);
  }

  const handleDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }

    const { source, destination } = result;

    const findObj = userPreference?.find(
      (item) => item?.moduleId?.name === source?.droppableId
    );
    const desObj = userPreference?.find(
      (item) => item?.moduleId?.name === destination?.droppableId
    );

    if (desObj?.isLocked === true || findObj?.isLocked === true) {
      return false;
    }

    const reorderedItems = reorderPositions(
      userPreference,
      findObj?.moduleId?._id,
      desObj?.position
    );
    setUserPreference(reorderedItems);
  };

  const isModuleSelected = (id) => {
    return checkedSwitches?.includes(id);
  };

  const isSwitchDisabled = (id) => {
    const currentSwitch = brandModuleSetting?.find((item) => item?._id === id);
    const isSelected =
      checkedSwitches?.find((checkId) => checkId === id)?.length > 0;

    if (isSelected) {
      return false;
    } else {
      if (userPreference?.length < 5) {
        if (currentSwitch?.parent === null) {
          return brandModuleSetting?.some(
            (item) =>
              item?.parent?._id === currentSwitch?._id &&
              isModuleSelected(item?._id)
          );
        } else {
          return isModuleSelected(currentSwitch?.parent?._id);
        }
      } else {
        return true;
      }
    }
  };

  const remove = (id) => {
    setUserPreference((prevUserPreference) =>
      prevUserPreference?.filter((item) => item?.moduleId?._id !== id)
    );
    setCheckedSwitches((prevCheckedSwitches) => {
      const updatedCheckedSwitches = prevCheckedSwitches?.map((item) => {
        if (item === id) {
          return { _id: item, checked: false };
        }
        return item;
      });
      return updatedCheckedSwitches;
    });
  };

  const add = (add, id) => {
    const itemToAdd = brandModuleSetting.find((item) => item?._id === id);
    if (add === true && !isModuleSelected(id)) {
      if (userPreference?.length < 5) {
        const newItem = {
          moduleId: {
            ...itemToAdd,
          },
          position: userPreference?.length + 1,
          __typename: "UserWebNavigation",
        };

        setUserPreference((prevUserPreference) => [
          ...prevUserPreference,
          newItem,
        ]);
        setCheckedSwitches((prevCheckedSwitches) => [
          ...prevCheckedSwitches,
          id,
        ]);
      }
    } else if (add === false && isModuleSelected(id)) {
      setUserPreference((prevUserPreference) =>
        prevUserPreference?.filter((item) => item?.moduleId?._id !== id)
      );
      setCheckedSwitches((prevCheckedSwitches) =>
        prevCheckedSwitches?.filter((item) => item !== id)
      );
    }
  };

  const handleSave = async () => {
    await saveNavigationFunction(userPreference);
    handleCancel();
  };

  const handleReset = async () => {
    await resetNavigationFunction();
    handleCancel();
  };

  // function moduleTranslation(inputString) {
  //     let resultString = inputString.toLowerCase();
  //     resultString = resultString.replace(/\s+/g, '_');
  //     return resultString;
  // }

  useEffect(() => {
    const filterModuleSetting = webModuleSetting.filter(
      (item) => item?.moduleId?.isEnabled === true
    );
    setUserPreference(filterModuleSetting);
    setInitialUserPreference(filterModuleSetting);
  }, [webModuleSetting]);

  useEffect(() => {
    setCheckedSwitches(userPreference?.map((item) => item?.moduleId?._id));
    const maxAllowedItems = 5;
    const remainModule = maxAllowedItems - userPreference?.length;
    setRemainAdding(remainModule);
    return () => {
      if (!isModalOpen) {
        setUserPreference(initialUserPreference);
      }
    };
  }, [isModalOpen, userPreference]);

  return (
    <Modal
      destroyOnClose={true}
      title={t("common.editNavigation")}
      open={isModalOpen}
      width={"60%"}
      className={"navigationModal"}
      getContainer={() => document.getElementById("layout-2")}
      footer={[
        <div className={"d-flex justify-content-between mt-4 mx-3 mx-md-0"}>
          <div
            key="reset"
            className={"d-flex align-items-center clickable"}
            onClick={handleReset}
          >
            <HistoryOutlined
              style={{
                verticalAlign: "-3px",
                color: "var(--primary-text-color)",
                marginRight: "10px",
              }}
            />
            <p className={"d-none d-md-block"} style={{ margin: 0 }}>
              {t("editNavigationModal.resetNavigationToDefault")}
            </p>
            <p className={"d-md-none"} style={{ margin: 0 }}>
              {t("common.reset")}
            </p>
          </div>
          <div className={"d-flex align-items-center clickable"}>
            <Button
              key="cancel"
              onClick={handleCancel}
              className={"secondary_btn"}
            >
              {t("common.cancel")}
            </Button>
            <Button key="save" onClick={handleSave} className={"primary_btn"}>
              {t("common.save")}
            </Button>
          </div>
        </div>,
      ]}
      onCancel={handleCancel}
    >
      <Divider className={"dividerColor"} />
      <div id={"editNavigation"}>
        <Row className={"mt-3"} gutter={[16, 16]}>
          <Col
            style={{ paddingLeft: "1.15rem", paddingRight: "1.15rem" }}
            xs={24}
            md={12}
          >
            <div className={"d-flex justify-content-between mb-3"}>
              <p className={"subTitle"}>
                {t("editNavigationModal.navigationItems")}
              </p>

              <p className={"subTitle"}>
                {t("editNavigationModal.NumMoreToAdd", { remainAdding })}
              </p>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <div>
                {userPreference &&
                  [...userPreference]
                    .sort((a, b) => a?.position - b?.position)
                    .map((item, index) => {
                      if (item?.moduleId?.isEnabled === true) {
                        return (
                          <Droppable
                            key={item?.moduleId?.name}
                            droppableId={item?.moduleId?.name}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                <Draggable
                                  key={item?.moduleId?.name}
                                  draggableId={item?.moduleId?.name}
                                  index={index}
                                  isDragDisabled={item?.isLocked}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      className="editNavigationList"
                                    >
                                      <div className="row justify-content-start align-items-center">
                                        <div className={`col-2 text-center`}>
                                          <IconModule
                                            active={false}
                                            className={"sidebarIcon"}
                                            iconName={item?.moduleId?.name}
                                          />
                                        </div>
                                        <div className={`col-6 text-start`}>
                                          <p>
                                            {t(
                                              moduleTranslation(
                                                item?.moduleId?.name
                                              )
                                            )}
                                          </p>
                                        </div>
                                        {item?.isLocked !== true && (
                                          <>
                                            <div className="col-2 text-end">
                                              <IconCommon
                                                iconName={"delete"}
                                                className={
                                                  "sidebarIcon clickable"
                                                }
                                                style={{
                                                  height: "1.2rem",
                                                  width: "1.2rem",
                                                }}
                                                onClick={() =>
                                                  remove(item?.moduleId?._id)
                                                }
                                              />
                                            </div>
                                            <div className="col-1 text-end">
                                              <div
                                                {...provided.dragHandleProps}
                                              >
                                                <IconCommon
                                                  style={{
                                                    height: "1.2rem",
                                                    width: "1.2rem",
                                                  }}
                                                  iconName={"hamburger"}
                                                  className={
                                                    "sidebarIcon row-resize"
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        );
                      }
                    })}
              </div>
            </DragDropContext>
          </Col>
          <Col
            style={{ paddingLeft: "1.15rem", paddingRight: "1.15rem" }}
            xs={24}
            md={12}
          >
            <p className={"subTitle mb-3"}>
              {t("editNavigationModal.itemForSelection")}
            </p>
            <List>
              <VirtualList
                data={brandModuleSetting?.filter(
                  (item) => item?.isEnabled === true && item?.parent === null
                )}
                height={"60vh"}
                itemHeight={47}
                itemKey="stock"
              >
                {(item) => {
                  let isOpen = webModuleSetting?.find(
                    (items) => items?.moduleId?.name === item?.name
                  );
                  const isSelected =
                    checkedSwitches?.find((items) => items === item?._id)
                      ?.length > 0;
                  let children = [];
                  brandModuleSetting?.map((items) => {
                    if (
                      items?.parent?._id === item?._id &&
                      items?.isEnabled === true
                    ) {
                      children?.push(items);
                    }
                  });
                  return (
                    <>
                      <div className={"editNavigationList"}>
                        <Row gutter={[16, 16]} className={"align-items-center"}>
                          <Col span={4} className={"text-center"}>
                            <IconModule
                              active={false}
                              className={"sidebarIcon"}
                              iconName={t(item?.name)}
                            />
                          </Col>
                          <Col span={16}>
                            <p>{t(moduleTranslation(item?.name))}</p>
                          </Col>
                          <Col span={4}>
                            <Switch
                              size={"small"}
                              defaultChecked={isOpen && true}
                              // checked={switchState.find((items) => items?._id === item?.moduleId?._id)?.checked}
                              checked={isSelected}
                              onChange={(e) => add(e, item?._id)}
                              // disabled={webModuleSetting?.length > 4 || isOpen?.isLocked === true}
                              disabled={
                                isSwitchDisabled(item?._id) ||
                                isOpen?.isLocked === true
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                      {children?.length > 0 &&
                        children.map((item) => {
                          const isSelected =
                            checkedSwitches?.find(
                              (items) => items === item?._id
                            )?.length > 0;
                          return (
                            <div className={"editNavigationList"}>
                              <Row
                                gutter={[16, 16]}
                                className={"align-items-center"}
                              >
                                <Col span={7} className={"text-end"}>
                                  <IconModule
                                    active={false}
                                    className={"sidebarIcon"}
                                    iconName={t(item?.name)}
                                  />
                                </Col>
                                <Col span={13}>
                                  <p>{t(moduleTranslation(item?.name))}</p>
                                </Col>
                                <Col span={4}>
                                  <Switch
                                    size={"small"}
                                    defaultChecked={isOpen && true}
                                    // checked={switchState.find((items) => items?._id === item?.moduleId?._id)?.checked}
                                    checked={isSelected}
                                    onChange={(e) => add(e, item?._id)}
                                    // disabled={webModuleSetting?.length > 4 || isOpen?.isLocked === true || !isOpen}
                                    disabled={
                                      isSwitchDisabled(item?._id) ||
                                      isOpen?.isLocked === true
                                    }
                                  />
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                    </>
                  );
                }}
              </VirtualList>
            </List>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default EditNavigationModal_2;
