import "./css/style.css";

export default function QuorvexLoadingScreen() {
    return (
        <div className="loading-screen">
            <svg id="quorvexLoader" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.6682 13.7667L32.2321 4.32702C27.1314 -0.775672 18.8623 -0.775672 13.7616 4.32702L4.32555 13.7667C-0.775183 18.8694 -0.775183 27.1416 4.32555 32.2443L13.7616 41.684C16.1959 44.1192 19.358 45.3933 22.5514 45.5V40.868C22.1687 36.5373 20.3242 32.3071 17.0115 28.9932C13.6612 25.6478 9.38864 23.7963 5.01569 23.4323V22.5787C9.38864 22.2209 13.6675 20.3694 17.0115 17.0178C20.3618 13.6663 22.2126 9.38577 22.5702 5.00486H23.4235C23.7811 9.38577 25.6319 13.6663 28.9822 17.0178C32.3325 20.3694 36.6114 22.2209 40.9906 22.5787V23.4323C36.6114 23.79 32.3325 25.6416 28.9822 28.9932C25.6319 32.3447 23.7874 36.6189 23.4235 40.9936V45.5C26.6232 45.3996 29.7916 44.1255 32.2384 41.684L41.6745 32.2443C46.7752 27.1416 46.7752 18.8694 41.6745 13.7667H41.6682Z" fill="#001453"/>
            </svg>

            {/*<svg id="quorvexLoader" width="50" height="50" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*    <g>*/}
            {/*        <circle shapeRendering="smooth" filter="url(#filter0_diiiiiii_1_4551)" cx="150" cy="150" r="45" fill="url(#paint0_linear_1_4551)"/>*/}
            {/*        <circle shapeRendering="smooth" id="quorvex-loader-outline" cx="150" cy="150" r="43" stroke="url(#paint1_linear_26_3)" strokeWidth="5"/>*/}
            {/*    </g>*/}
            {/*    <path d="M168.253 140.972L159.027 131.742C154.04 126.753 145.954 126.753 140.967 131.742L131.741 140.972C126.753 145.961 126.753 154.05 131.741 159.039L140.967 168.269C143.347 170.65 146.439 171.896 149.561 172V167.471C149.187 163.237 147.384 159.1 144.145 155.86C140.869 152.589 136.691 150.779 132.415 150.423V149.588C136.691 149.238 140.875 147.428 144.145 144.151C147.42 140.874 149.23 136.688 149.58 132.405H150.414C150.764 136.688 152.573 140.874 155.849 144.151C159.125 147.428 163.309 149.238 167.591 149.588V150.423C163.309 150.772 159.125 152.583 155.849 155.86C152.573 159.137 150.77 163.316 150.414 167.594V172C153.543 171.902 156.641 170.656 159.033 168.269L168.259 159.039C173.247 154.05 173.247 145.961 168.259 140.972H168.253Z" fill="#001453"/>*/}
            {/*    <defs>*/}
            {/*        <filter shapeRendering="smooth" id="filter0_diiiiiii_1_4551" x="0" y="0" width="300" height="300" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">*/}
            {/*            <feFlood floodOpacity="0" result="BackgroundImageFix"/>*/}
            {/*            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>*/}
            {/*            <feOffset/>*/}
            {/*            <feGaussianBlur stdDeviation="52.9131"/>*/}
            {/*            <feColorMatrix type="matrix" values="0 0 0 0 0.109804 0 0 0 0 0.215686 0 0 0 0 1 0 0 0 0.5 0"/>*/}
            {/*            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_4551"/>*/}
            {/*            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_4551" result="shape"/>*/}

            {/*            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>*/}
            {/*            <feOffset dx="1" dy="1"/>*/}
            {/*            <feGaussianBlur stdDeviation="9.5"/>*/}
            {/*            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>*/}
            {/*            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"/>*/}
            {/*            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1_4551"/>*/}

            {/*            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>*/}
            {/*            <feOffset dx="5" dy="4"/>*/}
            {/*            <feGaussianBlur stdDeviation="8.5"/>*/}
            {/*            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>*/}
            {/*            <feColorMatrix type="matrix" values="0 0 0 0 0.111979 0 0 0 0 0.127193 0 0 0 0 0.208333 0 0 0 0.5 0"/>*/}
            {/*            <feBlend mode="normal" in2="effect2_innerShadow_1_4551" result="effect3_innerShadow_1_4551"/>*/}

            {/*            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>*/}
            {/*            <feOffset dx="5" dy="4"/>*/}
            {/*            <feGaussianBlur stdDeviation="11"/>*/}
            {/*            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>*/}
            {/*            <feColorMatrix type="matrix" values="0 0 0 0 0.178681 0 0 0 0 0.191257 0 0 0 0 0.258333 0 0 0 0.1 0"/>*/}
            {/*            <feBlend mode="normal" in2="effect3_innerShadow_1_4551" result="effect4_innerShadow_1_4551"/>*/}
            {/*            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>*/}
            {/*            <feOffset dx="5" dy="4"/>*/}
            {/*            <feGaussianBlur stdDeviation="7"/>*/}
            {/*            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>*/}
            {/*            <feColorMatrix type="matrix" values="0 0 0 0 0.413194 0 0 0 0 0.426968 0 0 0 0 0.495833 0 0 0 0.33 0"/>*/}
            {/*            <feBlend mode="normal" in2="effect4_innerShadow_1_4551" result="effect5_innerShadow_1_4551"/>*/}
            {/*            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>*/}
            {/*            <feOffset dx="7" dy="9"/>*/}
            {/*            <feGaussianBlur stdDeviation="21"/>*/}
            {/*            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>*/}
            {/*            <feColorMatrix type="matrix" values="0 0 0 0 0.0306944 0 0 0 0 0.0492917 0 0 0 0 0.216667 0 0 0 0.1 0"/>*/}
            {/*            <feBlend mode="normal" in2="effect5_innerShadow_1_4551" result="effect6_innerShadow_1_4551"/>*/}
            {/*            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>*/}
            {/*            <feOffset dx="-4" dy="-3"/>*/}
            {/*            <feGaussianBlur stdDeviation="4.5"/>*/}
            {/*            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>*/}
            {/*            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"/>*/}
            {/*            <feBlend mode="normal" in2="effect6_innerShadow_1_4551" result="effect7_innerShadow_1_4551"/>*/}
            {/*            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>*/}
            {/*            <feOffset dx="3.73381" dy="3.73381"/>*/}
            {/*            <feGaussianBlur stdDeviation="3.11150"/>*/}
            {/*            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>*/}
            {/*            <feColorMatrix type="matrix" values="0 0 0 0 0.4 0 0 0 0 0.4 0 0 0 0 0.5 0 0 0 1 0"/>*/}
            {/*            <feBlend mode="normal" in2="effect7_innerShadow_1_4551" result="effect8_innerShadow_1_4551"/>*/}
            {/*        </filter>*/}
            {/*        <linearGradient id="paint0_linear_1_4551" x1="150" y1="106" x2="150" y2="196" gradientUnits="userSpaceOnUse">*/}
            {/*            <stop stopColor="black" stopOpacity="0.22"/>*/}
            {/*            <stop offset="0.0001" stopOpacity="0.22"/>*/}
            {/*            <stop offset="1" stopOpacity="0.11"/>*/}
            {/*        </linearGradient>*/}
            {/*        <linearGradient id="paint1_linear_26_3" x1="196" y1="161" x2="110.5" y2="141.5" gradientUnits="userSpaceOnUse">*/}
            {/*            <stop stopColor="#1C37FF"/>*/}
            {/*            <stop offset="1" stopColor="#1C37FF" stopOpacity="0"/>*/}
            {/*        </linearGradient>*/}
            {/*    </defs>*/}
            {/*</svg>*/}
        </div>
    );
}
