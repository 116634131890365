import { gql } from "@apollo/client";
import { ApolloClientQuery } from "./_setup";

const GET_USER_VERIFICATION_COUNTRY = gql`
  query getUserVerificationCountry {
    getUserVerificationCountry {
      ... on UserVerificationCountryArray {
        data {
          country
          _id
          isEnabled
          isDeleted
        }
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

export async function getUserVerificationCountry() {
  const variables = {
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    GET_USER_VERIFICATION_COUNTRY,
    variables,
    false
  );

  return execute;
}

export const GET_USER_VERIFICATION_FIELD = gql`
  query getUserVerificationAvailableField(
    $filter: UserVerificationAvailableFieldFilter
  ) {
    getUserVerificationAvailableField(filter: $filter) {
      ... on UserVerificationAvailableField {
        data
      }
    }
  }
`;

export async function getUserVerificationAvailableField(countryId) {
  const variables = {
    filter: {
      country: countryId,
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    GET_USER_VERIFICATION_FIELD,
    variables,
    false
    // "cache-first"
  );

  return execute;
}

export const CREATE_USER_VERIFICATION_RECORD_DOCUMENT = gql`
  mutation createUserVerificationRecordDocument(
    $input: UserVerificationRecordDocumentInput!
  ) {
    createUserVerificationRecordDocument(input: $input) {
      ... on UserVerificationRecordDocument {
        _id
        ip
        status
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

export async function createUserVerificationRecordDocument({
  user,
  record,
  groupSetting,
  document,
  device,
  deviceToken,
  browser,
  ip,
}) {
  const variables = {
    input: {
      user,
      record,
      groupSetting,
      document,
      device,
      deviceToken,
      browser,
      ip,
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    CREATE_USER_VERIFICATION_RECORD_DOCUMENT,
    variables,
    true
  );

  return execute;
}

// export const COMPLETE_USER_VERIFICATION_RECORD_DOCUMENT = gql`
//   mutation completeUserVerificationRecordDocument($user: String!) {
//     completeUserVerificationRecordDocument(user: $user) {
//       ... on UserVerificationRecordDocumentArray {
//         data {
//           document {
//             _id
//           }
//           status
//           _id
//         }
//       }
//       ... on BaseError {
//         errMsg
//       }
//     }
//   }
// `;

// export async function completeUserVerificationRecordDocument({ user }) {
//   const variables = {
//     user,
//     context: {
//       headers: {
//         authorization: "Bearer " + localStorage.getItem("a_t"),
//       },
//     },
//   };
//   const execute = await ApolloClientQuery(
//     COMPLETE_USER_VERIFICATION_RECORD_DOCUMENT,
//     variables,
//     true
//   );

//   return execute;
// }

export const SUBMIT_USER_VERIFICATION_RECORD_DOCUMENT = gql`
  mutation submitUserVerificationRecordDocument(
    $input: UserVerificationRecordValueInput!
  ) {
    submitUserVerificationRecord(input: $input) {
      ... on UserVerificationRecordValueArray {
        data {
          _id
          field {
            _id
            name
            label
            tag
            fieldValue {
              label
              value
            }
            fieldType
            fieldGroupType
            fieldGroup {
              _id
              name
              label
              tag
              fieldType
              fieldValue {
                label
                value
              }
              fieldGroupType
            }
          }
          linkField
          value
          status
          expiredDate
        }
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

export async function submitUserVerificationRecordDocument({
  documentRecord,
  user,
  field,
  country,
}) {
  const variables = {
    input: {
      documentRecord,
      user,
      field,
      countryName: country,
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    SUBMIT_USER_VERIFICATION_RECORD_DOCUMENT,
    variables,
    true
  );

  return execute;
}

export const GET_USER_VERIFICATION_RECORD_VALUE = gql`
  query getUserVerificationRecordValue(
    $filter: UserVerificationRecordValueFilter
  ) {
    getUserVerificationRecordValue(filter: $filter) {
      ... on UserVerificationRecordValueArray {
        data {
          _id
          referenceId {
            _id
            label
            name
          }
          status
          value
          expiredDate
          hasExpiredDate
          fieldSetting {
            _id
          }
          field {
            _id
            isEnabled
            isDeleted
            name
            label
            fieldType
            fieldGroupType
            fieldGroup {
              _id
              name
              label
              fieldType
              isEnabled
              isDeleted
            }
          }
          documentRecord {
            country {
              country
            }
            verificationType
            document {
              _id
              name
              label
            }
            group {
              _id
              label
              name
            }
            groupSetting {
              documents {
                document {
                  _id
                  name
                  label
                }
              }
              group {
                name
                label
                _id
              }
            }
            clientType
            clientStartTime
            clientEndTime
          }
        }
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

export async function getUserVerificationRecordValue({ user }) {
  const variables = {
    filter: {
      user,
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    GET_USER_VERIFICATION_RECORD_VALUE,
    variables,
    false
  );

  return execute;
}

export const GET_USER_VERIFICATION_RECORD_DOCUMENT = gql`
  query getUserVerificationRecordDocument(
    $filter: UserVerificationRecordDocumentFilter
  ) {
    getUserVerificationRecordDocument(filter: $filter) {
      ... on UserVerificationRecordDocumentArray {
        data {
          _id
          status
          group {
            _id
          }
          document {
            _id
          }
        }
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

export async function getUserVerificationRecordDocument({ user }) {
  const variables = {
    filter: {
      user,
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    GET_USER_VERIFICATION_RECORD_DOCUMENT,
    variables,
    false
  );

  return execute;
}

const CREATE_USER_VERIFICATION_RECORD = gql`
  mutation createUserVerificationRecord($input: UserVerificationRecordInput) {
    createUserVerificationRecord(input: $input) {
      ... on UserVerificationRecord {
        recordId
        _id
        title
        status
        duration
        countryName
        clientType
        recordDocument {
          _id
          clientType
          verificationType
          country {
            country
          }
          groupName
          document {
            _id
            name
            label
          }
          group {
            _id
            label
            name
          }
          groupSetting {
            documents {
              document {
                _id
                name
                label
              }
            }
            group {
              name
              label
              _id
            }
          }
          status
          clientStartTime
          clientEndTime
          fieldValue {
            referenceId {
              _id
            }
            field {
              _id
              fieldType
              fieldValue {
                label
                value
              }
              fieldGroup {
                label
                fieldType
                _id
              }
              fieldGroupType
            }
            fieldSetting {
              _id
            }
            value
            status
            hasExpiredDate
            expiredDate
          }
        }
      }
      ... on BaseError {
        errMsg
        errKey
      }
    }
  }
`;

export async function createUserVerificationRecord({
  user,
  countryName,
  clientType,
  device,
  deviceToken,
  browser,
  ip,
}) {
  const variables = {
    input: {
      user,
      countryName,
      clientType,
      device,
      deviceToken,
      browser,
      ip,
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    CREATE_USER_VERIFICATION_RECORD,
    variables,
    true
  );

  return execute;
}

const CANCEL_USER_VERIFICATION_RECORD = gql`
  mutation cancelUserVerificationRecord(
    $input: UserVerificationRecordCancelInput
  ) {
    cancelUserVerificationRecord(input: $input) {
      ... on UserVerificationRecord {
        _id
        clientType
        status
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

export async function cancelUserVerificationRecord({ user, record }) {
  const variables = {
    input: {
      user,
      record,
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    CANCEL_USER_VERIFICATION_RECORD,
    variables,
    true
  );

  return execute;
}

export const GET_USER_VERIFICATION_RECORD = gql`
  query getUserVerificationRecord($filter: UserVerificationRecordFilter) {
    getUserVerificationRecord(filter: $filter) {
      ... on UserVerificationRecordArray {
        data {
          recordId
          _id
          status
          title
          duration
          countryName
          clientType
          comment
          clientStartTime
          clientEndTime
          task {
            _id
          }
          recordDocument {
            _id
            clientType
            verificationType
            country {
              country
            }
            groupName
            document {
              _id
              name
              label
            }
            group {
              _id
              label
              name
            }
            groupSetting {
              documents {
                document {
                  _id
                  name
                  label
                }
              }
              group {
                name
                label
                _id
              }
            }
            status
            clientStartTime
            clientEndTime
            fieldValue {
              _id
              referenceId {
                _id
              }
              field {
                _id
                label
                fieldType
                fieldValue {
                  label
                  value
                }
                fieldGroup {
                  label
                  fieldType
                  _id
                }
                fieldGroupType
              }
              fieldSetting {
                _id
                sortingNumber
              }
              memberId
              value
              valueMobile {
                countryCode
                mobileNo
              }
              status
              isRequired
              hasExpiredDate
              expiredDate
            }
          }
        }
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

export async function getUserVerificationRecord({
  user,
  countryName,
  clientType,
}) {
  const variables = {
    filter: {
      user,
      countryName,
      clientType,
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    GET_USER_VERIFICATION_RECORD,
    variables,
    false
  );

  return execute;
}

const SAVE_USER_VERIFICATION_RECORD = gql`
  mutation saveUserVerificationRecord(
    $input: UserVerificationRecordValueInput
  ) {
    saveUserVerificationRecord(input: $input) {
      ... on UserVerificationRecord {
        _id
        status
        duration
        countryName
        clientType
        recordDocument {
          _id
          clientType
          verificationType
          country {
            country
          }
          groupName
          document {
            _id
            name
            label
          }
          group {
            _id
            label
            name
          }
          groupSetting {
            documents {
              document {
                _id
                name
                label
              }
            }
            group {
              name
              label
              _id
            }
          }
          status
          clientStartTime
          clientEndTime
        }
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

export async function saveUserVerificationRecord({
  record,
  documentRecord,
  user,
  field,
}) {
  const variables = {
    input: {
      record,
      documentRecord,
      user,
      field,
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    SAVE_USER_VERIFICATION_RECORD,
    variables,
    true
  );

  return execute;
}

const COMPLETE_USER_VERIFICATION_RECORD = gql`
  mutation completeUserVerificationRecord($record: String!, $user: String!) {
    completeUserVerificationRecord(record: $record, user: $user) {
      ... on UserVerificationRecord {
        _id
        status
        duration
        countryName
        clientType
        recordDocument {
          _id
          clientType
          verificationType
          country {
            country
          }
          groupName
          document {
            _id
            name
            label
          }
          group {
            _id
            label
            name
          }
          groupSetting {
            documents {
              document {
                _id
                name
                label
              }
            }
            group {
              name
              label
              _id
            }
          }
          status
          clientStartTime
          clientEndTime
        }
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

export async function completeUserVerificationRecord({ record, user }) {
  const variables = {
    record,
    user,
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    COMPLETE_USER_VERIFICATION_RECORD,
    variables,
    true
  );

  return execute;
}
