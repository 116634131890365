import {Layout} from "antd";
import React, {useEffect, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {useRecoilState} from "recoil";
import "./css/style.css";
import {globalColorThemeState} from "../../_common/global_state";
import {useIsMobile} from "../../_common/function";
import {Breadcrumb_5} from "./component/breadcrumb";
import Header_5 from "./component/header";
import Sider_5 from "./component/sider";
import PlatformDownloadModal from "./component/platformDownload";
import {getPlatform} from "../../api/graphql/platformDownload";
import QrModal from "./component/qr";

const Layout_5 = ({kybList, poiList, porList, routes, registerField}) => {
    const location = useLocation();
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const isMobile = useIsMobile(768);

    const [collapsed, setCollapsed] = useState(false);
    const [openQrModal, setOpenQrModal] = useState(false);

    const onCollapsedChange = (d) => {
        if (isMobile) {
            setCollapsed(!!d);
        }
    };

    useEffect(() => {
        if (!isMobile) {
            setCollapsed(false);
        } else {
            setCollapsed(true);
        }
    }, [isMobile]);
    useEffect(() => {
        if (!collapsed && isMobile) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [collapsed, isMobile]);

    const themeClass = colorState === "dark" ? "dark-theme" : "light-theme";

    return (
        <Layout id={"layout-5"} className={themeClass}>
            <Sider_5
                routes={routes}
                collapsed={collapsed}
                onCollapsedChange={onCollapsedChange}
                setOpenQrModal={setOpenQrModal}
                registerField={registerField}
                setCollapsed={setCollapsed}
            />
            <Layout id={"layout-body-5"}>
                <Header_5
                    registerField={registerField}
                    onCollapsedChange={onCollapsedChange}
                    collapsed={collapsed}
                    setOpenQrModal={setOpenQrModal}
                />
                <div className={"layout-outlet container-xxl"}>
                    <Breadcrumb_5/>
                    <Outlet/>
                </div>
                <QrModal
                    isModalOpen={openQrModal}
                    handleCancel={() => setOpenQrModal(false)}
                />
            </Layout>
        </Layout>
    );
};
export default Layout_5;
