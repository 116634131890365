import { Layout, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import "./css/style.css";
import { Header_6 } from "./component/header";
import { Sider_6 } from "./component/sider";
import { Breadcrumb_6 } from "./component/breadcrumb";
import { Footer_6 } from "./component/footer";
import {globalColorThemeState, globalOpenGoLiveModalState} from "../../_common/global_state";
import { useIsMobile } from "../../_common/function";
import QrModal from "./component/qr/qrModal";
import GoLiveModal from "./component/goLive";
import PlatformDownloadModal from "./component/platformDownload/platformDownloadModal";
import {getPlatform} from "../../api/graphql/platformDownload";
import useLayoutFunction from "../function";

const Layout_6 = ({ kybList, poiList, porList, routes, registerField }) => {
  const location = useLocation();
  const {filterAndGroupServers} = useLayoutFunction()
  const [colorState, setColorState] = useRecoilState(globalColorThemeState);
  const isMobile = useIsMobile(768);

  const [collapsed, setCollapsed] = useState(false);
  const [openQrModal, setOpenQrModal] = useState(false);
  const [openGoLiveModal, setOpenGoLiveModal] = useRecoilState(globalOpenGoLiveModalState);
  const [openPlatformModal, setOpenPlatformModal] = useState(false);
  const [platformData, setPlatformData] = useState([]);

  const onCollapsedChange = (d) => {
    if (isMobile) {
      setCollapsed(!!d);
    }
  };

  const preload = async () => {
    const response = await getPlatform();
    const filtered = await filterAndGroupServers(response?.getPlatform?.data);
    setPlatformData(filtered);
  };

  useEffect(() => {
    preload();
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [isMobile]);
  useEffect(() => {
    if (!collapsed && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [collapsed, isMobile]);

  const themeClass = colorState === "dark" ? "dark-theme" : "light-theme";

  return (
    <Layout id={"layout-6"} className={themeClass}>
      <Sider_6
        routes={routes}
        collapsed={collapsed}
        onCollapsedChange={onCollapsedChange}
        setOpenQrModal={setOpenQrModal}
        setOpenGoLiveModal={setOpenGoLiveModal}
        setOpenPlatformModal={setOpenPlatformModal}
        setCollapsed={setCollapsed}
      />
      <Layout id={"layout-body-6"}>
        <Header_6
          registerField={registerField}
          onCollapsedChange={onCollapsedChange}
          setOpenQrModal={setOpenQrModal}
          setOpenGoLiveModal={setOpenGoLiveModal}
          setOpenPlatformModal={setOpenPlatformModal}
        />
        {/* {!(location.pathname === '/dashboard') && (
                        <Breadcrumb_6 kybList={kybList} poiList={poiList} porList={porList} />
                    )} */}
        <div className={"layout-outlet container-xxl"}>
          <Outlet />
        </div>
        {/* <div>HI HI HI</div> */}
        <Footer_6 />
        <QrModal
            isModalOpen={openQrModal}
            handleCancel={() => setOpenQrModal(false)}
        />
        <GoLiveModal
            isModalOpen={openGoLiveModal}
            handleCancel={() => setOpenGoLiveModal(false)}
            registerField={registerField}
        />
        <PlatformDownloadModal
            isModalOpen={openPlatformModal}
            handleCancel={() => setOpenPlatformModal(false)}
            platformData={platformData}
        />
      </Layout>
    </Layout>
  );
};
export default Layout_6;
