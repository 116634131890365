import "./css/style.css";
import {useRecoilValue} from "recoil";
import loaderImageLight from "./assets/loader_light.svg";
import loaderImageDark from "./assets/loader_dark.svg";
import { globalColorThemeState } from "../../../../../../shared/_common/global_state";

export default function RidgewaymarketsLoadingScreen() {
    const colorState = useRecoilValue(globalColorThemeState);
    let loaderImage = colorState === "light" ? loaderImageLight : loaderImageDark

    return (
        <div id={"ridgewaymarketsLoadingScreen"} className={colorState}>
            <div className={"loader"}>
                <img src={loaderImage} />
                {/* <div className={"temp_loader"}></div> */}
            </div>
        </div>
    );
}
