import React from 'react'
import {withLazyComponent} from './lazyComponent'

export const publicRoute = [
    {
        path: '/login',
        element: withLazyComponent(React.lazy(()=>import('../../brand/_auth/login/view')))
    },
    {
        path: '/register',
        element: withLazyComponent(React.lazy(()=>import('../../brand/_auth/register/view')))
    },
    {
        path: '/forgot-password',
        element: withLazyComponent(React.lazy(()=>import('../../brand/_auth/forgotPassword/view')))
    },
    {
        path: '/verify-email',
        element: withLazyComponent(React.lazy(()=>import('../../shared/pages/emailVerify/view')))
    },
]