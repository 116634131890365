import React, {useState} from "react";
import {Button, Col, Divider, Drawer, Layout, Popover, Row} from "antd";
import {useNavigate} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {
    globalBrandConfigState,
    globalBrandModuleSettingState,
    globalIconListState,
    globalNotificationState,
    globalUserDetailState,
    globalWebModuleSettingState,
} from "../../../_common/global_state";
import {useProfileFunction} from "../../../pages/profile/function";
import useShared from "../../../_common/_shared";
import {useTranslation} from "react-i18next";
import {CloseOutlined} from "@ant-design/icons";
import EditNavigationModal_1 from "./editNavigationModal";
import FooterTime from "./latestTime/footerTime";

export const Sider_1 = ({routes}) => {
    const {Sider} = Layout;
    const [openEditNavModal, setOpenEditNavModal] = useState(false);
    const brandConfigState = useRecoilValue(globalBrandConfigState);
    const brandModuleSetting = useRecoilValue(globalBrandModuleSettingState);
    const webModuleSetting = useRecoilValue(globalWebModuleSettingState);
    const userDetails = useRecoilValue(globalUserDetailState);
    const iconListState = useRecoilValue(globalIconListState);
    const navigate = useNavigate();
    const {updateProfile} = useProfileFunction();
    const {IconModule, IconCommon} = useShared();
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(globalNotificationState);

    const [openMorePopover, setIsOpenMorePopOver] = useState(false);

    const uploadImage = async (e) => {
        if (e && e?.file && e?.file.response && e?.file.response[0] && e?.file.response[0].link) {
            await updateProfile(userDetails?._id, {
                profileImage: e?.file.response[0].link.toString(),
            });
        }
    };

    const categories = [
        ...new Set(
            routes.flatMap((route) => {
                if (route?.sub && route?.sub.length > 0 && route?.key === "more") {
                    return route?.sub.map((subRoute) => subRoute?.categoryName);
                }
                return [];
            })
        ),
    ];

    const [openDrawers, setOpenDrawers] = useState([]);

    const openDrawer = (drawerKey) => {
        setOpenDrawers((prevOpenDrawers) => [...prevOpenDrawers, drawerKey]);
    };

    const closeDrawer = (drawerKey) => {
        setOpenDrawers((prevOpenDrawers) => prevOpenDrawers.filter((key) => key !== drawerKey));
    };

    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    return (
        <Sider width={80} id={"layout-sider"} breakpoint="md" collapsedWidth="0">
            {/*<LogoSvg className={"layout-sider-logo"} />*/}
            <div className="sider-wrapper" style={{marginTop: 64}}>
                <div className="sider-fav-container">
                    {routes.map((route) => {
                        if (route?.sub && route?.sub?.length > 0) {
                            const moreContent = (
                                <>
                                    {categories.map((category, index) => (
                                        <Row id={"layout-sider-popover"} key={category}>
                                            {index === 0 ? (
                                                <Divider className="sidebarDivider" style={{display: "none"}} />
                                            ) : (
                                                <Divider className="sidebarDivider" />
                                            )}
                                            <Col span={24} className={"mb-3"}>
                                                <p className={"sub-title"}>
                                                    <strong>{t(`common.${category}`)}</strong>
                                                </p>
                                            </Col>
                                            {route?.sub
                                                .sort((a, b) => {
                                                    const categoryOrder = {
                                                        common: 1,
                                                        finance: 2,
                                                        others: 3,
                                                    };

                                                    const categoryComparison = categoryOrder[a.categoryName] - categoryOrder[b.categoryName];
                                                    if (categoryComparison !== 0) {
                                                        return categoryComparison;
                                                    }

                                                    return categoryOrder[a.key] - categoryOrder[b.key];
                                                })
                                                .filter((subRoute) => subRoute?.categoryName === category)
                                                .map((subRoute) => {
                                                    const filterModule = brandModuleSetting?.filter(
                                                        (item) => item?.isEnabled === true && subRoute?.label === item?.name
                                                    );
                                                    const drawerKey = `${subRoute?.label}`;
                                                    const isDrawerOpen = openDrawers.includes(drawerKey);
                                                    if (filterModule && subRoute?.sub) {
                                                        return (
                                                            <>
                                                                {/*<div key={subRoute?.label}>*/}
                                                                <Col
                                                                    xs={12}
                                                                    className={"layout-sider-popover-btn text-center mb-3"}
                                                                    onClick={() => {
                                                                        openDrawer(drawerKey);
                                                                    }}
                                                                >
                                                                    {subRoute?.icon}
                                                                    {/*<p className={"mt-1 sidebarLabel"}*/}
                                                                    {/*   style={{margin: '0'}}>{t(moduleTranslation(subRoute?.label))}</p>*/}
                                                                </Col>
                                                                <Drawer
                                                                    key={drawerKey}
                                                                    id="drawerContainer"
                                                                    onClose={() => closeDrawer(drawerKey)}
                                                                    open={isDrawerOpen}
                                                                    className={"popoverDrawer"}
                                                                    getContainer={false}
                                                                    width={225}
                                                                    title={
                                                                        <Row justify={"end"}>
                                                                            <Button type={"link"} onClick={() => closeDrawer(drawerKey)}>
                                                                                <CloseOutlined />
                                                                            </Button>
                                                                        </Row>
                                                                    }
                                                                    closeIcon={false}
                                                                >
                                                                    <Row className={"text-center"}>
                                                                        {subRoute?.sub?.map((subSubRoute) => {
                                                                            return (
                                                                                <Col
                                                                                    className={"mb-3"}
                                                                                    style={{cursor: "pointer"}}
                                                                                    key={subSubRoute?.label}
                                                                                    xs={12}
                                                                                    // lg={6}
                                                                                    onClick={() => {
                                                                                        subSubRoute?.type === "_blank"
                                                                                            ? window.open(subSubRoute?.path, "_blank")
                                                                                            : navigate(subSubRoute?.path);
                                                                                    }}
                                                                                >
                                                                                    {subSubRoute?.label}
                                                                                    <IconModule
                                                                                        iconName={`md-${subSubRoute?.label
                                                                                            ?.replaceAll(" ", "")
                                                                                            ?.replaceAll(".", "")
                                                                                            ?.toLowerCase()}`}
                                                                                        className={"sidebarIcon"}
                                                                                        title={subSubRoute?.label
                                                                                            .replace(".", "")
                                                                                            .replace(/[-\s+]/g, "_")
                                                                                            .toLowerCase()}
                                                                                        layout={1}
                                                                                    />
                                                                                    {/*<p className={"mt-1 sidebarLabel"}*/}
                                                                                    {/*   style={{*/}
                                                                                    {/*       margin: '0',*/}
                                                                                    {/*       wordWrap: 'break-word'*/}
                                                                                    {/*   }}>*/}
                                                                                    {/*    {t(moduleTranslation(subSubRoute?.label))}</p>*/}
                                                                                </Col>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                </Drawer>
                                                                {/*</div>*/}
                                                            </>
                                                        );
                                                    } else {
                                                        return (
                                                            <Col
                                                                key={subRoute?.label}
                                                                xs={12}
                                                                className={"layout-sider-popover-btn text-center mb-3"}
                                                                onClick={() => {
                                                                    subRoute?.type === "_blank"
                                                                        ? window.open(subRoute?.path, "_blank")
                                                                        : navigate(subRoute?.path);
                                                                    setIsOpenMorePopOver(false);
                                                                }}
                                                            >
                                                                {subRoute?.icon}
                                                                {/*<p className={"mt-1 sidebarLabel"}*/}
                                                                {/*   style={{margin: '0'}}>{t(moduleTranslation(subRoute?.label))}</p>*/}
                                                            </Col>
                                                        );
                                                    }
                                                })}
                                        </Row>
                                    ))}

                                    {/* {route?.key === "more" && (
                    <Col span={24} className={"text-center editNavigation"}>
                      <Divider
                        style={{
                          margin: "0.2rem 0",
                          background: "var(--divider-color)",
                        }}
                      />
                      <Button
                        type={"link"}
                        className={""}
                        onClick={() => {
                          setOpenEditNavModal(true);
                          setIsOpenMorePopOver(false);
                        }}
                      >
                        {t("common.editNavigation")}
                      </Button>
                    </Col>
                  )} */}
                                </>
                            );

                            const content = (
                                <div id={"layout-sider-popover"} className="popover-select-item-list">
                                    {/*<Divider style={{margin: "0.2rem 0", background: "var(--divider-color)"}}/>*/}
                                    {route?.sub?.map((subRoute) => {
                                        return (
                                            <Button
                                                type="text"
                                                key={subRoute?.label}
                                                xs={24}
                                                className={`layout-sider-popover-btn text-center `}
                                                onClick={() => {
                                                    subRoute?.type === "_blank" ? window.open(subRoute?.path, "_blank") : navigate(subRoute?.path);
                                                }}
                                            >
                                                <IconModule
                                                    iconName={`md-${subRoute?.label?.replaceAll(" ", "")?.replaceAll(".", "").replaceAll("-", "")?.toLowerCase()}`}
                                                    layout={1}
                                                />
                                                <p className={"ms-2"}>
                                                    {t(`module.${subRoute?.label?.replaceAll(" ", "_")?.replaceAll(".", "")?.toLowerCase()}`)}
                                                </p>
                                            </Button>
                                        );
                                    })}
                                </div>
                            );

                            return (
                                <>
                                    {route?.key === "more" ? null : (
                                        <Popover
                                            // open={true}
                                            destroyTooltipOnHide={true}
                                            content={content}
                                            placement={"left"}
                                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                        >
                                            <Col span={24} className={"text-center mb-3"} style={{cursor: "pointer"}}>
                                                <IconModule
                                                    iconName={`md-${route?.label?.replaceAll(" ", "")?.replaceAll(".", "").replaceAll("-", "")?.toLowerCase()}`}
                                                    layout={1}
                                                />
                                                <p className={"mt-1"}>
                                                    {t(`module.${route?.label?.replaceAll(" ", "_")?.replaceAll(".", "")?.toLowerCase()}`)}
                                                </p>
                                                {/*<p style={{padding: "0 0.75rem"}}*/}
                                                {/*   className={"mt-1 sidebarLabel"}>{t(moduleTranslation(route?.label))}</p>*/}
                                            </Col>
                                        </Popover>
                                    )}
                                </>
                            );
                        } else {
                            return (
                                <Col
                                    span={24}
                                    className={"text-center mb-3"}
                                    key={route?.label}
                                    onClick={() => {
                                        route?.type === "_blank" ? window.open(route?.path, "_blank") : navigate(route?.path);
                                    }}
                                    style={{cursor: "pointer"}}
                                >
                                    <IconModule iconName={`md-${route?.label?.replaceAll(" ", "")?.replaceAll(".", "").replaceAll("-", "")?.toLowerCase()}`} layout={1} />
                                    {/* {route?.icon} */}
                                    {/* {t(moduleTranslation(route?.label))} */}
                                    <p className={"mt-1"}>
                                        {t(
                                            `module.${route?.label
                                                ?.replace(".", "")
                                                ?.replace(/[-\s+]/g, "_")
                                                .toLowerCase()}`
                                        )}
                                    </p>
                                    {/* <p style={{padding: "0 0.75rem"}} className={"mt-1"}>{t(moduleTranslation(route?.label))}</p> */}
                                </Col>
                            );
                        }
                    })}
                </div>
                <div>
                    {/*<div className="navItem">*/}
                    {/*    <IconModule className={"footerIcon"} iconName={"md-support"} layout={1} />*/}
                    {/*    <span className={"footerText"}>{t("module.support")}</span>*/}
                    {/*</div>*/}
                    <div className="navItem">
                        <IconCommon className={"footerIcon"} iconName={"cm-time-regular"} />
                        <FooterTime />
                    </div>
                </div>
            </div>

            <EditNavigationModal_1 isModalOpen={openEditNavModal} handleCancel={() => setOpenEditNavModal(false)} />
        </Sider>
    );
};

function LogoSvg({className}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="none" className={className}>
            <path
                d="M12.0909 32L0 15.0439V10.2539H6.71933L14.0181 20.4906C16.6642 24.2012 15.8015 29.3539 12.0909 32ZM18.5162 18.5055H15.0504L9.11005 10.2539H26.7679C26.7679 14.81 23.0745 18.5055 18.5162 18.5055ZM29.5642 1.45074C29.5642 5.20635 26.5189 8.25162 22.7633 8.25162H0V6.80088C0 3.04527 3.04527 0 6.80088 0H29.5642V1.45074Z"
                fill="#2748D6"
            />
        </svg>
    );
}
