import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  globalBrandModuleSettingState,
  globalColorThemeState,
  globalIconListState,
  globalNotificationState,
  globalUserDetailState,
  globalUserWalletState,
} from "./global_state";
import { LoadingOutlined } from "@ant-design/icons";
import { getEwalletById } from "../api/graphql/eWallet";
import { moduleTranslation } from "./function";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

function useShared() {
  const userDetail = useRecoilValue(globalUserDetailState);
  const iconListState = useRecoilValue(globalIconListState);
  const colorState = useRecoilValue(globalColorThemeState);
  const brandModuleSetting = useRecoilValue(globalBrandModuleSettingState);
  const setUserWalletState = useSetRecoilState(globalUserWalletState);
  const setNotificationMessage = useSetRecoilState(globalNotificationState);
  const { t } = useTranslation();

  const getIconImage = (name, isActive = false, isDefault = true) => {
    const obj = iconListState.find((item) => item?.name === name);
    if (obj) {
      const color = colorState === "dark" ? "Dark" : "Light";
      const active = isActive === true ? "Active" : "Inactive";
      const defaultIcon = isDefault === true ? "defaultIcon" : "icon";
      return obj[`${defaultIcon}${color}${active}`][0]?.path;
    } else {
      return false;
    }
  };

  const IconCommon = ({
    iconName,
    onClick,
    className,
    isAlwaysActive,
    style,
    title,
    isModule,
    isAlwaysInActive,
    tooltipText = "",
  }) => {
    const colorState = useRecoilValue(globalColorThemeState);
    const color = colorState === "dark" ? "Dark" : "Light";
    const [isCommonHovered, setIsCommonHovered] = useState(false);
    const activeSuffix =
      (isCommonHovered || isAlwaysActive) && !isAlwaysInActive
        ? "Active"
        : "Inactive";
    const filterIcon = iconListState.find((item) => item?.name === iconName);
    const iconPath =
      filterIcon === undefined ||
      filterIcon[`icon${color}${activeSuffix}`] === null
        ? null
        : filterIcon[`icon${color}${activeSuffix}`][0]?.path;

    const onMouseEnter = () => setIsCommonHovered(true);
    const onMouseLeave = () => setIsCommonHovered(false);

    if (iconPath !== "") {
      return (
        <Tooltip title={tooltipText}>
          <div
            className="iconContainer"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{ height: "100%" }}
          >
            <img
              style={style}
              className={className}
              onClick={onClick}
              src={iconPath}
            />
            {title && (
              <p className={"mt-1 text-center"}>
                {t(isModule ? `module.${title}` : `common.${title}`)}
              </p>
            )}
          </div>
        </Tooltip>
      );
    } else {
      return <LoadingOutlined />;
    }
  };

  const IconModule = ({
    iconName,
    className,
    title,
    active = true,
    isAlwaysActive,
    layout,
  }) => {
    const colorState = useRecoilValue(globalColorThemeState);
    const color = colorState === "dark" ? "Dark" : "Light";
    const [isModuleHovered, setIsModuleHovered] = useState(false);
    const newNaming = layout === 6 || layout === 5 || layout === 3 || layout === 1;

    const activeSuffix = (isModuleHovered && active) || isAlwaysActive ? "Active" : "Inactive";
    const filterIcon = (newNaming ? iconListState : brandModuleSetting)?.find(
      (item) => item?.name === iconName
    );

    const iconPath =
      filterIcon === undefined ||
      filterIcon[`icon${color}${activeSuffix}`] === null
        ? null
        : filterIcon[`icon${color}${activeSuffix}`][0]?.path;
    const onMouseEnter = () => setIsModuleHovered(true);
    const onMouseLeave = () => setIsModuleHovered(false);

    if (iconPath !== "") {
      return (
        <div
          className="iconContainer"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={{ height: "100%" }}
        >
          <img className={className} src={iconPath} />
          {title && <p className={"mt-1"}>{t(`module.${title}`)}</p>}
        </div>
      );
    } else {
      return <LoadingOutlined />;
    }
  };

  const IconFlag = ({
    countryCode,
    onClick,
    className,
    isAlwaysActive,
    style,
    title,
    isModule,
    isAlwaysInActive,
  }) => {
    const colorState = useRecoilValue(globalColorThemeState);
    const color = colorState === "dark" ? "Dark" : "Light";
    const [isCommonHovered, setIsCommonHovered] = useState(false);
    const activeSuffix =
      (isCommonHovered || isAlwaysActive) && !isAlwaysInActive
        ? "Active"
        : "Inactive";
    const filterIcon = iconListState.find(
      (item) => item?.countryCode === countryCode
    );
    const iconPath =
      filterIcon === undefined ||
      filterIcon[`icon${color}${activeSuffix}`] === null
        ? null
        : filterIcon[`icon${color}${activeSuffix}`][0]?.path;

    const onMouseEnter = () => setIsCommonHovered(true);
    const onMouseLeave = () => setIsCommonHovered(false);

    if (iconPath !== "") {
      return (
        <div
          className="iconContainer"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={{ height: "100%" }}
        >
          <img
            style={style}
            className={className}
            onClick={onClick}
            src={iconPath}
          />
          {title && (
            <p className={"mt-1 text-center"}>
              {t(isModule ? `module.${title}` : `common.${title}`)}
            </p>
          )}
        </div>
      );
    } else {
      return <LoadingOutlined />;
    }
  };

  const convertLanguageFile = (record) => {
    let resources = {};
    record.forEach((item) => {
      resources[item?.code] = { translation: item?.translationFile };
    });

    return resources;
  };

  const updateEwallet = async () => {
    try {
      const eWallet = await getEwalletById(userDetail?.eWallet?._id);
      await setUserWalletState(eWallet?.getEWalletByID);
    } catch (e) {
      console.log("Error:", e);
      setNotificationMessage({
        status: "error",
        title: "",
        msg: "System Error",
        key: "systemNotification",
      });
    }
  };

  return {
    getIconImage,
    IconModule,
    IconCommon,
    IconFlag,
    convertLanguageFile,
    updateEwallet,
  };
}

export default useShared;
