import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";


const GET_BANK_INFORMATION = gql`
    query getBankInformation($filter: BankInformationInput, $limit: Int, $offset: Int, $orderBy: String) {
        getBankInformation(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ...on BankInformationArray {
                data {
                    _id
                    user {_id}
                    psp {
                        _id
                        name
                        displayName
                    }
                    formData
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getBankInfo(userid) {

    const variables = {
        filter: {
            user:userid
        },
        limit: null,
        offset: null,
        orderBy: null,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_BANK_INFORMATION, variables, false);

    return execute;
}

const UPDATE_BANK_INFORMATION = gql`
    mutation updateBankInformation($input: BankInformationInput){
        updateBankInformation(input: $input){
            ... on BankInformation {
                _id
                user { _id }
                psp {
                    _id
                    name
                }
                formData
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`

export async function updateBankInfo(input) {

    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_BANK_INFORMATION, variables, true);

    return execute;
}

const GET_PSP_SETTING_FIELD = gql`
    query getPspSettingField( $filter: PspSettingFieldInput, $limit: Int, $offset: Int, $orderBy: String ) {
        getPspSettingField( filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy ) {
            ...on PspSettingFieldArray {
                data {
                    _id
                    id
                    pspSetting {
                        _id
                        name
                        displayName
                        description
                        enabled
                        depositEnabled
                        withdrawalEnabled
                    }
                    fieldName
                    fieldType
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
        }
    }

`;

export async function getPspSettingField() {

    const variables = {
        filter: null,
        limit: null,
        offset: null,
        orderBy: null,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_PSP_SETTING_FIELD, variables, false);

    return execute;
}