import {Button, DatePicker, Input, InputNumber, Row, Select, Space,} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
// import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {formatDate} from "../../_common/function";

const {Search} = Input;
const {RangePicker} = DatePicker;

const useReportingFilter = () => {
    const {t} = useTranslation()

    const getReportColumnSearchProps = (filter, reportFieldName) => {
        const filteredValue = filter?.find((f) => f?.fieldName === reportFieldName)?.value || null
        return {
            filteredValue: filteredValue,
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
                return (
                    <div style={{padding: 8}}>
                        <Search
                            placeholder={t("common.search")}
                            value={selectedKeys}
                            onChange={(e) => {
                                setSelectedKeys([e?.target?.value]);
                            }}
                            onPressEnter={() => confirm()}
                            onSearch={() => confirm()}
                            style={{width: 150, marginBottom: 8, display: "block"}}
                            className={"report-filter-search"}
                        />
                        <Button
                            onClick={() => {
                                setSelectedKeys([]);
                                confirm();
                            }}
                            style={{width: 90, display: " block", marginLeft: "auto"}}
                        >
                            {t("common.reset")}
                        </Button>
                    </div>
                )
            },
            filterIcon: (filtered) => (
                <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
            ),
        };
    };

    const getReportBooleanFilterProps = (filter, reportFieldName) => {
        const filteredValue = filter?.find((f) => f?.fieldName === reportFieldName)?.value || null
        return {
            filteredValue: filteredValue,
            filters: [
                {text: t("common.true"), value: true},
                {text: t("common.false"), value: false},
            ],
            filterMultiple: false,
        };
    };

    let num1,
        num2,
        op1 = ">",
        op2 = "<";
    const getReportDigitsFilterProps = (min, max, step, filter, reportFieldName) => {
        const filteredValue = filter?.find((f) => f?.fieldName === reportFieldName)?.value || null
        return {
            filteredValue: filteredValue,
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => (
                <Space direction={"vertical"} style={{padding: 8}}>
                    <Space.Compact block>
                        <Select
                            options={[
                                {value: ">", label: "Greater than"},
                                {value: ">=", label: "Greater than or equal to"},
                                {value: "<", label: "Less than"},
                                {value: "<=", label: "Less than or equal to"},
                                {value: "=", label: "Equal to"},
                                {value: "!=", label: "Not equal to"},
                            ]}
                            defaultValue={op1}
                            onSelect={(value) => (op1 = value)}
                            style={{width: 200, textAlign: "center"}}
                        />
                        <InputNumber
                            className="site-input-left"
                            min={min}
                            max={max}
                            step={step}
                            value={selectedKeys}
                            onChange={(value) => (num1 = value)}
                            style={{width: 50}}
                        />
                    </Space.Compact>
                    <Space.Compact block className={"margin-bottom-0-75"}>
                        <Select
                            options={[
                                {value: ">", label: "Greater than"},
                                {value: ">=", label: "Greater than or equal to"},
                                {value: "<", label: "Less than"},
                                {value: "<=", label: "Less than or equal to"},
                                {value: "=", label: "Equal to"},
                                {value: "!=", label: "Not equal to"},
                            ]}
                            defaultValue={op2}
                            onSelect={(value) => (op2 = value)}
                            style={{width: 200, textAlign: "center"}}
                        />
                        <InputNumber
                            className="site-input-right"
                            min={min}
                            max={max}
                            step={step}
                            value={selectedKeys}
                            onChange={(value) => (num2 = value)}
                            style={{width: 50}}
                        />
                    </Space.Compact>
                    <Row justify={"space-between"}>
                        <Button
                            type={"link"}
                            onClick={() => {
                                num1 = null
                                num2 = null
                                op1 = ""
                                op2 = ""
                                setSelectedKeys([]);
                                confirm();
                            }}
                        >
                            {t("common.reset")}
                        </Button>
                        <Button
                            onClick={() => {
                                const filterValue = op2
                                    ? `${op1} ${num1} ${op2} ${num2}`
                                    : `${op1} ${num1}`;
                                setSelectedKeys([filterValue]);
                                confirm();
                            }}
                        >
                            {t("common.confirm")}
                        </Button>
                    </Row>
                </Space>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
            ),
        };
    };

    const getReportDigitsExactFilterProps = (min, max, step, filter, reportFieldName) => {
        const filteredValue = filter?.find((f) => f?.fieldName === reportFieldName)?.value || null
        return {
            filteredValue: filteredValue,
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => (
                <Space direction={"vertical"} style={{padding: 8}}>
                    <InputNumber
                        min={min}
                        max={max}
                        step={step}
                        value={selectedKeys}
                        onChange={(value) => setSelectedKeys([value?.toString()])}
                        style={{width: 150}}
                    />
                    <Row justify={"space-between"}>
                        <Button
                            type={"link"}
                            onClick={() => {
                                setSelectedKeys([]);
                                confirm();
                            }}
                        >
                            {t("common.reset")}
                        </Button>
                        <Button
                            onClick={() => {
                                confirm();
                            }}
                        >
                            {t("common.confirm")}
                        </Button>
                    </Row>
                </Space>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
            ),
        };
    };

    const getReportDateRangeFilterProps = (filter, reportFieldName) => {
        let filtered;
        const filteredValue = filter?.find((f) => f?.fieldName === reportFieldName)?.value || null
        const rangePresets = [
            // {label: t("common.today"), value: [dayjs().add(0, "d"), dayjs()]},
            // {label: t("common.yesterday"), value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")]},
            // {label: t("date.last7Days"), value: [dayjs().add(-7, "d"), dayjs()]},
            // {label: t("date.last14Days"), value: [dayjs().add(-14, "d"), dayjs()]},
            // {label: t("date.last30Days"), value: [dayjs().add(-30, "d"), dayjs()]},
            {label: t("common.today"), value: [formatDate().add(0, "d"), formatDate()]},
            {label: t("common.yesterday"), value: [formatDate().add(-1, "d"), formatDate().add(-1, "d")]},
            {label: t("date.last7Days"), value: [formatDate().add(-7, "d"), formatDate()]},
            {label: t("date.last14Days"), value: [formatDate().add(-14, "d"), formatDate()]},
            {label: t("date.last30Days"), value: [formatDate().add(-30, "d"), formatDate()]},
            {
                label: t("date.thisMonth"),
                // value: [dayjs().startOf("month"), dayjs().endOf("month")],
                value: [formatDate().startOf("month"), formatDate().endOf("month")],
            },
            {
                label: t("date.lastMonth"),
                value: [
                    // dayjs().subtract(1, "month").startOf("month"),
                    // dayjs().subtract(1, "month").endOf("month"),
                    formatDate().subtract(1, "month").startOf("month"),
                    formatDate().subtract(1, "month").endOf("month"),
                ],
            },
        ];

        return {
            filteredValue: filteredValue,
            filterDropdown: ({setSelectedKeys, _, confirm}) => (
                <Space direction={"vertical"} style={{padding: 8}}>
                    <RangePicker
                        presets={rangePresets}
                        // onChange={(dates: any | (Dayjs | null)[], dateStrings: string[]) =>
                        onChange={(dates, dateStrings) =>
                            setSelectedKeys(["date", dateStrings])
                        }
                    />
                    <Row justify={"space-between"}>
                        <Button
                            onClick={() => {
                                confirm();
                                filtered = true;
                            }}
                        >
                            {t("common.confirm")}
                        </Button>
                        <Button
                            type={"link"}
                            onClick={() => {
                                setSelectedKeys(["date", "", ""]);
                                filtered = false;
                                confirm();
                            }}
                        >
                            {t("common.reset")}
                        </Button>
                    </Row>
                </Space>
            ),
            filterIcon: () => (
                <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
            ),
        };
    };

    return {
        getReportColumnSearchProps,
        getReportBooleanFilterProps,
        getReportDigitsFilterProps,
        getReportDigitsExactFilterProps,
        getReportDateRangeFilterProps,
    };
}

export default useReportingFilter