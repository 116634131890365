import useShared from "../../../../../shared/_common/_shared";

export default function CronoscapEmptyData(props) {
    const {title} = props;
    const {IconCommon} = useShared();
    return (
        <>
            <IconCommon
                isAlwaysInActive={true}
                iconName={"cm-empty-regular"}
                className={"empty-data"}
                title={title || "noData"}
            />
        </>
    );
}
