import axios from "axios";

export async function getOpportunities(id, keyword, baseSymbolId, rows) {
    const lang = localStorage.getItem("language");
    let languageForCulture;
    switch (lang) {
        case "en":
            languageForCulture = "EN";
            break;
        case "vn":
            languageForCulture = "VI";
            break;
        case "cn":
        case "zh-H":
            languageForCulture = "ZH_CN";
            break;
        case "ms":
            languageForCulture = "MS";
            break;
        case "th":
            languageForCulture = "TH";
            break;
        default:
            languageForCulture = "EN";
            break;
    }
    let params = {
        culture: languageForCulture,
        // type_product: stock || "",
        baseSymbolId: baseSymbolId,
        page: 1,
        rows: rows || null,
    };

    if (id) {
        params["user_id"] = id;
    }
    if (keyword && keyword !== "") {
        params["keyword"] = keyword;
    }

    try {
        const response = await axios.get("https://nextrades.ai/tc/trade-ideas", {params});
        return response?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}
