import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import {
  globalBrandConfigState,
  globalBrandModuleSettingState,
  globalCurrentPathState,
  globalIconListState,
  globalNotificationState,
  globalUserDetailState,
  globalWebModuleSettingState,
} from "../_common/global_state";
import { getAvailableKyc } from "../api/graphql/kyc";
import useShared from "../_common/_shared";
import getSidebarRoute from "../router/sidebarRoute";
import { getRegistrationSettingQuery } from "../api/graphql/auth";
import Layout_1 from "./layout_1";
import Layout_2 from "./layout_2";
import Layout_4 from "./layout_4";
import Layout_6 from "./layout_6";
import useLayoutFunction from "./function";
import Layout_5 from "./layout_5";
import Layout_3 from "./layout_3";

export default function Layouts() {
  const navigate = useNavigate();
  const hash = window.location.hash;
  const currentPath = hash.substring(1);
  const [kybList, setKybList] = useState([]);
  const [poiList, setPoiList] = useState([]);
  const [porList, setPorList] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [registerField, setRegisterField] = useState([]);
  const [navMap, setNavMap] = useState({});
  const webModuleSetting = useRecoilValue(globalWebModuleSettingState);
  const brandModuleSetting = useRecoilValue(globalBrandModuleSettingState);
  const iconList = useRecoilValue(globalIconListState);
  const brandConfigState = useRecoilValue(globalBrandConfigState);
  const userDetails = useRecoilValue(globalUserDetailState);
  const setNotificationMessage = useSetRecoilState(globalNotificationState);
  const globalPathState = useRecoilValue(globalCurrentPathState);
  const { IconCommon, IconModule } = useShared();
  const { parseIconName } = useLayoutFunction();

  const hideSidebarChildren = [4, 5, 6].includes(
    brandConfigState?.defaultLayout
  );

  // const shouldExtendIconName = brandConfigState?.defaultLayout === 6;
  // const parseIconName = (iconName = "", type) => {
  //   if (shouldExtendIconName) {
  //     return `${type}-${iconName?.replaceAll(" ", "-")?.replaceAll(".", "").toLowerCase()}-regular`;
  //   }
  //   return iconName;
  // };

  const preloadKyc = async () => {
    try {
      const kyc = await getAvailableKyc(userDetails?._id, "kyc");
      let poi = [];
      let por = [];

      kyc?.getAvailableKyc?.data?.map((k) => {
        if (k?.kycType === "poi") {
          poi.push(k);
        } else if (k?.kycType === "por") {
          por.push(k);
        }
      });

      setPorList(por);
      setPoiList(poi);
    } catch (e) {
      setNotificationMessage({
        status: "error",
        title: "",
        msg: "Apologies, we are currently undergoing maintenance for the account verification page. We're actively working to resolve the issue.",
        key: "systemNotification",
      });
    }
  };

  const preloadKyb = async () => {
    try {
      const kyc = await getAvailableKyc(userDetails?._id, "kyb");
      setKybList(kyc?.getAvailableKyc?.data);
    } catch (e) {
      setNotificationMessage({
        status: "error",
        title: "",
        msg: "Apologies, we are currently undergoing maintenance for the account verification page. We're actively working to resolve the issue.",
        key: "systemNotification",
      });
    }
  };

  const preloadRoute = async () => {
    const setting = await getSidebarRoute(
      brandConfigState,
      brandModuleSetting,
      webModuleSetting,
      hideSidebarChildren
    );

    const childNavs = brandModuleSetting.filter((d) => d.parent && d.isEnabled);

    //{child:parent}
    const navMap = {};
    childNavs.forEach((n) => {
      navMap[n.name] = n.parent?.name;
    });
    setNavMap(navMap);

    const newSetting = await Promise.all(
      setting.map((item) => {
        let newItem = item;
        if (newItem?.sub?.length > 0) {
          const newSub = newItem.sub.map((sub) => {
            return {
              ...sub,
              icon: (
                <IconModule
                  layout={brandConfigState?.defaultLayout}
                  isAlwaysActive={globalPathState === sub.path}
                  className={"sidebarIcon"}
                  iconName={parseIconName(
                    sub.key,
                      sub.key === "Wallet" &&
                      !([5, 6].includes(brandConfigState?.defaultLayout))
                          ? "cm"
                          : "md",
                    brandConfigState?.defaultLayout
                  )}
                  title={sub.label
                    .replace(".", "")
                    .replace("/", "")
                    .replace(/[-\s+]/g, "_")
                    .toLowerCase()}
                />
              ),
            };
          });
          newItem.sub = newSub;
        }

        // if(newItem?.sub?.length > 0){
        //     const getSubItem = newItem.sub.map((subItem)=>{
        //         if(subItem?.sub?.length > 0){
        //             subItem?.sub.map((subSubItem) =>{
        //                 return{
        //                     ...subSubItem,
        //                     icon: <IconCommon className={"sidebarIcon"} iconName={subSubItem.key}/>,
        //                 }
        //             })
        //         }
        //     })
        // }

        if (
          (newItem.key === "home" || newItem.key === "more") &&
            !(brandConfigState?.defaultLayout === 1 || brandConfigState?.defaultLayout === 5)
        ) {
          return {
            ...newItem,
            icon: (
              <IconCommon
                className={"sidebarIcon"}
                iconName={parseIconName(
                  newItem?.key,
                  "cm",
                  brandConfigState?.defaultLayout
                )}
                title={newItem.label
                  .replace(".", "")
                  .replace(/[-\s/+]/g, "_")
                  .toLowerCase()}
                isAlwaysActive={globalPathState === newItem.path}
                isModule={true}
              />
            ),
          };
        } else {
          return {
            ...newItem,
            icon: (
              <IconModule
                layout={brandConfigState?.defaultLayout}
                className={"sidebarIcon"}
                iconName={parseIconName(
                  newItem?.key,
                  "md",
                  brandConfigState?.defaultLayout
                )}
                isAlwaysActive={globalPathState === newItem.path}
                title={newItem.label
                  .replace(".", "")
                  .replace(/[-\s/+]/g, "_")
                  .toLowerCase()}
              />
            ),
          };
        }
      })
    );

    setRoutes(newSetting);
  };

  const preloadRegisterLiveField = async () => {
    if (userDetails?.clientAccountType === "demo") {
      const response = await getRegistrationSettingQuery("live");
      setRegisterField(response?.getRegistrationSetting?.data);
    }
  };

  useEffect(() => {
    preloadRegisterLiveField();
  }, [userDetails]);

  useEffect(() => {
    preloadRoute();
  }, [
    brandConfigState,
    webModuleSetting,
    iconList,
    brandModuleSetting,
    globalPathState,
  ]);

  let component;
  const props = {
    kybList: kybList,
    poiList: poiList,
    porList: porList,
    routes: routes,
    registerField: registerField,
    navMap,
  };

  switch (brandConfigState?.defaultLayout) {
    case 1:
      component = <Layout_1 {...props} />;
      break;

    case 2:
      component = <Layout_2 {...props} />;
      break;

    case 3:
      component = <Layout_3 {...props} />;
      break;

    case 4:
      component = <Layout_4 {...props} />;
      break;

    case 5:
      component = <Layout_5 {...props} />;
      break;

    case 6:
      component = <Layout_6 {...props} />;
      break;

    default:
      component = <Layout_2 {...props} />;
      break;
  }

  return component;
}
