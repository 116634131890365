import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_AVAILABLE_KYC = gql`
    query getAvailableKyc($user: String!, $verificationType: String) {
        getAvailableKyc(user: $user, verificationType: $verificationType) {
            ... on UserAvailableKycAry {
                data {
                    _id
                    kycField {
                        _id
                        label
                        fieldName
                        fieldType
                    }
                    country
                    kycType
                    verificationType
                    isRequired
                    isEnabled
                    hasExpiredDate
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`

export async function getAvailableKyc(user, verificationType) {

    const variables = {
        user: user,
        verificationType: verificationType,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_AVAILABLE_KYC, variables, false);

    return execute;
}

const BATCH_ADD_USER_KYC = gql`
    mutation batchAddUserKyc($input: [UserKycInput],$verificationType:String) {
        batchAddUserKyc(input: $input,verificationType:$verificationType) {
            ...on UserApprovedKycAry{
                data {
                    _id
                    user {_id}
                    kycField {label}
                    kycSetting {_id}
                    value
                    expiredDatetime
                    isPassed
                    isNotifyExpireSoon
                    status
                    comment
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`;

export async function batchAddUserKyc(input,verificationType) {

    const variables = {
        input: input,
        verificationType:verificationType,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(BATCH_ADD_USER_KYC, variables, true);
    return execute;
}

const GET_USER_KYC_LIST = gql`
    query getUserKycList(
        $user: String!
        $filter: UserKycInput
        $limit: Int
        $offset: Int
        $orderBy: String,
        $startDate: String,
        $endDate: String,
        $dateKey: String
    ) {
        getUserKycList(
            user: $user
            filter: $filter
            limit: $limit
            offset: $offset
            orderBy: $orderBy
            startDate: $startDate,
            endDate: $endDate,
            dateKey: $dateKey
        ) {
            ... on UserApprovedKycAry {
                data {
                    _id
                    user {
                        username
                    }
                    kycField {
                        fieldName
                        fieldType
                        label
                    }
                    kycSetting {
                        verificationType
                        kycType
                        hasExpiredDate
                        isRequired
                        isEnabled
                    }
                    value
                    expiredDatetime
                    status
                    comment
                    createdAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getKycList(user) {

    const variables = {
        user: user,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_USER_KYC_LIST, variables, false);
    return execute;
}

const GET_USER_KYC_MEMBER_LIST = gql`
    query getUserKycMemberList(
        $user: String!
        $status:String
    ) {
        getUserKycMemberList(
            user: $user
            status: $status
        ) {
            ... on UserApprovedKycAry {
                data {
                    _id
                    user {
                        username
                    }
                    kycField {
                        fieldName
                        fieldType
                        label
                    }
                    kycSetting {
                        verificationType
                        kycType
                        hasExpiredDate
                        isRequired
                        isEnabled
                    }
                    value
                    expiredDatetime
                    status
                    comment
                    memberUserKyc{
                        _id
                    }
                    memberId
                    isMemberField
                    createdAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getUserKycMemberList(user) {

    const variables = {
        user: user,
        status:"approved",
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_USER_KYC_MEMBER_LIST, variables, false);
    return execute;
}