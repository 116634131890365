import {ApolloClientQuery} from "./_setup";
import {gql} from "@apollo/client";

const UPDATE_USER_CLAIM_MISSION = gql`
mutation UpdateUserClaimMission($input: UserClaimMissionInput, $id: String!) {
  updateUserClaimMission(input: $input, id: $id) {
    ... on BaseError {
      errKey
      errMsg
      errObj
    }
    ... on CrmTaskApproval {
      msg
    }
    ... on UserClaimMission {
      _id
      id
      missionGroupId {
        _id
        name
      }
      missionId {
        _id
        displayName {
          language
          content
        }
      }
      userVoucher {
        _id
        id
        name
        label
        description
        isDeleted
        isEnabled
        image {
          fileList
          path
        }
      }
      isMissionExpired
      isMissionCompleted
      isVoucherIssued
      user {
        _id
        username
      }
      tradingAccount {
        _id
        accountId
      }
      missionTarget
      missionClaimedAt
      isDeleted
    }
  }
}
`;

export async function updateUserClaimMission(id, input) {
    const variables = {
        input: input,
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_CLAIM_MISSION, variables, true);

    return execute;
}

const DELETE_USER_CLAIM_MISSION = gql`
mutation DeleteUserClaimMission($id: String!) {
  deleteUserClaimMission(id: $id) {
    ... on BaseError {
      errKey
      errMsg
      errObj
    }
    ... on CrmTaskApproval {
      msg
    }
    ... on UserClaimMission {
      _id
      isDeleted
    }
  }
}
`;

export async function deleteUserClaimMission(id) {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_USER_CLAIM_MISSION, variables, true);

    return execute;
}

const GET_REPORTING_FIELD = gql`
query GetReportingFields {
    getReportingFields {
        ... on ReportingDataAry {
            data
            total
            res
        }
        ... on BaseError {
            errKey
            errMsg
            errObj
        }
    }
}`

const REFRESH_USER_CLAIM_MISSION = gql`
mutation refreshUserClaimMission($userid: String!) {
  refreshUserClaimMission(userid: $userid)
}
`;

export async function refreshUserClaimMission(userid) {
    const variables = {
        userid: userid,
    };
    const execute = await ApolloClientQuery(REFRESH_USER_CLAIM_MISSION, variables, true);

    return execute;
}

export async function getReportingFields() {

    const variables = {
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_REPORTING_FIELD, variables, false);

    return execute;
}

const GET_AVAILABLE_USER_MISSION = gql(`
query GetAvailableUserMission($userid: String!, $filter: MissionSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getAvailableUserMission(userid: $userid, filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
        ... on AvailableUserMissionArray {
            data {
                _id
                id
                position
                name
                isRandomReward
                rewardValue
                missionCode
                networkType
                missionCodeType
                availability
                automationId
                continuationMission
                missionEnd
                product
                missionEventDurationStartDate
                missionEventDurationEndDate
                missionDurationType
                missionDurationValue
                missionDurationDate
                conditionCalculationPeriod
                autoClaim
                termAndConditionEnabled
                isDeleted
                autoComplete
                autoCompleteExpiredMission
                isTradingAccountRequired
                isClaimable
                isMissionCodeRequired
                createdAt
                updatedAt
                displayName {
                    language
                    content
                }
                description {
                    language
                    content
                }
                rules {
                    language
                    content
                }
                rewards {
                    rewardType
                    reward {
                        ... on VoucherGroup {
                            _id
                            id
                            name
                            isRandomReward
                            termAndConditionEnabled
                            rewardValue
                            isDeleted
                            createdAt
                            updatedAt
                            displayName {
                                language
                                content
                            }
                            description {
                                language
                                content
                            }
                            pool {
                                maximumRedeem
                                probability
                                voucher {
                                    _id
                                    name
                                    termAndConditionEnabled
                                    rewardType
                                    voucherType
                                    voucherValueIsRange
                                    voucherValue
                                    voucherValueStartRange
                                    voucherValueEndRange
                                    voucherStackable
                                    maxAmount
                                    isDeleted
                                    createdAt
                                    updatedAt
                                    displayName {
                                        language
                                        content
                                    }
                                    description {
                                        language
                                        content
                                    }
                                    termAndCondition {
                                        language
                                        content
                                    }
                                    product {
                                        _id
                                        name
                                    }
                                    stockVoucher {
                                        _id
                                        baseSymbolId
                                        name
                                    }
                                    defaultImageLight {
                                        fileList
                                        path
                                    }
                                    defaultImageDark {
                                        fileList
                                        path
                                    }
                                    imageLight {
                                        fileList
                                        path
                                    }
                                    imageDark {
                                        fileList
                                        path
                                    }
                                }
                            }
                            termAndCondition {
                                language
                                content
                            }
                            defaultImageLight {
                                fileList
                                path
                            }
                            defaultImageDark {
                                fileList
                                path
                            }
                            imageLight {
                                fileList
                                path
                            }
                            imageDark {
                                fileList
                                path
                            }
                        }
                        ... on Voucher {
                            _id
                            id
                            name
                            termAndConditionEnabled
                            rewardType
                            voucherType
                            voucherValueIsRange
                            voucherValue
                            voucherValueStartRange
                            voucherValueEndRange
                            voucherStackable
                            maxAmount
                            isDeleted
                            createdAt
                            updatedAt
                            displayName {
                                language
                                content
                            }
                            description {
                                language
                                content
                            }
                            termAndCondition {
                                language
                                content
                            }
                            product {
                                _id
                                name
                            }
                            stockVoucher {
                                _id
                                baseSymbolId
                                name
                            }
                            defaultImageLight {
                                fileList
                                path
                            }
                            defaultImageDark {
                                fileList
                                path
                            }
                            imageLight {
                                fileList
                                path
                            }
                            imageDark {
                                fileList
                                path
                            }
                        }
                    }
                    voucherExpiryType
                    voucherExpiryDate
                    voucherExpiryValue
                    voucherDurationType
                    voucherDurationValue
                    voucherDurationDate
                    maxRedeem
                    isFixedReward
                }
                continuationMissionId {
                    _id
                }
                productId {
                    _id
                    name
                }
                missionGroup {
                    _id
                    name
                    displayName {language content}
                }
                termAndCondition {
                    language
                    content
                }
                canClaimForTradingAccount {
                    _id
                    accountId
                }
                defaultImageLight {
                    fileList
                    path
                }
                defaultImageDark {
                    fileList
                    path
                }
                imageLight {
                    fileList
                    path
                }
                imageDark {
                    fileList
                    path
                }
                missionAutomationSetting
            }
            totalCount
        }
        ... on BaseError {
            errKey
            errMsg
            errObj
        }
    }
}
`)

export async function getAvailableUserMission(userId, filter, limit, offset, orderBy) {

    const variables = {
        userid: userId,
        filter,
        limit,
        offset,
        orderBy,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_AVAILABLE_USER_MISSION, variables, false);

    return execute;
}

const GET_MISSION_AUTOMATION_SETTING_BY_ID = gql`
query getMissionAutomationSettingById($id: String!) {
    getMissionAutomationSettingById(id: $id) {
        ... on MissionAutomationSetting {
            _id
            missionId
            triggerGroups {
                triggerGroup
                triggerName
            }
            criteriaGroups {
                position
                logicalOperator
                criteriaItems {
                    type
                    reportId
                    adminPanelCollection
                    reportFieldId
                    fieldName
                    comparisonOperator
                    logicalOperator
                    position
                    value
                    isDisplay
                }
            }
        }
        ... on BaseError {
            errKey
            errMsg
            errObj
        }
    }
}
`

export async function getMissionAutomationSettingById(id) {

    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_MISSION_AUTOMATION_SETTING_BY_ID, variables, false);

    return execute;
}

const GET_USER_CLAIM_MISSION = gql(`
query GetUserClaimMission($filter: UserClaimMissionInput, $limit: Int, $offset: Int, $orderBy: String) {
  getUserClaimMission(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
    ... on UserClaimMissionArray {
      data {
        _id
        id
        missionGroupId {
          _id
          displayName {
            language
            content
          }
        }
        missionId {
          _id
          id
          position
          name
          isRandomReward
          rewardValue
          missionCode
          networkType
          missionCodeType
          availability
          automationId
          continuationMission
          missionEnd
          product
          missionEventDurationStartDate
          missionEventDurationEndDate
          missionDurationType
          missionDurationValue
          missionDurationDate
          conditionCalculationPeriod
          autoClaim
          termAndConditionEnabled
          isDeleted
          autoComplete
          autoCompleteExpiredMission
          isTradingAccountRequired
          isMissionCodeRequired
          createdAt
          updatedAt
          displayName {
            language
            content
          }
          description {
            language
            content
          }
          rules {
            language
            content
          }
          rewards {
            rewardType
            reward {
              ... on VoucherGroup {
                _id
                id
                name
                isRandomReward
                termAndConditionEnabled
                rewardValue
                isDeleted
                createdAt
                updatedAt
                displayName {
                  language
                  content
                }
                description {
                  language
                  content
                }
                pool {
                  maximumRedeem
                  probability
                  voucher {
                    _id
                    name
                    termAndConditionEnabled
                    rewardType
                    voucherType
                    voucherValueIsRange
                    voucherValue
                    voucherValueStartRange
                    voucherValueEndRange
                    voucherStackable
                    maxAmount
                    isDeleted
                    createdAt
                    updatedAt
                    displayName {
                      language
                      content
                    }
                    description {
                      language
                      content
                    }
                    termAndCondition {
                      language
                      content
                    }
                    product {
                      _id
                      name
                    }
                    stockVoucher {
                      _id
                      baseSymbolId
                      name
                    }
                    defaultImageLight {
                      fileList
                      path
                    }
                    defaultImageDark {
                      fileList
                      path
                    }
                    imageLight {
                      fileList
                      path
                    }
                    imageDark {
                      fileList
                      path
                    }
                  }
                }
                termAndCondition {
                  language
                  content
                }
                defaultImageLight {
                  fileList
                  path
                }
                defaultImageDark {
                  fileList
                  path
                }
                imageLight {
                  fileList
                  path
                }
                imageDark {
                  fileList
                  path
                }
              }
              ... on Voucher {
                _id
                id
                name
                termAndConditionEnabled
                rewardType
                voucherType
                voucherValueIsRange
                voucherValue
                voucherValueStartRange
                voucherValueEndRange
                voucherStackable
                maxAmount
                isDeleted
                createdAt
                updatedAt
                displayName {
                  language
                  content
                }
                description {
                  language
                  content
                }
                termAndCondition {
                  language
                  content
                }
                product {
                  _id
                  name
                }
                stockVoucher {
                  _id
                  baseSymbolId
                  name
                }
                defaultImageLight {
                  fileList
                  path
                }
                defaultImageDark {
                  fileList
                  path
                }
                imageLight {
                  fileList
                  path
                }
                imageDark {
                  fileList
                  path
                }
              }
            }
            voucherExpiryType
            voucherExpiryDate
            voucherExpiryValue
            voucherDurationType
            voucherDurationValue
            voucherDurationDate
            maxRedeem
            isFixedReward
          }
          continuationMissionId {
            _id
          }
          productId {
            _id
            name
          }
          missionGroup {
            _id
            name
            displayName {language content}
          }
          termAndCondition {
            language
            content
          }
          canClaimForTradingAccount {
            _id
            accountId
          }
          defaultImageLight {
            fileList
            path
          }
          defaultImageDark {
            fileList
            path
          }
          imageLight {
            fileList
            path
          }
          imageDark {
            fileList
            path
          }
        }
        isMissionExpired
        isMissionCompleted
        isVoucherIssued
        tradingAccount {
          _id
          accountId
        }
        missionTarget
        missionClaimedAt
        isDeleted
        user {
          _id
          username
        }
        missionAutomationSetting
      }
    }
    ... on BaseError {
      errMsg
      errKey
      errObj
    }
  }
}
`)

export async function getUserClaimMission(filter, limit, offset, orderBy) {

    const variables = {
        filter: filter,
        limit: limit,
        offset: offset,
        orderBy: orderBy,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_USER_CLAIM_MISSION, variables, false);

    return execute;
}

const GET_USER_CLAIM_MISSION_COUNT = gql(`
query getUserClaimMissionCount($filter: UserClaimMissionInput) {
  getUserClaimMissionCount(filter: $filter) 
}
`)

export async function getUserClaimMissionCount(filter) {

    const variables = {
        filter: filter,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_USER_CLAIM_MISSION_COUNT, variables, false);

    return execute;
}

const ADD_USER_CLAIM_MISSION = gql`
mutation AddUserClaimMission($input: UserClaimMissionInput) {
  addUserClaimMission(input: $input) {
    ... on BaseError {
      errKey
      errMsg
      errObj
    }
    ... on CrmTaskApproval {
      msg
    }
    ... on UserClaimMission {
      _id
    }
  }
}
`;

export async function addUserClaimMission(input) {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(ADD_USER_CLAIM_MISSION, variables, true);

    return execute;
}

const USER_COMPLETE_MISSION = gql`
    mutation userCompleteMission($id: String!, $input: UserCompleteMissionIput) {
        userCompleteMission(id: $id, input: $input) {
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on UserVouchersArray {
                data {
                    _id
                }
            }
        }
    }
`

export async function userCompleteMission(id, input) {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(USER_COMPLETE_MISSION, variables, true);

    return execute;
}

const USER_CLAIM_MISSION_WITH_MISSION_CODE = gql`
mutation userClaimMissionWithMissionCode($input: UserExtraMissionCodeInput) {
  userClaimMissionWithMissionCode(input: $input) {
    ... on BaseError {
      errKey
      errMsg
      errObj
    }
    ... on CrmTaskApproval {
      msg
    }
    ... on UserExtraMission {
      _id
    }
  }
}
`;

export async function userClaimMissionWithMissionCode(input) {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(USER_CLAIM_MISSION_WITH_MISSION_CODE, variables, true);

    return execute;
}

const FORFEIT_USER_CLAIM_MISSION = gql`
    mutation forfeitUserClaimMission($id: String!) {
        forfeitUserClaimMission(id: $id) {
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on UserClaimMission {
                _id
            }
        }
    }
`

export async function forfeitUserClaimMission(id) {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(FORFEIT_USER_CLAIM_MISSION, variables, true);

    return execute;
}

const GET_MISSION_BY_MISSION_CODE = gql`
    mutation getMissionByMissionCode($missionCode: String!) {
        getMissionByMissionCode(missionCode: $missionCode) {
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on Mission {
                _id
            }
        }
    }
`

export async function getMissionByMissionCode(missionCode) {
    const variables = {
        missionCode: missionCode,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_MISSION_BY_MISSION_CODE, variables, true);

    return execute;
}

const ADD_USER_EXTRA_MISSION = gql`
mutation addUserExtraMission($input: UserExtraMissionInput) {
    addUserExtraMission(input: $input) {
        ... on BaseError {
            errKey
            errMsg
            errObj
        }
        ... on UserExtraMission {
            _id
        }
    }
}
`

export async function addUserExtraMission(input) {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(ADD_USER_EXTRA_MISSION, variables, true);

    return execute;
}