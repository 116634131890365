import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_USER_BY_ID = gql`
    query getUserByID($id: String!) {
        getUserByID(id: $id) {
            ... on User {
                _id
                id
                firstName
                middleName
                lastName
                fullName
                aliasFirstName
                aliasMiddleName
                aliasLastName
                aliasFullName
                username
                email
                gender
                dateOfBirth
                age
                countryCode
                mobileNo
                completedMobileNo
                domain
                userType
                identificationType
                identificationNo
                comment
                language
                newsletter
                address
                city
                postalCode
                state
                country
                occupationType
                purposeOfTransaction
                nameOfEmployer
                natureOfBusiness
                approximateNetWorth
                countryOfTaxResidency
                sourceOfWealth
                affiliateCode
                companyName
                profileImage
                eWallet {
                    _id
                    isFreezed
                    balance
                }
                pspUserId
                status
                clientAccountType
                rank {
                    _id
                    name
                }
                desk {
                    _id
                    name
                }
                referrer {
                    _id
                    fullName
                }
                salesRep {
                    _id
                    fullName
                }
                role {
                    _id
                    name
                    isDefaultCrmUserPermission
                }
                lastDepositDate
                isLoginEnabled
                isTestAccount
                isKycApproved
                isDeleted
                deletedReason
                legalName
                dateOfIncorporation
                dbaAliasTradingName
                incorporationAddress
                incorporationCity
                incorporationPostalCode
                incorporationCountry
                registeredAddress
                registeredCity
                registeredPostalCode
                registeredCountry
                businessAddress
                businessCity
                businessPostalCode
                businessCountry
                representativeType
                formerName
                formerDbaAliasTradingName
                registrationNumber
                corporateEmail
                corporateCountryCode
                corporateMobileNo
                legalForm
                isSuspicious
                verifiedEmail
                previousFollowUp {
                    followUpDateTime
                }
                latestFollowUp {
                    followUpDateTime
                }
                emailVerificationCode
                loginStartDate
                loginEndDate
                nationality
                isActive
                signature
                theme
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
                isTemporaryPasswordEnabled
                directClient
                account
                isDeletedByClient
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getUserById(id) {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_USER_BY_ID, variables, false);

    return execute;
}

const UPDATE_CLIENT_USER = gql`
    mutation updateClientUser($id: String!, $input: ClientUserInput) {
        updateClientUser(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                _id
                id
                firstName
                middleName
                lastName
                fullName
                aliasFirstName
                aliasMiddleName
                aliasLastName
                aliasFullName
                username
                email
                gender
                dateOfBirth
                age
                countryCode
                mobileNo
                completedMobileNo
                domain
                userType
                identificationType
                identificationNo
                comment
                language
                newsletter
                address
                city
                postalCode
                state
                country
                occupationType
                purposeOfTransaction
                nameOfEmployer
                natureOfBusiness
                approximateNetWorth
                countryOfTaxResidency
                sourceOfWealth
                affiliateCode
                companyName
                profileImage
                eWallet {
                    _id
                    isFreezed
                    balance
                }
                pspUserId
                status
                clientAccountType
                rank {
                    _id
                    name
                }
                desk {
                    _id
                    name
                }
                referrer {
                    _id
                    fullName
                }
                salesRep {
                    _id
                    fullName
                }
                role {
                    _id
                    name
                    isDefaultCrmUserPermission
                }
                lastDepositDate
                isLoginEnabled
                isTestAccount
                isKycApproved
                isDeleted
                deletedReason
                legalName
                dateOfIncorporation
                dbaAliasTradingName
                incorporationAddress
                incorporationCity
                incorporationPostalCode
                incorporationCountry
                registeredAddress
                registeredCity
                registeredPostalCode
                registeredCountry
                businessAddress
                businessCity
                businessPostalCode
                businessCountry
                representativeType
                formerName
                formerDbaAliasTradingName
                registrationNumber
                corporateEmail
                corporateCountryCode
                corporateMobileNo
                legalForm
                isSuspicious
                verifiedEmail
                previousFollowUp {
                    followUpDateTime
                }
                latestFollowUp {
                    followUpDateTime
                }
                emailVerificationCode
                loginStartDate
                loginEndDate
                nationality
                isActive
                signature
                theme
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
                isTemporaryPasswordEnabled
                directClient
                account
                isDeletedByClient
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function updateClientUser(id, value) {
    const variables = {
        id: id,
        input: value,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_CLIENT_USER, variables, true);

    return execute;
}

const UPDATE_USER = gql`
    mutation updateUser($id: String!, $input: UserInput) {
        updateUser(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                _id
                id
                firstName
                middleName
                lastName
                fullName
                aliasFirstName
                aliasMiddleName
                aliasLastName
                aliasFullName
                username
                email
                gender
                dateOfBirth
                age
                countryCode
                mobileNo
                completedMobileNo
                domain
                userType
                identificationType
                identificationNo
                comment
                language
                newsletter
                address
                city
                postalCode
                state
                country
                occupationType
                purposeOfTransaction
                nameOfEmployer
                natureOfBusiness
                approximateNetWorth
                countryOfTaxResidency
                sourceOfWealth
                affiliateCode
                companyName
                profileImage
                eWallet {
                    _id
                    isFreezed
                    balance
                }
                pspUserId
                status
                clientAccountType
                rank {
                    _id
                    name
                }
                desk {
                    _id
                    name
                }
                referrer {
                    _id
                    fullName
                }
                salesRep {
                    _id
                    fullName
                }
                role {
                    _id
                    name
                    isDefaultCrmUserPermission
                }
                lastDepositDate
                isLoginEnabled
                isTestAccount
                isKycApproved
                isDeleted
                deletedReason
                legalName
                dateOfIncorporation
                dbaAliasTradingName
                incorporationAddress
                incorporationCity
                incorporationPostalCode
                incorporationCountry
                registeredAddress
                registeredCity
                registeredPostalCode
                registeredCountry
                businessAddress
                businessCity
                businessPostalCode
                businessCountry
                representativeType
                formerName
                formerDbaAliasTradingName
                registrationNumber
                corporateEmail
                corporateCountryCode
                corporateMobileNo
                legalForm
                isSuspicious
                verifiedEmail
                previousFollowUp {
                    followUpDateTime
                }
                latestFollowUp {
                    followUpDateTime
                }
                emailVerificationCode
                loginStartDate
                loginEndDate
                nationality
                isActive
                signature
                theme
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
                isTemporaryPasswordEnabled
                directClient
                account
                isDeletedByClient
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function updateUser(id, value) {
    const variables = {
        id: id,
        input: value,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER, variables, true);

    return execute;
}

const UPDATE_USER_PASSWORD = gql`
    mutation updateUserPassword($id: String!, $input: UserPasswordInput) {
        updateUserPassword(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                _id
                id
                firstName
                middleName
                lastName
                fullName
                aliasFirstName
                aliasMiddleName
                aliasLastName
                aliasFullName
                username
                email
                gender
                dateOfBirth
                age
                countryCode
                mobileNo
                completedMobileNo
                domain
                userType
                identificationType
                identificationNo
                comment
                language
                newsletter
                address
                city
                postalCode
                state
                country
                occupationType
                purposeOfTransaction
                nameOfEmployer
                natureOfBusiness
                approximateNetWorth
                countryOfTaxResidency
                sourceOfWealth
                affiliateCode
                companyName
                profileImage
                eWallet {
                    _id
                    isFreezed
                    balance
                }
                pspUserId
                status
                clientAccountType
                rank {
                    _id
                    name
                }
                desk {
                    _id
                    name
                }
                referrer {
                    _id
                    fullName
                }
                salesRep {
                    _id
                    fullName
                }
                role {
                    _id
                    name
                    isDefaultCrmUserPermission
                }
                lastDepositDate
                isLoginEnabled
                isTestAccount
                isKycApproved
                isDeleted
                deletedReason
                legalName
                dateOfIncorporation
                dbaAliasTradingName
                incorporationAddress
                incorporationCity
                incorporationPostalCode
                incorporationCountry
                registeredAddress
                registeredCity
                registeredPostalCode
                registeredCountry
                businessAddress
                businessCity
                businessPostalCode
                businessCountry
                representativeType
                formerName
                formerDbaAliasTradingName
                registrationNumber
                corporateEmail
                corporateCountryCode
                corporateMobileNo
                legalForm
                isSuspicious
                verifiedEmail
                previousFollowUp {
                    followUpDateTime
                }
                latestFollowUp {
                    followUpDateTime
                }
                emailVerificationCode
                loginStartDate
                loginEndDate
                nationality
                isActive
                signature
                theme
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
                isTemporaryPasswordEnabled
                directClient
                account
                isDeletedByClient
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function updateUserPassword(id, value) {
    const variables = {
        id: id,
        input: value,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_PASSWORD, variables, true);

    return execute;
}

const DELETE_USER = gql`
    mutation deleteUser($id: String!, $reason: String) {
        deleteUser(id: $id, reason: $reason) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                _id
                id
                firstName
                middleName
                lastName
                fullName
                aliasFirstName
                aliasMiddleName
                aliasLastName
                aliasFullName
                username
                email
                gender
                dateOfBirth
                age
                countryCode
                mobileNo
                completedMobileNo
                domain
                userType
                identificationType
                identificationNo
                comment
                language
                newsletter
                address
                city
                postalCode
                state
                country
                occupationType
                purposeOfTransaction
                nameOfEmployer
                natureOfBusiness
                approximateNetWorth
                countryOfTaxResidency
                sourceOfWealth
                affiliateCode
                companyName
                profileImage
                eWallet {
                    _id
                    isFreezed
                    balance
                }
                pspUserId
                status
                clientAccountType
                rank {
                    _id
                    name
                }
                desk {
                    _id
                    name
                }
                referrer {
                    _id
                    fullName
                }
                salesRep {
                    _id
                    fullName
                }
                role {
                    _id
                    name
                    isDefaultCrmUserPermission
                }
                lastDepositDate
                isLoginEnabled
                isTestAccount
                isKycApproved
                isDeleted
                deletedReason
                legalName
                dateOfIncorporation
                dbaAliasTradingName
                incorporationAddress
                incorporationCity
                incorporationPostalCode
                incorporationCountry
                registeredAddress
                registeredCity
                registeredPostalCode
                registeredCountry
                businessAddress
                businessCity
                businessPostalCode
                businessCountry
                representativeType
                formerName
                formerDbaAliasTradingName
                registrationNumber
                corporateEmail
                corporateCountryCode
                corporateMobileNo
                legalForm
                isSuspicious
                verifiedEmail
                previousFollowUp {
                    followUpDateTime
                }
                latestFollowUp {
                    followUpDateTime
                }
                emailVerificationCode
                loginStartDate
                loginEndDate
                nationality
                isActive
                signature
                theme
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
                isTemporaryPasswordEnabled
                directClient
                account
                isDeletedByClient
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function deleteUser(id, reason) {
    const variables = {
        id: id,
        reason: reason,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_USER, variables, true);

    return execute;
}

const DELETE_CLIENT_USER = gql`
    mutation deleteClientUser($id: String!, $input: ClientUserDeleteInput) {
        deleteClientUser(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                _id
                isDeletedByClient
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function deleteClientUser(id, input) {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_CLIENT_USER, variables, true);

    return execute;
}

const GET_USER_SETTING_BY_USER_ID = gql`
    query getUserSettingByUserID($userid: String!) {
        getUserSettingByUserID(userid: $userid) {
            ... on UserSetting {
                _id
                id
                user {
                    username
                }
                timezone
                theme
                language
                newsletter {
                    _id
                    id
                    name
                    label
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getUserSettingByUserID(userid) {
    const variables = {
        userid: userid,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_USER_SETTING_BY_USER_ID, variables, false);

    return execute;
}

const UPDATE_EMAIL_NEWSLETTER_SUBSCRIPTION = gql`
    mutation updateEmailNewsletterSubscription($input: SubscriptionInput) {
        updateEmailNewsletterSubscription(input: $input)
    }
`;

export async function updateEmailNewsletterSubscription(input) {
    const variables = {
        input: input,
        // context: {
        //     headers: {
        //         authorization: "Bearer " + localStorage.getItem("a_t"),
        //     },
        // },
    };
    const execute = await ApolloClientQuery(UPDATE_EMAIL_NEWSLETTER_SUBSCRIPTION, variables, true);

    return execute;
}
