import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_SYMBOL_MAPPING_CATEGORY = gql`
    query getSymbolMappingCategory($filter: SymbolMappingCategorySearchInput) {
        getSymbolMappingCategory(filter: $filter) {
            ... on SymbolMappingCategoryArray {
                data {
                    category{
                        parent{
                            categoryName
                            label
                            type
                        }
                        categoryName
                        label
                        type
                    }
                    symbol{
                        name
                        symbolId
                        baseSymbolId
                        description
                        favorite
                        enabled
                    }
                    symbolMt5 {
                        _id
                        symbol
                        description
                        baseSymbolId
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getSymbolMappingCategory(server) {

    const variables = {
        filter: {
            serverName: server
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_SYMBOL_MAPPING_CATEGORY, variables, false);

    return execute;
}