import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Image,
  Layout,
  Menu,
  Row,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  globalBrandSettingState,
  globalColorThemeState,
  globalCurrentPathState,
  globalLanguageListState,
  globalUserDetailState,
} from "../../../_common/global_state";
import useShared from "../../../_common/_shared";
import { useTranslation } from "react-i18next";
import { CloseOutlined, EditOutlined, RightOutlined } from "@ant-design/icons";
import EditNavigationModal_6 from "./editNavigationModal";
import { useVerification } from "../../../_common/_authorize";
import { getPlatform } from "../../../api/graphql/platformDownload";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import useLayoutFunction from "../../function";
import {useIsMobile} from "../../../_common/function";

export const Sider_6 = (props) => {
  const {
    routes,
    collapsed,
    onCollapsedChange,
    setOpenGoLiveModal,
    setOpenQrModal,
    setOpenPlatformModal,
    setCollapsed
  } = props
  const isMobile = useIsMobile(768)
  const { Sider } = Layout;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { IconCommon, IconFlag } = useShared();
  const [openEditNavModal, setOpenEditNavModal] = useState(false);
  const userDetails = useRecoilValue(globalUserDetailState);
  const languageListState = useRecoilValue(globalLanguageListState);
  const globalPathState = useRecoilValue(globalCurrentPathState);
  const brandSettingState = useRecoilValue(globalBrandSettingState);

  const [colorState, setColorState] = useRecoilState(globalColorThemeState);
  const { handleTokenExpiration } = useVerification();
  const [platformData, setPlatformData] = useState([]);
  const [nav, setNav] = useState([]);
  const {changeLanguageHandler} = useLayoutFunction()

  const currentLanguageObj = languageListState?.find(
    (lang) => lang?.code === localStorage.getItem("language")
  );

  function filterAndGroupServers(arr) {
    const result = {};
    let appObject = null;

    for (const obj of arr) {
      const serverName = obj?.server?.name;
      const isEnabled =
        obj?.windowLinkObject.isEnabled ||
        obj?.macOSXLinkObject.isEnabled ||
        obj?.androidAPKLinkObject.isEnabled ||
        obj?.iOSDownloadLinkObject.isEnabled ||
        obj?.androidDownloadLinkObject.isEnabled ||
        obj?.huaweiDownloadLinkObject.isEnabled;

      if (obj.type === "app" && isEnabled) {
        appObject = obj;
      }

      if (serverName && isEnabled) {
        result[serverName] = obj;
      }
    }

    if (appObject) {
      result["App"] = appObject;
    }

    return result;
  }

  useEffect(() => {
    processRoute();
  }, [routes]);

  const processRoute = async () => {
    let fav = [],
      other;

    await routes?.map((route) => {
      if (route.key !== "more") {
        fav.push(route);
      } else {
        other = route.sub;
      }
    });

    setNav([
      {
        groupName: "pinned",
        label: t("common.pinnedPages"),
        items: fav,
      },
      {
        groupName: "pages",
        label: t("common.pages"),
        items: other,
      },
    ]);
  };

  const languageDropdown = (
    <Menu
      className={"language-dropdown-menu"}
      selectedKeys={[localStorage.getItem("language")]}
    >
      {languageListState?.map((lang) => (
        <Menu.Item
          key={lang?.code}
          value={lang?.code}
          onClick={() => changeLanguageHandler(lang?.code)}
        >
          <IconFlag countryCode={lang?.countryCode} style={{ width: "24px" }} />
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Sider
      width={240}
      id={"layout-sider"}
      breakpoint={"lg"}
      collapsedWidth="0"
      collapsible
      collapsed={collapsed}
    >
      <div className="nav-container">
        <div className="nav-close-button">
          <Button
            className=""
            type="text"
            icon={<CloseOutlined />}
            onClick={() => onCollapsedChange(!collapsed)}
          />
        </div>
        {nav?.length > 0 &&
          nav?.map((d, i) => (
            <Fragment key={d.groupName}>
              <div className="nav-group-title d-flex">
                {d.label}
                {d?.groupName === "pinned" && (
                  <a
                    className={"tertiary_btn ms-auto"}
                    onClick={() => {
                      setOpenEditNavModal(true);
                    }}
                  >
                    <EditOutlined />
                  </a>
                )}
              </div>
              <div
                className={`nav-item-container ${i === 1 ? "scrollable" : ""}`}
              >
                {d?.items?.map((item, j) =>
                  item?.sub ? (
                    <Collapse ghost>
                      <CollapsePanel
                        showArrow={false}
                        key={j}
                        header={
                          <Fragment key={j}>
                            <a
                              onClick={() => {
                                item?.type === "_blank"
                                    ? window.open(item?.path, "_blank")
                                    : navigate(item?.path)
                              }}
                              className={`${
                                globalPathState === item?.path ? "active" : ""
                              } nav-item`}
                            >
                              <div className="nav-item-icon me-1">
                                <RightOutlined />
                              </div>
                              <div
                                className="nav-item-icon me-2"
                                style={{ marginRight: "4px" }}
                              >
                                {item?.icon}
                              </div>
                              <div>{item.label}</div>
                            </a>
                            {item.children ? (
                              <div
                                className={`nested-nav-item-container ${
                                  item.isExpanded ? "open" : ""
                                }`}
                              >
                                <div>
                                  {item.children?.map((c) => (
                                    <a className="nav-item">
                                      <div className="nav-item-icon" />
                                      <div className="nav-item-icon">
                                        {c.icon}
                                      </div>
                                      <div>{c.label}</div>
                                    </a>
                                  ))}
                                </div>
                              </div>
                            ) : null}
                          </Fragment>
                        }
                      >
                        {item?.sub?.map((d, i) => (
                          <Fragment key={i}>
                            <a
                              onClick={() => {
                                d?.type === "_blank"
                                    ? window.open(d?.path, "_blank")
                                    : navigate(d?.path)
                                isMobile && setCollapsed(true)
                              }}
                              className={`${
                                globalPathState === d?.path ? "active" : ""
                              } nav-item`}
                            >
                              <div className="nav-item-icon me-1" />
                              <div className="nav-item-icon me-2" />
                              <div>{d.label}</div>
                            </a>
                          </Fragment>
                        ))}
                      </CollapsePanel>
                    </Collapse>
                  ) : (
                    <Fragment key={j}>
                      <a
                        onClick={() => {
                          item?.type === "_blank"
                              ? window.open(item?.path, "_blank")
                              : navigate(item?.path)
                          isMobile && setCollapsed(true)
                        }}
                        className={`${
                          globalPathState === item?.path ? "active" : ""
                        } nav-item`}
                      >
                        <div className="nav-item-icon me-1"></div>
                        <div
                          className="nav-item-icon me-2"
                          style={{ marginRight: "4px" }}
                        >
                          {item?.icon}
                        </div>
                        <div>{item.label}</div>
                      </a>
                    </Fragment>
                  )
                )}
              </div>
            </Fragment>
          ))}
        <div className="nav-logo-container text-center d-none d-lg-block">
          <Image
            src={
              brandSettingState?.[
                `companyLogo${colorState === "dark" ? "Inverse" : ""}`
              ]
            }
            height={25}
            preview={false}
          />
        </div>
        <div className={"d-block d-lg-none"}>
          {isMobile &&
          <Row gutter={[0, 8]}>
            {userDetails?.clientAccountType === "demo" &&
            <Col span={24}>
              <Button
                  block
                  type={"primary"}
                  onClick={() => setOpenGoLiveModal(true)}
              >
                {t("common.goLive")}
              </Button>
            </Col>
            }
            <Col span={24}>
              <Button
                  block
                  type={"primary"}
                  className={""}
                  onClick={() => {
                    setOpenPlatformModal(true);
                  }}
                  icon={
                    <IconCommon
                        isAlwaysInActive
                        iconName={"cm-download-regular"}
                        style={{ width: "16px" }}
                    />
                  }
              >
                {t("module.platformDownload")}
              </Button>
            </Col>
          </Row>
          }
          <Divider />
          <Row>
            <Dropdown
              overlay={languageDropdown}
              id={"language-select"}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              arrow
              placement={"top"}
            >
              <Col
                span={6}
                className={"text-center"}
                onClick={(e) => e?.preventDefault()}
              >
                <IconFlag
                  countryCode={currentLanguageObj?.countryCode}
                  style={{ width: "24px" }}
                />
              </Col>
            </Dropdown>
            <Col
              span={6}
              className={"text-center"}
              onClick={() => {
                if (colorState === "light") {
                  setColorState("dark");
                  localStorage.setItem("color", "dark");
                } else {
                  setColorState("light");
                  localStorage.setItem("color", "light");
                }
              }}
            >
              {colorState !== "light" ? (
                <IconCommon
                  iconName={"cm-darktheme-regular"}
                  className={"toggleIcon"}
                  style={{ width: "24px" }}
                />
              ) : (
                <IconCommon
                  iconName={"cm-lighttheme-regular"}
                  className={"toggleIcon"}
                  style={{ width: "24px" }}
                />
              )}
            </Col>
            <Col span={6} className={"text-center"}>
              <IconCommon
                style={{ width: "24px" }}
                iconName={"cm-qr-regular"}
                onClick={() => {
                  setOpenQrModal(true);
                }}
              />
            </Col>
            <Col span={6} className={"text-center"}>
              <IconCommon
                style={{ width: "24px" }}
                iconName={"cm-logout-regular"}
                onClick={() => handleTokenExpiration()}
              />
            </Col>
          </Row>
        </div>
      </div>
      <EditNavigationModal_6
        isModalOpen={openEditNavModal}
        handleCancel={() => setOpenEditNavModal(false)}
      />
    </Sider>
  );
};
