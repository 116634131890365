export const categoryFakeData = [{
    category: {
        categoryName: "Shares",
        label: "symbolCategory.shares",
        type: "stocks"
    },
    symbol: [
        {
            baseSymbolId: "987",
            description: "Apple 100 Shares per 1 Lot",
            enabled: true,
            favorite: false,
            name: "APPLE",
            symbolId: 351
        },
        {
            name: "GOOGLE",
            symbolId: 355,
            baseSymbolId: "608",
            description: "Google 100 Shares Per 1 Lot",
            favorite: false,
            enabled: true
        }
    ]
}]