import React, {Fragment, useEffect, useState} from "react";
import {Button, Layout, Menu, Popover, Space} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    globalBrandConfigState,
    globalBrandModuleSettingState,
    globalBrandSettingState,
    globalColorThemeState,
    globalIconListState,
    globalLanguageListState,
    globalNotificationState,
    globalUserDetailState,
    globalUserTradingAccount,
    globalUserWalletState,
    globalWebModuleSettingState,
} from "../../../_common/global_state";
import {useProfileFunction} from "../../../pages/profile/function";
import useShared from "../../../_common/_shared";
import {useTranslation} from "react-i18next";
import {DownOutlined} from "@ant-design/icons";
import EditNavigationModal_3 from "./editNavigationModal";
import FooterTime from "./latestTime/footerTime";
import useLayoutFunction from "../../function";
import {useVerification} from "../../../_common/_authorize";
import {useIsMobile} from "../../../_common/function";

export const Sider_3 = ({routes, collapsed, setCollapsed, setOpenQrModal, setOpenPlatformModal, setOpenGoLiveModal}) => {
    const {Sider} = Layout;
    const isMobile = useIsMobile(991)
    const location = useLocation();
    const [openEditNavModal, setOpenEditNavModal] = useState(false);
    const [userPreference, setUserPreference] = useState([]);
    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const brandModuleSetting = useRecoilValue(globalBrandModuleSettingState);
    const webModuleSetting = useRecoilValue(globalWebModuleSettingState);
    const userWallet = useRecoilValue(globalUserWalletState);
    const userDetails = useRecoilValue(globalUserDetailState);
    const tradingAccState = useRecoilValue(globalUserTradingAccount);
    const navigate = useNavigate();
    const {IconModule, IconCommon, IconFlag} = useShared();
    const {t} = useTranslation();
    const {changeLanguageHandler} = useLayoutFunction();
    const {handleTokenExpiration} = useVerification();
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const languageListState = useRecoilValue(globalLanguageListState);
    const hasVerified = userDetails?.isKycApproved;
    const hasDeposited = userWallet.balance > 0 && userDetails.lastDepositDate;
    const hasTradingAccount = tradingAccState?.length;

    useEffect(() => {
        const filterModuleSetting = JSON.parse(
            JSON.stringify(
                webModuleSetting.filter((item) => item?.moduleId?.isEnabled === true)
            )
        );
        setUserPreference(filterModuleSetting);
    }, [webModuleSetting]);

    const flattedRoutes = routes.reduce(
        (a, b) => ({
            ...a,
            ...(b.key === "more"
                ? b.sub.reduce((c, d) => ({...c, [d.key]: d}), {})
                : {[b.key]: b}),
        }),
        {}
    );

    const languageDropdown = (
        <Menu
            className={"sidebarLanguage"}
            selectedKeys={[localStorage.getItem("language")]}
        >
            {(languageListState ?? [])?.map((lang) => {
                return (
                    <Menu.Item
                        key={lang?.code}
                        value={lang?.code}
                        onClick={() => changeLanguageHandler(lang?.code)}
                    >
                        <Space><IconFlag countryCode={lang?.countryCode} style={{width: "24px"}}/> {lang?.nativeName}</Space>
                    </Menu.Item>
                )
            })}
        </Menu>
    );

    return (
        <Sider
            width={280}
            id={"layout-sider"}
            className={collapsed ? "expand" : ""}
            breakpoint="lg"
            collapsedWidth="0"
        >
            <div
                className="show-sm"
                style={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: "8px 16px 20px",
                }}
            >
                <Button
                    type="default"
                    className="square icon show-sm"
                    onClick={() => {
                    }}
                >
                    <IconCommon iconName={"cm-menu-regular"}/>
                </Button>
            </div>
            <div>
                <div className={"show-sm"}>
                    <div className={"mx-auto d-flex flex-column mb-3"}>
                        {userDetails?.clientAccountType === "demo" && (
                            <Button
                                type="primary"
                                className="goLiveButton mb-3"
                                onClick={() => {
                                    setOpenGoLiveModal(true);
                                }}
                            >
                                {t("common.goLive")}
                            </Button>
                        )}
                        <div className={"mx-auto"}>
                            <Space>
                                <Button
                                    type="default"
                                    className="square icon"
                                    onClick={() => {
                                        setOpenQrModal(true);
                                    }}
                                >
                                    <IconCommon iconName={"cm-qr-regular"}/>
                                </Button>

                                <Popover
                                    trigger={"click"}
                                    getPopupContainer={() => document.querySelector("#layout-3")}
                                    content={languageDropdown}
                                >
                                    <Button type="default" className="square icon">
                                        <IconCommon iconName={"cm-language-regular"}/>
                                    </Button>
                                </Popover>

                                <Button type="default" className="square icon" onClick={() => {
                                    if (colorState === "light") {
                                        setColorState("dark");
                                        localStorage.setItem("color", "dark");
                                    } else {
                                        setColorState("light");
                                        localStorage.setItem("color", "light");
                                    }
                                }}>
                                    <IconCommon iconName={`cm-${colorState}theme-regular`}/>
                                </Button>

                                <Button
                                    type="default"
                                    className="square icon"
                                    onClick={() => {
                                        setOpenPlatformModal(true);
                                    }}
                                >
                                    <IconCommon iconName={"cm-download-regular"}/>
                                </Button>

                                <Button
                                    type="default"
                                    className="square icon"
                                    onClick={() => handleTokenExpiration()}
                                >
                                    <IconCommon iconName={"cm-logout-regular"}/>
                                </Button>
                            </Space>
                        </div>
                    </div>
                </div>
                <div className={`sider-title ${!isMobile ? "border-0" : ""}`}>{t("common.getstarted")}</div>
                <div className="quick-action-container">
                    <div
                        className={`custom-card quick-action-item ${
                            !hasVerified ? "active-action" : ""
                        }`}
                    >
                        <div className="numbering">1</div>
                        <div className="labeling">{t("common.verify")}</div>
                        {!hasVerified ? (
                            <Button
                                type="primary"
                                onClick={() => {
                                    navigate("/account-verification");
                                    setCollapsed(false)
                                }}
                            >
                                {t("common.verify")}
                            </Button>
                        ) : (
                            <div className="pending-active">{t("common.complete")}</div>
                        )}
                    </div>
                    <div
                        className={`custom-card quick-action-item ${
                            hasVerified && !hasDeposited ? "active-action" : ""
                        }`}
                    >
                        <div className="numbering">2</div>
                        <div className="labeling">{t("common.deposit")}</div>
                        {!hasVerified ? (
                            <div className="pending-active">{t("common.pending")}</div>
                        ) : !hasDeposited ? (
                            <Button
                                type="primary"
                                onClick={() => {
                                    navigate("/wallet-deposit");
                                    setCollapsed(false)
                                }}
                            >
                                {t("common.deposit")}
                            </Button>
                        ) : (
                            <div className="pending-active">{t("common.complete")}</div>
                        )}
                    </div>
                    <div
                        className={`custom-card quick-action-item ${
                            hasVerified && hasDeposited && !hasTradingAccount
                                ? "active-action"
                                : ""
                        }`}
                    >
                        <div className="numbering">3</div>
                        <div className="labeling">{t("user.tradingAccount")}</div>
                        {!hasVerified || !hasDeposited ? (
                            <div className="pending-active">{t("common.pending")}</div>
                        ) : !hasTradingAccount ? (
                            <Button type="primary">{t("common.add")}</Button>
                        ) : (
                            <div className="pending-active">{t("common.complete")}</div>
                        )}
                    </div>
                </div>
            </div>
            <div>
                <div className="sider-header">
                    <div className="sider-title">{t("common.pinnedPages")}</div>
                    <IconCommon
                        iconName={"cm-edit-regular"}
                        onClick={() => {
                            setOpenEditNavModal(true);
                        }}
                    />
                </div>
                <div className="menu-container">
                    {(routes?.filter(d => d?.key !== "more") ?? [])
                        .map((item, index) => {
                            const iconName = `md-${(item?.label || "")
                                ?.replaceAll(/[-\s\.]/g, "")
                                ?.toLowerCase()}-regular`;
                            const moduleKey = `module.${(item?.label || "")
                                // ?.replaceAll(/[_]/g, "-")
                                ?.replaceAll(/[-\s]/g, "_")
                                ?.replaceAll(/[\.]/g, "")
                                ?.toLowerCase()}`;
                            const hasChildren = item?.sub?.length
                            const isActive = item?.path?.startsWith?.(location.pathname);

                            return (
                                <Fragment key={index}>
                                    <div
                                        className={`sider-nav-item ${hasChildren ? "" : ""} ${
                                            isActive ? "active" : ""
                                        }`}
                                        onClick={(e) => {
                                            if (hasChildren) {
                                                e.currentTarget.classList.toggle("expand");
                                                return;
                                            }

                                            if (item?.type === "_blank") {
                                                return window.open(item?.path, "_blank");
                                            }
                                            navigate(item?.path);
                                            setCollapsed(false)
                                        }}
                                    >
                                        <div className="icon">
                                            <IconModule layout={3} iconName={iconName}/>
                                        </div>
                                        <div>{t(moduleKey)}</div>
                                        {hasChildren ? (
                                            <DownOutlined className="sider-nav-item-arrow"/>
                                        ) : null}
                                    </div>
                                    {hasChildren ? (
                                        <div className="sub-nav-container">
                                            {item?.sub.map((sub, index) => {
                                                const routeData = flattedRoutes[sub?.key || ""];
                                                const iconName = `md-${(sub?.key || "")
                                                    ?.replaceAll(/[-\s\.]/g, "")
                                                    ?.toLowerCase()}-regular`;
                                                const moduleKey = `module.${sub?.key?.replaceAll(
                                                    /[\.]/g,
                                                    ""
                                                )}`
                                                    ?.replaceAll(/[-\s]/g, "_")
                                                    ?.toLowerCase();
                                                const isActive = routeData?.path?.startsWith?.(
                                                    location.pathname
                                                );
                                                return (
                                                    <Fragment key={index}>
                                                        <div
                                                            className={`sider-nav-item ${
                                                                isActive ? "active" : ""
                                                            }`}
                                                            onClick={() => {
                                                                if (routeData?.type === "_blank") {
                                                                    return window.open(routeData?.path, "_blank");
                                                                }
                                                                navigate(routeData?.path);
                                                                setCollapsed(false)
                                                            }}
                                                        >
                                                            <div className="icon">
                                                                <IconModule layout={3} iconName={iconName}/>
                                                            </div>
                                                            <div>{t(moduleKey)}</div>
                                                        </div>
                                                    </Fragment>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </Fragment>
                            );
                        })}
                </div>
            </div>
            <div>
                <div className="sider-title">{t("bookmark.allPages")}</div>
                <div className="menu-container">
                    {(brandModuleSetting || [])
                        ?.filter(
                            (item) => item?.isEnabled === true && item?.parent === null
                        )
                        .sort((a, b) => a?.position - b?.position)
                        .map((item, index) => {
                            const routeData = flattedRoutes[item?.name || ""];
                            const iconName = `md-${(item?.name || "")
                                ?.replaceAll(/[-\s]/g, "")
                                ?.toLowerCase()}-regular`;
                            const moduleKey = `module.${item?.name}`
                                ?.replaceAll(/[-\s]/g, "_")
                                ?.toLowerCase();

                            const hasChildren = routeData?.sub?.length;
                            const isActive = routeData?.path?.startsWith?.(location.pathname);
                            return (
                                <Fragment key={index}>
                                    <div
                                        className={`sider-nav-item ${hasChildren ? "" : ""} ${
                                            isActive ? "active" : ""
                                        }`}
                                        onClick={(e) => {
                                            if (hasChildren) {
                                                e.currentTarget.classList.toggle("expand");
                                                return;
                                            }

                                            if (routeData?.type === "_blank") {
                                                return window.open(routeData?.path, "_blank");
                                            }
                                            navigate(routeData?.path);
                                            setCollapsed(false)
                                        }}
                                    >
                                        <div className="icon">
                                            <IconModule layout={3} iconName={iconName}/>
                                        </div>
                                        <div>{t(moduleKey)}</div>
                                        {hasChildren ? (
                                            <DownOutlined className="sider-nav-item-arrow"/>
                                        ) : null}
                                    </div>
                                    {hasChildren ? (
                                        <div className="sub-nav-container">
                                            {routeData?.sub.map((item, index) => {
                                                const routeData = flattedRoutes[item?.key || ""];
                                                const iconName = `md-${(item?.key || "")
                                                    ?.replaceAll(/[-\s\.]/g, "")
                                                    ?.toLowerCase()}-regular`;
                                                const moduleKey = `module.${item?.key?.replaceAll(
                                                    /[\.]/g,
                                                    ""
                                                )}`
                                                    ?.replaceAll(/[-\s]/g, "_")
                                                    ?.toLowerCase();
                                                const isActive = routeData?.path?.startsWith?.(
                                                    location.pathname
                                                );
                                                return (
                                                    <Fragment key={index}>
                                                        <div
                                                            className={`sider-nav-item ${
                                                                isActive ? "active" : ""
                                                            }`}
                                                            onClick={() => {
                                                                if (routeData?.type === "_blank") {
                                                                    return window.open(routeData?.path, "_blank");
                                                                }
                                                                navigate(routeData?.path);
                                                                setCollapsed(false)
                                                            }}
                                                        >
                                                            <div className="icon">
                                                                <IconModule layout={3} iconName={iconName}/>
                                                            </div>
                                                            <div>{t(moduleKey)}</div>
                                                        </div>
                                                    </Fragment>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </Fragment>
                            );
                        })}
                </div>
            </div>
            <div className="sider-footer">
                <FooterTime/>
                <div>
                    {t("login.footer.copyright", {
                        copyrightYear: new Date().getFullYear(),
                        companyName: brandSettingState?.companyNameShort,
                    })}
                </div>
            </div>

            <EditNavigationModal_3
                isModalOpen={openEditNavModal}
                handleCancel={() => setOpenEditNavModal(false)}
            />
        </Sider>
    );
};
