import axios from "axios";
import {encryptMT5Message} from "../../_common/function";

export async function getEconomicCalendar(filter) {
  let params = {
    timezone: filter?.timezone || null,
    date: filter?.date || null,
    importance: filter?.importance || null,
    region: filter?.region || null,
    lang: localStorage.getItem("language") || "en",
  };

  try {
    const response = await axios.get(
      "https://nextrades.ai/tc-e/economic-calendar",
      { params }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getSymbolPrice(symbolId, spreadGroup, server) {
  let params = {
    spreadGroup: spreadGroup,
    serverName: server,
    symbolId: symbolId || null,
  };
  try {
    const response = await axios.get(
      "https://ctrader.nextrades.ai/api/rest/spreadGroupSymbolPrice",
      { params }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getSymbolPriceByName(name, groupId, serverName) {
  const params = {
    groupId,
    serverName,
  };
  if (name) {
    params.symbol = name;
  }
  return await axios
    .get("https://ctrader.nextrades.ai/api/rest/spreadGroupSymbolPrice", {
      params,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export async function getSymbolPriceMt5(url, symbolName, spreadGroup) {
  let variable = {
    symbol: symbolName,
    spreadGroup: spreadGroup,
  };

  try {
    const response = await axios.get(
        url + "/tick_data/spread_group_symbol_price", {
          headers: {'Content-Type': 'application/json'}
        }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getInstruments(symbol) {
  return await axios({
    url: `https://instruments.nextrades.ai/api/rest/getInstruments?base_symbol_id=${symbol}`,
    method: "get",
  })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

// export async function getInstruments(symbol) {
//     const url = 'https://instruments.nextrades.ai/graphql'; // Replace with the actual API URL
//
//     try {
//         const response = await axios.post(url, {
//             query: `
//         query getInstruments($filter: InstrumentsSearchInput, $page: Int!) {
//           getInstruments(filter: $filter, page: $page) {
//             icon_link
//             base_symbol_id
//             instrument_name
//             ticker_symbol
//           }
//         }
//       `,
//             variables: {
//                 filter: {
//                     base_symbol_id: symbol
//                 },
//                 page: 1
//             }
//         });
//
//         return response;
//     } catch (error) {
//         console.error('Error fetching instruments:', error);
//         throw error;
//     }
// }
