import {useRecoilValue} from "recoil";
import {globalColorThemeState} from "../../../../../../shared/_common/global_state";
import "./css/style.css"

export default function BlueOceanLoadingScreen() {
    const colorState = useRecoilValue(globalColorThemeState);

    return (
        <div id={"blue-ocean-loading-screen"} className={colorState}>
            <div className={"loader-wrapper"}>
                <div className={"loader"}>
                    <span />
                </div>
            </div>
        </div>
    );
}
