import useShared from "../../../../../shared/_common/_shared";

export default function BlueOceanEmptyData(props) {
    const {title} = props;
    const {IconCommon} = useShared();
    return (
        <div className={"text-center"}>
            <IconCommon
                isAlwaysInActive={true}
                iconName={"cm-empty-regular"}
                className={"empty-data"}
                title={title || "noData"}
            />
        </div>
    );
}
