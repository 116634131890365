import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_PLATFORM = gql`
    query getPlatform($filter: PlatformInput) {
        getPlatform(filter: $filter) {
            ... on PlatformArray {
                data {
                    type
                    windowLinkObject {
                        displayName
                        displayNameLanguage {
                            label
                            language
                        }
                        link
                        linkFileList
                        icon
                        iconFileList
                        defaultIcon
                        defaultIconFileList
                        isEnabled
                    }
                    macOSXLinkObject {
                        displayName
                        displayNameLanguage {
                            label
                            language
                        }
                        link
                        linkFileList
                        icon
                        iconFileList
                        defaultIcon
                        defaultIconFileList
                        isEnabled
                    }
                    androidAPKLinkObject {
                        displayName
                        displayNameLanguage {
                            label
                            language
                        }
                        link
                        linkFileList
                        icon
                        iconFileList
                        defaultIcon
                        defaultIconFileList
                        isEnabled
                    }
                    iOSDownloadLinkObject {
                        displayName
                        displayNameLanguage {
                            label
                            language
                        }
                        link
                        linkFileList
                        icon
                        iconFileList
                        defaultIcon
                        defaultIconFileList
                        isEnabled
                    }
                    androidDownloadLinkObject {
                        displayName
                        displayNameLanguage {
                            label
                            language
                        }
                        link
                        linkFileList
                        icon
                        iconFileList
                        defaultIcon
                        defaultIconFileList
                        isEnabled
                    }
                    huaweiDownloadLinkObject {
                        displayName
                        displayNameLanguage {
                            label
                            language
                        }
                        link
                        linkFileList
                        icon
                        iconFileList
                        defaultIcon
                        defaultIconFileList
                        isEnabled
                    }
                    server {
                        name
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getPlatform() {
    const variables = {
        filter: {
            windowLinkObject: {
                isEnabled: true,
            },
            macOSXLinkObject: {
                isEnabled: true,
            },
            androidAPKLinkObject: {
                isEnabled: true,
            },
            iOSDownloadLinkObject: {
                isEnabled: true,
            },
            androidDownloadLinkObject: {
                isEnabled: true,
            },
            huaweiDownloadLinkObject: {
                isEnabled: true,
            },
            isDeleted: false,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_PLATFORM, variables, false);

    return execute;
}
