import React, {Suspense} from 'react';

export const withLazyComponent = (Children) => {
    return (props) => (
        <Suspense>
            <div className="page-layout">
                <Children {...props} />
            </div>
        </Suspense>
    )
}