import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import {withLazyComponent} from "./lazyComponent";
import {protectedRoute} from "./protectedRoute";
import {publicRoute} from "./publicRoute";
import {useRecoilValue} from "recoil";
import {
    globalBrandModuleSettingState,
    globalUserVerifyState,
} from "../_common/global_state";
import Layouts from "../layouts";

const Page404 = withLazyComponent(
    React.lazy(() => import("../pages/_error/page404"))
);

const RouterComponent = () => {
    const userVerifyState = useRecoilValue(globalUserVerifyState);
    const brandWebModuleSetting = useRecoilValue(globalBrandModuleSettingState);

    return (
        <Routes>
            {userVerifyState && (
                <Route path="/" element={<Layouts/>}>
                    {protectedRoute &&
                        protectedRoute.map((item, index) => {
                            const moduleSetting = brandWebModuleSetting?.find((module) => module?.name === item?.moduleName);
                            const moduleEnabled = moduleSetting ? moduleSetting?.isEnabled : true;
                            // const moduleEnabled = true;

                            return moduleEnabled ? (
                                <Route
                                    key={index}
                                    path={item?.path}
                                    element={<item.element/>}
                                />
                            ) : null;
                        })}
                    <Route path="*" element={<Page404/>}/>
                </Route>
            )}

            <Route path="/">
                {publicRoute &&
                    publicRoute.map((item, index) => {
                        return (
                            <Route key={index} path={item?.path} element={<item.element/>}/>
                        );
                    })}
            </Route>
        </Routes>
    );
};

export default RouterComponent;
