import React, {useState} from "react";
import {Avatar, Button, Col, Divider, Drawer, Layout, Popover, Row, Upload} from "antd";
import dummyImg from "../../../assets/image/dummy.png"
import {useNavigate} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {
    globalBrandConfigState,
    globalBrandModuleSettingState,
    globalIconListState,
    globalNotificationState,
    globalUserDetailState,
    globalWebModuleSettingState
} from "../../../_common/global_state";
import {useProfileFunction} from "../../../pages/profile/function";
import useShared from "../../../_common/_shared";
import {useTranslation} from "react-i18next";
import {PlusOutlined} from "@ant-design/icons";
import EditNavigationModal_2 from "./editNavigationModal";

export const Sider_2 = ({routes}) => {
    const {Sider} = Layout;
    const [openEditNavModal, setOpenEditNavModal] = useState(false)
    const brandConfigState = useRecoilValue(globalBrandConfigState)
    const brandModuleSetting = useRecoilValue(globalBrandModuleSettingState)
    const webModuleSetting = useRecoilValue(globalWebModuleSettingState)
    const userDetails = useRecoilValue(globalUserDetailState)
    const iconListState = useRecoilValue(globalIconListState);
    const navigate = useNavigate()
    const {updateProfile} = useProfileFunction()
    const {IconModule, IconCommon} = useShared()
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(globalNotificationState)

    const [openMorePopover, setIsOpenMorePopOver] = useState(false)

    const uploadImage = async (e) => {
        if (e && e?.file && e?.file.response && e?.file.response[0] && e?.file.response[0].link) {
            await updateProfile(userDetails?._id, {
                profileImage: e?.file.response[0].link.toString(),
            })
        }
    }

    const categories = [...new Set(routes.flatMap(route => {
        if (route?.sub && route?.sub.length > 0 && route?.key === "more") {
            return route?.sub.map(subRoute => subRoute?.categoryName);
        }
        return [];
    }))];

    const [openDrawers, setOpenDrawers] = useState([]);

    const openDrawer = (drawerKey) => {
        setOpenDrawers(prevOpenDrawers => [...prevOpenDrawers, drawerKey]);
        document.querySelector(".setPopoverCustom + .ant-popover").style.overflowY = "hidden";
    };

    const closeDrawer = (drawerKey) => {
        setOpenDrawers(prevOpenDrawers => prevOpenDrawers.filter(key => key !== drawerKey));
        document.querySelector(".setPopoverCustom + .ant-popover").style.overflowY = "scroll";
    };

    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    return (
        <Sider width={80} id={"layout-sider"} collapsible breakpoint="md" collapsedWidth="0">
            <Col span={24} className={"text-center mt-4 mb-3"}>
                <Upload id={"upload-profile-image"} showUploadList={false}
                        beforeUpload={(file) => {
                            const isImage = file.type?.includes('image')
                            if (!isImage) {
                                setNotificationMessage({
                                    status: "error",
                                    title: "",
                                    msg: t("errorMsg.type.invalidFileType"),
                                    key: "invalidFileType",
                                });
                            }
                            return isImage || Upload.LIST_IGNORE;
                        }}
                        accept="image/*" maxCount={1}
                        onChange={uploadImage}
                        action={`${brandConfigState?.backendURL}api/upload/profile/image`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={`upload-container ${hovered ? 'hovered' : ''}`}
                    // disabled={userDetails?.profileImage}
                >
                    <Avatar size={40} src={userDetails?.profileImage || dummyImg}/>
                    <div className="plus-overlay">
                        <PlusOutlined/>
                    </div>
                </Upload>
            </Col>
            {
                routes.map((route) => {
                    if (route?.sub && route?.sub?.length > 0) {
                        const moreContent = (
                            <>
                                {/*<div key={route?.key}>*/}
                                {categories.map((category, index) => (
                                    <Row id={"layout-sider-popover"} key={category}>
                                        {index === 0 ? (
                                            <Divider className="sidebarDivider" style={{display: 'none'}}/>
                                        ) : (
                                            <Divider className="sidebarDivider"/>
                                        )}
                                        <Col span={24} className={"mb-2"}>
                                            <p className={"sub-title"}><strong>{t(`common.${category}`)}</strong></p>
                                            {/*<p className={"sub-title"}>{t(category)}</p>*/}
                                        </Col>
                                        {route?.sub
                                            .sort((a, b) => {
                                                const categoryOrder = {
                                                    common: 1,
                                                    finance: 2,
                                                    others: 3,
                                                };

                                                const categoryComparison = categoryOrder[a.categoryName] - categoryOrder[b.categoryName];
                                                if (categoryComparison !== 0) {
                                                    return categoryComparison;
                                                }

                                                return categoryOrder[a.key] - categoryOrder[b.key];
                                            })
                                            .filter(subRoute => subRoute?.categoryName === category)
                                            .map(subRoute => {
                                                const filterModule = brandModuleSetting?.filter((item) => item?.isEnabled === true && subRoute?.label === item?.name)
                                                const drawerKey = `${subRoute?.label}`;
                                                const isDrawerOpen = openDrawers.includes(drawerKey);
                                                if (filterModule && subRoute?.sub) {
                                                    return (
                                                        <>
                                                            {/*<div key={subRoute?.label}>*/}
                                                            <Col
                                                                xs={12} lg={6}
                                                                className={"layout-sider-popover-btn text-center mb-2"}
                                                                onClick={() => {
                                                                    openDrawer(drawerKey);
                                                                    document.querySelector(".setPopoverCustom + .ant-popover")?.scrollTo({
                                                                        top: 0,
                                                                    });
                                                                }}
                                                            >
                                                                {subRoute?.icon}
                                                                {/*<p className={"mt-1 sidebarLabel"}*/}
                                                                {/*   style={{margin: '0'}}>{t(moduleTranslation(subRoute?.label))}</p>*/}
                                                            </Col>
                                                            <Drawer
                                                                key={drawerKey}
                                                                id="drawerContainer"
                                                                onClose={() => closeDrawer(drawerKey)}
                                                                open={isDrawerOpen}
                                                                className={"popoverDrawer"}
                                                                getContainer={false}
                                                                width={384}
                                                                title={
                                                                    <Row justify={"end"}>
                                                                        <Button type={"link"}
                                                                                onClick={() => closeDrawer(drawerKey)}>X</Button>
                                                                    </Row>
                                                                }
                                                                closeIcon={false}
                                                            >
                                                                <Row className={"text-center"}>
                                                                    {subRoute?.sub
                                                                        ?.map(subSubRoute => {
                                                                            let title = subSubRoute?.label.replace(".", '').replace(/[-\s/+]/g, '_').toLowerCase()
                                                                            return (
                                                                                <Col
                                                                                    className={'mb-3'}
                                                                                    style={{cursor: "pointer"}}
                                                                                    key={subSubRoute?.label}
                                                                                    xs={12}
                                                                                    lg={6}
                                                                                    onClick={() => {
                                                                                        subSubRoute?.type === "_blank"
                                                                                            ? window.open(subSubRoute?.path, '_blank')
                                                                                            : navigate(subSubRoute?.path)
                                                                                    }}
                                                                                >
                                                                                    <IconModule
                                                                                        iconName={subSubRoute?.label}
                                                                                        className={'sidebarIcon'}
                                                                                        title={title}
                                                                                    />
                                                                                    {/*<p className={"mt-1 sidebarLabel"}*/}
                                                                                    {/*   style={{*/}
                                                                                    {/*       margin: '0',*/}
                                                                                    {/*       wordWrap: 'break-word'*/}
                                                                                    {/*   }}>*/}
                                                                                    {/*    {t(moduleTranslation(subSubRoute?.label))}</p>*/}
                                                                                </Col>

                                                                            )
                                                                        })}
                                                                </Row>
                                                            </Drawer>
                                                            {/*</div>*/}
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <Col
                                                            key={subRoute?.label}
                                                            xs={12}
                                                            lg={6}
                                                            className={"layout-sider-popover-btn text-center mb-2"}
                                                            onClick={() => {
                                                                subRoute?.type === "_blank" ? window.open(subRoute?.path, '_blank') : navigate(subRoute?.path)
                                                                setIsOpenMorePopOver(false)
                                                            }}
                                                        >
                                                            {subRoute?.icon}
                                                            {/*<p className={"mt-1 sidebarLabel"}*/}
                                                            {/*   style={{margin: '0'}}>{t(moduleTranslation(subRoute?.label))}</p>*/}
                                                        </Col>
                                                    );
                                                }
                                            })}
                                    </Row>
                                ))}

                                {route?.key === "more" && (
                                    <Col span={24} className={"text-center editNavigation"}>
                                        <Divider
                                            style={{margin: "0.2rem 0", background: "var(--divider-color)"}}
                                        />
                                        <Button
                                            type={"link"}
                                            className={""}
                                            onClick={() => {
                                                setOpenEditNavModal(true)
                                                setIsOpenMorePopOver(false)
                                            }}
                                        >
                                            {/*Edit Navigation*/}
                                            {t('common.editNavigation')}
                                        </Button>
                                    </Col>
                                )}
                                {/*</div>*/}
                            </>
                        );

                        const content = (
                            <Row id={"layout-sider-popover"}>
                                {/*<Divider style={{margin: "0.2rem 0", background: "var(--divider-color)"}}/>*/}
                                {route?.sub?.map(subRoute => {
                                    return (
                                        <Col
                                            key={subRoute?.label}
                                            xs={12}
                                            lg={6}
                                            className={`layout-sider-popover-btn text-center ${route?.sub?.length > 4 ? 'mt-1 mb-2' : 'mb-0'}`}
                                            onClick={() => {
                                                subRoute?.type === "_blank" ? window.open(subRoute?.path, '_blank') : navigate(subRoute?.path)
                                            }}
                                        >
                                            {subRoute?.icon}
                                            {/*<p className={"mt-1 sidebarLabel"}*/}
                                            {/*   style={{*/}
                                            {/*       margin: '0',*/}
                                            {/*       wordWrap: 'break-word'*/}
                                            {/*   }}>{t(moduleTranslation(subRoute?.label))}</p>*/}
                                        </Col>
                                    );

                                })}
                            </Row>
                        );

                        return (
                            <>
                                {route?.key === 'more'
                                    ? (<Popover open={openMorePopover} destroyTooltipOnHide={true}
                                                className={"setPopoverCustom"} content={moreContent}
                                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                                placement={"right"}
                                                trigger="hover"
                                                onOpenChange={(bool) => setIsOpenMorePopOver(bool)}
                                    >
                                        <Col span={24} className={"text-center mb-2"}
                                             style={{cursor: "pointer"}}>
                                            {route?.icon}
                                            {/*<p style={{padding: "0 0.75rem"}}*/}
                                            {/*   className={"mt-1 sidebarLabel"}>{t(moduleTranslation(route?.label))}</p>*/}
                                        </Col>
                                    </Popover>) : (
                                        <Popover destroyTooltipOnHide={true} content={content} placement={"right"}
                                                 getPopupContainer={triggerNode => triggerNode.parentElement}>
                                            <Col span={24} className={"text-center mb-2"}
                                                 style={{cursor: "pointer"}}>
                                                {route?.icon}
                                                {/*<p style={{padding: "0 0.75rem"}}*/}
                                                {/*   className={"mt-1 sidebarLabel"}>{t(moduleTranslation(route?.label))}</p>*/}
                                            </Col>
                                        </Popover>
                                    )
                                }
                            </>
                        )
                    } else {
                        return (
                            <Col span={24} className={"text-center mb-3"} key={route?.label} onClick={() => {
                                route?.type === "_blank" ? window.open(route?.path, '_blank') : navigate(route?.path)
                            }} style={{cursor: "pointer"}}>
                                {route?.icon}
                                {/*<p style={{padding: "0 0.75rem"}} className={"mt-1"}>{t(moduleTranslation(route?.label))}</p>*/}
                            </Col>
                        )
                    }
                })
            }
            <EditNavigationModal_2 isModalOpen={openEditNavModal} handleCancel={() => setOpenEditNavModal(false)}/>
        </Sider>
    )
}