import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_NEWSLETTER = gql`
    query getNewsletter($filter: NewsletterInput, $limit: Int, $offset: Int, $orderBy: String) {
        getNewsletter(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
            ... on NewsletterArray {
                data {
                    _id
                    id
                    name
                    description
                    image {
                        path
                    }
                    isEnabled
                    createdBy {username}
                    updatedBy {username}
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getNewsletter() {

    const variables = {
        filter: {
            name: null,
            description: null,
            isEnabled: true,
        },
        limit: null,
        offset: null,
        orderBy: null,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_NEWSLETTER, variables, false);

    return execute;
}