import {getApps, initializeApp} from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";
import getConfigByName from "../../config";

export const fetchFCMToken = async () => {

    let config = getConfigByName()
    let app;
    let messaging;

    if (typeof window !== 'undefined' && !getApps().length) {
        app = initializeApp(config?.fireBaseConfig);
    } else {
        app = getApps()[0];
    }

    if (app) {
        messaging = getMessaging(app);

        try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                const currentToken = await getToken(messaging, {vapidKey: config?.VapidKey});
                if (currentToken) {
                    // console.log('Current token for client:', currentToken);
                    return currentToken;
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                    return null;
                }
            } else {
                console.log('Notification permission denied.');
                return null;
            }
        } catch (error) {
            console.log('An error occurred while retrieving token:', error);
            return null;
        }
    }

    return null;
};
